import { useNavigation } from '@react-navigation/native';
import {
  Button,
  Icon,
  Input,
  Spinner,
  Text,
  useTheme,
} from '@ui-kitten/components';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Linking, View } from 'react-native';
import { useActive, useHover } from 'react-native-web-hooks';

import WarningIcon from '../../../assets/images/warning_icon.svg';
import config from '../../config';
import { ActionTypeChoices } from '../../constants/Action';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { AppStackNavigation } from '../../navigation';
import { useStore } from '../../stores';
import { ActionData } from '../../types';
import { useUserFullName, useUserId } from '../../utils/helper';
import AutocompleteComponent from '../Common/AutocompleteComponent';
import CustomModal from '../Common/CustomModal';
// import MarkdownBox from '../Common/MarkdownBox';

type Props = {
  assessmentId: number;
  questionId: number;
  answerId: number;
  setIsChanged: (state: boolean) => void;
  actionId?: string;
};

const CreateActionType: React.FC<Props> = ({
  assessmentId,
  questionId,
  answerId,
  setIsChanged,
  actionId,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const userFullName = useUserFullName();
  const navigation = useNavigation<AppStackNavigation>();
  const store = useStore();
  const { actionStore } = store;
  const userId = useUserId();

  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isActive = useActive(ref);

  const [actionType, setActionType] = useState<string>(
    actionId ? ActionTypeChoices.NO_ACTION : '',
  );
  const initialActionType = actionId ? ActionTypeChoices.NO_ACTION : '';
  const [actionCreator, setActionCreator] = useState<string>('');
  const [commentText, setCommentText] = useState<string>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const ACTION_TYPES = [
    {
      id: ActionTypeChoices.CONFORMANCE,
      text: 'Conformance Field Inspection Action',
    },
    { id: ActionTypeChoices.RISK, text: 'Risk Field Inspection Action' },
    { id: ActionTypeChoices.QUALITY, text: 'Quality Inspection Action' },
    { id: ActionTypeChoices.NO_ACTION, text: 'No action required' },
  ];

  const ACTION_CREATOR = [{ id: userId, text: userFullName }];

  useEffect(() => {
    if (actionId !== undefined) {
      setActionType(ActionTypeChoices.NO_ACTION);
      setCommentText(actionStore.getAction(Number(actionId))?.description);
    }
  }, [actionId]);

  useEffect(() => {
    if (actionType !== initialActionType) {
      setIsChanged(true);
    }
  }, [actionType]);

  const onChangeText = (value: string): void => {
    setCommentText(value);
  };

  const onLinkPress = async () => {
    let url = '';
    if (actionType === ActionTypeChoices.RISK) {
      url = config.ratUrl;
    } else {
      url = `${config.catUrl}`;
      url += `?AssessmentID=${assessmentId}`;
      url += `&QuestionID=${questionId}`;
      url += `&EventDate=${format(new Date(), 'yyyy/MM/dd')}`;
      url += '&hint=0';
      if (actionType === ActionTypeChoices.CONFORMANCE) {
        url += '&ActionSource=FI';
      } else if (actionType === ActionTypeChoices.QUALITY) {
        url += '&ActionSource=QI';
      }
      if (config.env !== 'production') {
        url += `&DataSource=${config.env}`;
      }
    }

    if (url !== '') {
      const canOpen = await Linking.canOpenURL(url);
      if (canOpen) {
        //@ts-ignore
        Linking.openURL(url, '_blank');
      }
    }
  };

  const onDoneWrapped = () => {
    if (actionType !== ActionTypeChoices.NO_ACTION) {
      setIsVisible(true);
    } else {
      onDone();
    }
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setIsChanged(false);

    let data: ActionData;
    let update = false;
    if (actionType === ActionTypeChoices.CONFORMANCE) {
      data = {
        answer: answerId,
        submitted: true,
        actionType: ActionTypeChoices.CONFORMANCE,
        creator: userId,
      };
    } else if (actionType === ActionTypeChoices.QUALITY) {
      data = {
        answer: answerId,
        submitted: true,
        actionType: ActionTypeChoices.QUALITY,
        creator: userId,
      };
    } else if (actionType === ActionTypeChoices.RISK) {
      data = {
        answer: answerId,
        submitted: true,
        actionType: ActionTypeChoices.RISK,
        description: 'See bp RAT for details',
        creator: userId,
      };
    } else if (actionType === ActionTypeChoices.NO_ACTION) {
      data = {
        answer: answerId,
        submitted: true,
        actionType: ActionTypeChoices.NO_ACTION,
        description: commentText,
        creator: userId,
      };
      if (actionId !== undefined) {
        update = true;
      }
    }

    let result: any;

    if (update) {
      result = await actionStore.updateAction(Number(actionId), data!);
    } else {
      result = await actionStore.addAction(data!);
    }
    await actionStore.refreshCatActions();
    setLoading(false);
    if (result.ok) {
      navigation.navigate('Main', { screen: 'Actions' });
    }
  };

  const renderConfirmation = () => (
    <View style={styles.card}>
      <WarningIcon />
      <Text style={[styles.text, { marginTop: 30 }]}>
        I confirm the appropriate action(s) have been
      </Text>
      <Text style={[styles.text, { marginTop: 5 }]}>
        created in the{' '}
        {actionType === ActionTypeChoices.RISK
          ? 'Risk Assurance Tool.'
          : 'Castrol Action Tracker.'}
      </Text>
      <View style={styles.buttons}>
        <Button
          status="primary"
          size="large"
          appearance="outline"
          style={{ marginRight: 20, width: 150 }}
          accessoryLeft={() => (
            <Icon
              name="close-outline"
              width={20}
              height={20}
              fill={theme['text-dark']}
            />
          )}
          onPress={() => {
            setIsVisible(false);
          }}
        >
          Cancel
        </Button>
        <Button
          status="primary"
          size="large"
          appearance="filled"
          style={{ width: 150 }}
          accessoryLeft={(props) =>
            loading ? (
              <Spinner status="info" {...props} />
            ) : (
              <Icon
                name="checkmark-outline"
                width={24}
                height={24}
                fill={theme['text-white']}
              />
            )
          }
          onPress={() => {
            setIsVisible(false);
            onDone();
          }}
        >
          Confirm
        </Button>
      </View>
    </View>
  );

  return (
    <>
      <View style={styles.actionType} ref={ref}>
        <View style={{ flexDirection: 'row' }}>
          <AutocompleteComponent
            label="Action type"
            placeholder="Select action type"
            onSelect={(id) => setActionType(id)}
            data={ACTION_TYPES}
            value={actionType}
            marginTop={0}
            disabled={actionId !== undefined}
          />
          <View style={{ flex: 1 }} />
        </View>
        {(actionType === ActionTypeChoices.CONFORMANCE ||
          actionType === ActionTypeChoices.RISK ||
          actionType === ActionTypeChoices.QUALITY) && (
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 0.5 }}>
              <Button
                style={styles.actionTypeButton}
                status="basic"
                size="medium"
                appearance="filled"
                accessoryLeft={(props) => (
                  <Icon name="link-outline" width={24} height={24} {...props} />
                )}
                onPress={onLinkPress}
              >
                {actionType === ActionTypeChoices.RISK
                  ? 'Link to RAT [Risk Assurance Tool]'
                  : 'Link to Castrol Action Tracker [CAT]'}
              </Button>
            </View>
          </View>
        )}
        {actionType === ActionTypeChoices.NO_ACTION && (
          <>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 0.5, marginTop: 37 }}>
                <AutocompleteComponent
                  label="Creator"
                  placeholder={userFullName}
                  onSelect={(id) => setActionCreator(id)}
                  data={ACTION_CREATOR}
                  value={actionCreator}
                  marginTop={0}
                  disabled
                />
              </View>
            </View>
            <View style={styles.commentContainer}>
              <View style={styles.commentLabel}>
                <Text
                  style={[
                    styles.commentLabelText,
                    isActive
                      ? styles.active
                      : isHovered
                      ? styles.hover
                      : styles.inactive,
                  ]}
                >
                  Comment
                </Text>
              </View>
              <View
                style={[
                  styles.inputContainer,
                  isActive
                    ? styles.active
                    : isHovered
                    ? styles.hover
                    : styles.inactive,
                ]}
              >
                <Input
                  status="primary"
                  size="medium"
                  multiline
                  textStyle={{ minHeight: 151, padding: 10 }}
                  onChangeText={onChangeText}
                  value={commentText}
                />
                {/* <MarkdownBox
                  setValue={onChangeText}
                  showOutline={false}
                  draftComment={commentText}
                /> */}
              </View>
            </View>
          </>
        )}
      </View>
      <Button
        status="primary"
        size="large"
        style={styles.doneButton}
        disabled={!actionType}
        onPress={onDoneWrapped}
        accessoryLeft={(props) =>
          loading ? (
            <Spinner status="info" {...props} />
          ) : (
            <Icon
              name="checkmark-outline"
              width={24}
              height={24}
              fill={theme['text-white']}
            />
          )
        }
      >
        <Text>Done</Text>
      </Button>
      <CustomModal
        visible={isVisible}
        onBackdropPress={() => {
          setIsVisible(false);
        }}
        children={renderConfirmation()}
      />
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    actionType: {
      marginTop: 16,
      padding: 16,
      borderRadius: 3,
      backgroundColor: '#FCFCFC',
    },
    actionTypeButton: {
      marginTop: 44,
      backgroundColor: 'text-white',
      borderColor: 'text-light',
    },
    commentContainer: {
      flex: 1,
      position: 'relative',
      marginTop: 29,
    },
    commentLabel: {
      flex: 1,
      position: 'absolute',
      backgroundColor: '#FCFCFC',
      left: 7,
      top: -8,
      paddingHorizontal: 5,
      paddingVertical: 0,
      margin: 0,
      zIndex: 1,
    },
    commentLabelText: {
      fontSize: 14,
      fontFamily: 'UniversLTPro_Regular',
      color: 'input-field-outline',
      zIndex: 2,
    },
    active: {
      color: 'input-field-outline-active',
      borderColor: 'input-field-outline-active',
    },
    hover: {
      color: 'input-field-outline-hover',
      borderColor: 'input-field-outline-hover',
    },
    inactive: {
      color: 'text-grey',
      borderColor: 'input-field-outline',
    },
    inputContainer: {
      alignSelf: 'stretch',
      borderRadius: 3,
      borderWidth: 1,
      backgroundColor: '#FCFCFC',
      color: 'text-grey',
      borderColor: 'input-field-outline',
    },
    doneButton: {
      marginTop: 24,
      width: 312,
      justifyContent: 'flex-start',
      alignSelf: 'flex-end',
    },
    text: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    card: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      alignItems: 'center',
      justifyContent: 'center',
      width: 520,
      height: 300,
      borderRadius: 4,
      paddingVertical: 30,
      paddingHorizontal: 47,
    },
    buttons: {
      flexDirection: 'row',
      marginTop: 40,
    },
  },
});

export default observer(CreateActionType);
