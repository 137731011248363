import { Icon, Text } from '@ui-kitten/components';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import CustomTooltip from '../Common/CustomTooltip';

type Props = {
  year: string;
};

const DrumbeatLabels: React.FC<Props> = ({ year }) => {
  const styles = useResponsiveStyleSheet(themedStyles);

  const [showTip, setShowTip] = useState<boolean>(false);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const currentDate = new Date();

  return (
    <View style={styles.labelsContainer}>
      <View
        style={{
          flex: 0.32,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text
          style={[
            styles.labelText,
            { flex: 0, marginLeft: 16, marginRight: 16, textAlign: 'left' },
          ]}
        >
          Assessment
        </Text>
        <CustomTooltip
          showTip={showTip}
          contentText="Click on a cell to assign a field inspection or mark it as N/A (Not Applicable). An inspection is considered overdue if it is not completed within 1 month after its scheduled date"
          setShowTip={setShowTip}
          placement="right"
          multiline
        >
          <TouchableOpacity
            onPress={() => setShowTip(true)}
            // @ts-ignore
            onMouseEnter={() => setShowTip(true)}
            // @ts-ignore
            onMouseLeave={() => setShowTip(false)}
            style={styles.questionContainer}
          >
            <Icon
              name="question-mark-outline"
              width={14}
              height={14}
              fill="#FFFFFF"
            />
          </TouchableOpacity>
        </CustomTooltip>
      </View>
      {months.map((month) => (
        <View
          style={[
            styles.labelContainer,
            currentDate.getFullYear().toString() === year &&
              format(currentDate, 'MMM') === month &&
              styles.currentMonth,
          ]}
          key={month}
        >
          <Text
            style={[
              styles.labelText,
              month === 'Dec' && { borderRightWidth: 0 },
            ]}
          >
            {month}
          </Text>
        </View>
      ))}
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    labelsContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'gray-03',
    },
    labelContainer: {
      flex: 0.68 / 12,
      paddingVertical: 5,
    },
    labelText: {
      flex: 1,
      textAlign: 'center',
      fontSize: 14,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-dark',
      borderRightWidth: 1,
      borderRightColor: 'gray-03',
    },
    currentMonth: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'stretch',
      paddingVertical: 0,
      backgroundColor: 'green-07',
    },
    questionContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 14,
      height: 14,
      borderRadius: 100,
      backgroundColor: '#007F00',
    },
  },
});

export default observer(DrumbeatLabels);
