import { useTheme } from '@ui-kitten/components';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryStack,
} from 'victory-native';

import { ScheduleStatistics } from '../../types';

type Props = {
  data: ScheduleStatistics[];
};

const MONTHS = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const AssessmentsGraph: React.FC<Props> = ({ data }) => {
  const theme = useTheme();

  const [scheduleCount, setScheduleCount] = useState(
    isEmpty(data)
      ? [
          {
            order: 1,
            month: 1,
            year: 0,
            completed: 0,
            overdue: 0,
            scheduled: 0,
          },
        ]
      : data,
  );

  useEffect(() => {
    if (!data.length) {
      const empty = [
        {
          order: 1,
          month: 1,
          year: 0,
          completed: 0,
          overdue: 0,
          scheduled: 0,
        },
      ];
      setScheduleCount(empty);
    } else {
      setScheduleCount(data);
    }
  }, [data]);

  const legendData = [
    {
      name: 'COMPLETED',
      symbol: {
        fill: theme['bp-green'],
      },
    },
    {
      name: 'OVERDUE',
      symbol: {
        fill: theme['badge-red'],
      },
    },
    {
      name: 'SCHEDULED',
      symbol: {
        fill: theme['badge-blue'],
        fillOpacity: 0.5,
        strokeWidth: 1,
        stroke: theme['badge-blue'],
        strokeDasharray: 2,
      },
    },
  ];

  const xTickValues = useMemo(() => {
    const values: number[] = [0.5];
    scheduleCount.forEach((m) => values.push(m.order + 0.5));
    return values;
  }, [scheduleCount]);

  const fontSize = useMemo(() => {
    if (scheduleCount.length > 12) {
      return 6;
    } else if (scheduleCount.length > 6) {
      return 7.5;
    } else {
      return 10;
    }
  }, [scheduleCount]);

  const chartStyles = {
    grid: {
      stroke: theme['gray-09'],
      strokeDasharray: 0,
    },
    axis: { stroke: theme['gray-09'] },
    text: {
      fontSize,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fill: theme['text-light'],
    },
    completedBar: {
      fill: theme['bp-green'],
      stroke: (x: any) =>
        x.datum.completed === 0 ? 'transparent' : theme['bp-green'],
      strokeWidth: 1,
    },
    overdueBar: {
      fill: theme['badge-red'],
      stroke: (x: any) =>
        x.datum.overdue === 0 ? 'transparent' : theme['badge-red'],
      strokeWidth: 1,
    },
    scheduleBar: {
      fill: theme['badge-blue'],
      stroke: (x: any) =>
        x.datum.scheduled === 0 ? 'transparent' : theme['badge-blue'],
      strokeWidth: 1,
      strokeDasharray: 2,
      fillOpacity: 0.5,
    },
  };

  return (
    <VictoryChart
      width={408}
      height={207}
      padding={{ top: 15, bottom: 35, left: 45, right: 95 }}
      style={{ parent: { flex: 1 } }}
    >
      <VictoryLegend
        orientation="vertical"
        data={legendData}
        x={318}
        y={50}
        rowGutter={4}
        style={{
          labels: { ...chartStyles.text, letterSpacing: 0.5, fontSize: 10 },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={(t) => (Number.isInteger(t) ? t : null)}
        style={{
          axis: chartStyles.axis,
          grid: chartStyles.grid,
          tickLabels: {
            ...chartStyles.text,
            letterSpacing: 1.5,
            fontSize: 10,
          },
        }}
      />
      <VictoryAxis
        style={{
          axis: chartStyles.axis,
          grid: chartStyles.grid,
        }}
        tickCount={scheduleCount.length || 1}
        tickValues={xTickValues}
        tickFormat={(x) => ''}
      />

      <VictoryStack>
        <VictoryBar
          data={scheduleCount}
          x="order"
          y="completed"
          barRatio={scheduleCount.length > 1 ? 0.8 : 3}
          style={{
            data: chartStyles.completedBar,
          }}
        />
        <VictoryBar
          data={scheduleCount}
          x="order"
          y="overdue"
          barRatio={scheduleCount.length > 1 ? 0.8 : 3}
          style={{
            data: chartStyles.overdueBar,
          }}
        />
        <VictoryBar
          data={scheduleCount}
          x="order"
          y="scheduled"
          barRatio={scheduleCount.length > 1 ? 0.8 : 3}
          style={{
            data: chartStyles.scheduleBar,
          }}
          labels={(d) => `${MONTHS[d.datum.month - 1]}`}
          labelComponent={
            <VictoryLabel
              y={190}
              verticalAnchor="start"
              style={{ ...chartStyles.text, letterSpacing: 1.5 }}
            />
          }
        />
      </VictoryStack>
    </VictoryChart>
  );
};

export default observer(AssessmentsGraph);
