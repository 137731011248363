import { useIsFocused } from '@react-navigation/native';
import { Button, Icon, Spinner, Text, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, FlatList, View } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { useStore } from '../../stores';
import { CatActionTrackerItem, FilterData } from '../../types';
import ShowFilters from '../Common/ShowFilters';
import ActionTrackerItem from './ActionTrackerItem';
import ActionTrackerLabels from './ActionTrackerLabels';

type Props = {
  index: number;
};

const ActionTracker: React.FC<Props> = ({ index }) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const store = useStore();
  const { actionStore, isInternetReachable } = store;
  const isFocused = useIsFocused();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [loadingFooter, setLoadingFooter] = useState(false);
  const [catActions, setCatActions] = useState<CatActionTrackerItem[]>([]);
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const page = useRef(-1);
  const loadingRef = useRef(false);
  const filters = store.filters || {};

  useEffect(() => {
    if (isFocused && index === 1) {
      onFilterSubmit(filters, true);
    }
  }, [isFocused, index]);

  const onFilterSubmit = async (filters: FilterData, isFocused = false) => {
    if (
      loading ||
      loadingRef.current ||
      loadingFooter ||
      !isInternetReachable
    ) {
      return;
    }

    loadingRef.current = true;
    setLoading(true);

    if (isFocused) {
      await actionStore.refreshCatActions();
    }

    page.current = 1;
    const result = await actionStore.fetchCatActions(filters, page.current);
    if (result.ok) {
      setCatActions(result.extra?.results as CatActionTrackerItem[]);

      if (result.extra && result.extra.next) {
        page.current += 1;
      } else {
        page.current = -1;
      }

      if (result.extra?.count) {
        setTotalCount(result.extra.count);
      }
    } else {
      page.current = -1;
      setLoadingFooter(false);
    }

    loadingRef.current = false;
    setLoading(false);
  };

  const fetchNextCatActions = async () => {
    if (
      loading ||
      loadingFooter ||
      loadingRef.current ||
      page.current === -1 ||
      !isInternetReachable
    ) {
      return;
    }

    setLoadingFooter(true);

    const result = await actionStore.fetchCatActions(filters, page.current);

    if (result.ok) {
      setCatActions([
        ...catActions,
        ...(result.extra?.results as CatActionTrackerItem[]),
      ]);

      if (result.extra?.next === null) {
        page.current = -1;
      } else {
        page.current += 1;
      }
    } else {
      page.current = -1;
    }
    setLoadingFooter(false);
  };

  const renderActions = useCallback(
    ({ item }: { item: CatActionTrackerItem }) => (
      <ActionTrackerItem catAction={item} />
    ),
    [],
  );

  const renderEmptyAssessments = () =>
    loading ? (
      <View style={styles.loading}>
        <ActivityIndicator size="large" color={theme['bp-green']} />
      </View>
    ) : (
      <Text style={styles.emptyAssessments}>No actions found</Text>
    );

  const renderFooter = () => {
    if (loading || catActions.length === 0) {
      return;
    }

    return (
      <View style={styles.footerContainer}>
        {totalCount ? (
          <Text style={styles.footerSummary}>
            Showing {catActions.length} of {totalCount} actions
          </Text>
        ) : (
          <></>
        )}
        {page.current !== -1 ? (
          <Button
            status="primary"
            appearance="filled"
            accessoryLeft={(props) =>
              loadingFooter ? (
                <Spinner status="info" {...props} />
              ) : (
                <Icon
                  name="plus-outline"
                  fill={theme['text-white']}
                  {...props}
                />
              )
            }
            onPress={() => fetchNextCatActions()}
          >
            Load more actions
          </Button>
        ) : (
          <></>
        )}
      </View>
    );
  };

  const renderHeader = () => (
    <>
      {isInternetReachable && (
        <ShowFilters
          conformanceActionTracker
          onFilterSubmit={onFilterSubmit}
          actualIndex={index}
          filterIndex={1}
        />
      )}
      <ActionTrackerLabels />
    </>
  );

  const renderSeparator = useCallback(
    () => <View style={styles.separator} />,
    [],
  );

  return (
    <View style={styles.container}>
      <FlatList
        data={loading ? [] : catActions}
        renderItem={renderActions}
        contentContainerStyle={[{ paddingBottom: 80, paddingRight: 26 }]}
        keyExtractor={(_item, index) => `${index}`}
        ItemSeparatorComponent={renderSeparator}
        ListHeaderComponent={renderHeader()}
        ListEmptyComponent={renderEmptyAssessments()}
        ListFooterComponent={renderFooter()}
      />
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      paddingLeft: 26,
    },
    emptyAssessments: {
      textAlign: 'center',
      marginTop: 20,
      fontFamily: 'UniversLTPro_Regular',
    },
    separator: {
      backgroundColor: 'gray-03',
      height: 1,
    },
    loading: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footerContainer: {
      marginTop: 20,
      alignItems: 'center',
    },
    footerSummary: {
      fontSize: 14,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: '#232323',
      marginBottom: 10,
    },
  },
});

export default observer(ActionTracker);
