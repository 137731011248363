import { Icon, Text, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { View } from 'react-native';

import { useMediaQueries } from '../../hooks/useMediaQueries';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

const ActionItemLabels = () => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const { isDesktop } = useMediaQueries();

  return (
    <>
      <View style={styles.labelsContainer}>
        <View
          style={[styles.labelCell, { flex: 160 / 1330, flexDirection: 'row' }]}
        >
          <Icon
            name="chevron-right-outline"
            width={22}
            height={22}
            fill={theme['text-white']}
          />
          <Text style={styles.labelText}>Assessment ID</Text>
        </View>
        <View style={[styles.labelCell, { flex: 208 / 1330 }]}>
          <Text style={styles.labelText}>Assessment</Text>
        </View>
        <View style={[styles.labelCell, { flex: 180 / 1330 }]}>
          <Text style={styles.labelText}>Assessment date</Text>
        </View>
        <View style={[styles.labelCell, { flex: 300 / 1330 }]}>
          <Text style={styles.labelText}>Question</Text>
        </View>
        <View style={[styles.labelCell, { flex: 240 / 1330 }]}>
          <Text style={styles.labelText}>Comments</Text>
        </View>
        <View
          style={[
            styles.labelCell,
            isDesktop ? { flex: 121 / 1330 } : { flex: 60 / 1330 },
          ]}
        >
          <Text style={[styles.labelText]}>Action</Text>
        </View>
        <View
          style={[
            styles.labelCell,
            isDesktop ? { flex: 121 / 1330 } : { flex: 181 / 1330 },
          ]}
        >
          <Text style={styles.labelText}>Assessor</Text>
        </View>
      </View>
      <View style={styles.separator} />
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    labelsContainer: {
      flexDirection: 'row',
      height: 41,
      paddingTop: 16,
      marginTop: 16,
      backgroundColor: 'text-white',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    labelCell: {
      marginHorizontal: 15,
    },
    labelText: {
      fontSize: 12,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-grey',
    },
    separator: {
      backgroundColor: 'gray-03',
      height: 1,
    },
  },
});

export default observer(ActionItemLabels);
