import { light, dark } from '@eva-design/eva';

export default {
  dark: {
    ...dark,
  },
  light: {
    ...light,

    // official sketch color names

    'text-dark': '#232323',
    'text-light': '#979797',
    'text-white': '#FFFFFF',
    'text-grey': '#666666',

    'bp-green': '#007F00',
    'bp-dark-green': '#254F30',
    'bp-light-green': '#99CC00',

    'light-green-100': '#E3F0BF',

    'green-100': '#C5E7C1',
    'green-500': '#34B128',
    'green-900': '#01320E',

    'grey-100': '#F2F2F2',
    'grey-200': '#EDEDED',
    'grey-300': '#DEDEDE',

    'yellow-800': '#FDA000',

    // non offical names

    'input-field-background': '#FFFFFF',
    'input-field-background-focused': '#F2F2F2',
    'input-field-outline': '#CBCBCB',
    'input-field-outline-hover': '#232323',
    'input-field-outline-focused': '#F2F2F2',
    'input-field-outline-active': '#007F00',

    'button-danger-outline-active': '#FF7591',
    'button-danger-background-active': '#FFE4E9',

    'button-success-outline-active': '#6EAE6E',
    'button-success-background-active': '#E4F2E4',

    'button-info-outline-active': '#4FC3F7',
    'button-info-background-active': '#D5F4FE',

    'badge-grey': '#CCCCCC',
    'badge-red': '#F00023',
    'badge-red-2': '#FE4110',
    'badge-blue': '#4FC3F7',
    'badge-yellow': '#FDB900',

    'drumbeat-grey': '#666666',
    'drumbeat-green': '#56BE4C',
    'drumbeat-red': '#FF7692',
    'drumbeat-blue': '#D6F4FE',
    'drumbeat-yellow': '#FDBA00',

    'green-01': '#57BD4D', // drumbeat success green
    'green-02': '#204E2C', // header button green
    'green-03': '#93A898', // header disabled text green
    'green-04': '#E4F2E4', // yes fill green
    'green-05': '#6EAE6E', // yes outline green
    'green-06': '#01320E', // header green
    'green-07': '#CFE696', // drawer active green

    'gray-01': '#C8C8C8', // outline gray
    'gray-02': '#EDEDED', // background gray
    'gray-03': '#E1E1E1', // background gray
    'gray-04': '#CBCBCB', // outline gray
    'gray-05': '#9C9C9C', // inactive tab gray
    'gray-06': '#DFDFDF', // inactive tab gray
    'gray-07': '#F9F9F9', // expand action gray
    'gray-08': '#F7F8F9', // drumbeat graph footer
    'gray-09': '#E9E9E9', // drumbeat graph grid

    'blue-01': '#000099',
    'blue-02': '#D6F4FE',
    'blue-03': '#025073',
    'pink-01': '#FFABBC',
  },
};
