import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('bpEwells/Question')
export default class Question extends Model({
  id: prop<number>(),
  question: prop<string>(''),
  description: prop<string>(''),
  isActive: prop<boolean>(),
  isDescriptionRequired: prop<boolean>(),
  order: prop<number>(),
  versionId: prop<number>(),

  // foreign key id
  formSection: prop<number>(),
  formSectionVersionId: prop<string>(),
}) {
  @modelAction
  update(data: ModelCreationData<Question>) {
    Object.assign(this, data);
  }
}
