import { Layout, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { View } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

const AssessmentLabels = () => {
  const styles = useResponsiveStyleSheet(themedStyles);

  return (
    <Layout style={styles.labelsContainer}>
      <Text style={[styles.labelText, { flex: 0.75 }]}>ID</Text>
      <Text style={[styles.labelText, { flex: 3 }]}>Assessment</Text>
      <Text style={styles.labelText}>Site</Text>
      <View style={{ flex: 2 }}>
        <Text style={[styles.labelText]}>Assessor</Text>
      </View>
      <Text style={styles.labelText}>Date observed</Text>
      <View style={{ flex: 1 }}>
        <Text style={styles.labelText}>Status</Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={styles.labelText} />
      </View>
    </Layout>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    labelsContainer: {
      flex: 1,
      flexDirection: 'row',
      height: 25,
      paddingVertical: 5,
      paddingHorizontal: 16,
      marginVertical: 16,
      backgroundColor: '#DEDEDE',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    labelText: {
      flex: 1,
      fontSize: 12,
      marginHorizontal: 10,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-grey',
    },
  },
});

export default observer(AssessmentLabels);
