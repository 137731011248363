import * as Font from 'expo-font';
import { useState, useEffect } from 'react';

import { FontMapping } from '../constants/Fonts';
import { NativeStorageService } from '../services/storage/storage';
import { StorageService } from '../services/storage/storageService';
import { createStore, Store } from '../stores';

export default () => {
  const [storageService] = useState<StorageService>(new NativeStorageService());
  const [store, setStore] = useState<Store>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      const store = createStore(storageService);
      await store.load();
      await Font.loadAsync(FontMapping);
      await store.fetchInitialData();
      setStore(store);
      setReady(true);
    })();
  }, []);

  return {
    store,
    ready: !Object.values(ready).includes(false),
  };
};
