import * as Device from 'expo-device';
import { observable } from 'mobx';
import {
  model,
  Model,
  prop,
  _async,
  _await,
  modelAction,
  modelFlow,
  getRoot,
  ModelCreationData,
} from 'mobx-keystone';

import config from '../config';
import User from '../models/User';
import * as api from '../services/api';
import { StorageService } from '../services/storage/storageService';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('bpEwells/AuthStore')
export default class AuthStore extends Model({
  accessToken: prop<string | null>(null),
  refreshToken: prop<string | null>(null),
  user: prop<User | null>(null),
}) {
  storageService!: StorageService;

  @observable
  loading = false;

  @modelAction
  setUser = (user: User | null) => {
    this.user = user;
  };

  @modelFlow
  load = _async(function* (this: AuthStore) {
    this.loading = true;

    this.accessToken = yield* _await(
      this.storageService.getItemAsync(config.tokenKey),
    );

    this.loading = false;
  });

  @modelFlow
  removeToken = _async(function* (this: AuthStore) {
    this.refreshToken = null;
    this.accessToken = null;
    yield* _await(this.storageService.removeItemAsync(config.tokenKey));
  });

  @modelFlow
  storeTokens = _async(function* (
    this: AuthStore,
    accessToken: string,
    refreshToken: string,
  ) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    yield* _await(
      this.storageService.setItemAsync(config.tokenKey, accessToken),
    );
  });

  @modelFlow
  _signIn = _async(function* (
    this: AuthStore,
    accessToken: string,
    refreshToken: string,
    user: any,
  ) {
    yield* _await(this.storeTokens(accessToken, refreshToken));
    const newUser = new User(user);
    this.setUser(newUser);
    const rootStore = getRoot<Store>(this);
    yield* _await(rootStore.fetchInitialData());
    rootStore.setIsSignInComplete(true);
  });

  @modelFlow
  signIn = _async(function* (this: AuthStore, code: string | undefined) {
    if (code) {
      const type = yield* _await(Device.getDeviceTypeAsync());

      let deviceType = '';
      switch (type) {
        case Device.DeviceType.PHONE:
          deviceType = 'Mobile';
          break;
        case Device.DeviceType.TABLET:
          deviceType = 'Tablet';
          break;
        case Device.DeviceType.DESKTOP:
          deviceType = 'Desktop';
          break;
        default:
          deviceType = 'Unknown';
      }

      const osName = Device.osName || '';
      const osVersion = Device.osVersion || '';
      const deviceName = Device.deviceName || '';
      const modelName = Device.modelName || '';

      try {
        const {
          response: { entities },
        } = yield* _await(
          api.signIn({
            code,
            osName,
            osVersion,
            deviceName,
            deviceType,
            modelName,
          }),
        );
        yield* _await(
          this._signIn(
            entities.accessToken,
            entities.refreshToken,
            entities.user,
          ),
        );
      } catch (error) {
        yield* _await(this.checkToken(error));
        return getError(error);
      }
      return getSuccess();
    }
  });

  @modelFlow
  checkSelf = _async(function* (this: AuthStore) {
    if (!this.accessToken) {
      return getSuccess();
    }

    try {
      yield* _await(api.checkSelf(this.accessToken));
    } catch (error) {
      yield* _await(this.checkToken(error));
      return getError(error);
    }

    return getSuccess();
  });

  @modelFlow
  refreshAccessToken = _async(function* (this: AuthStore) {
    if (this.refreshToken) {
      try {
        const {
          response: { entities },
        } = yield* _await(
          api.refreshAccessToken({ refresh: this.refreshToken }),
        );
        yield* _await(
          this.storeTokens(entities.accessToken, entities.refreshToken),
        );
      } catch (error) {
        console.warn('[DEBUG] refresh token error', error);
        return getError(error);
      }
      return getSuccess();
    }
  });

  @modelFlow
  checkToken = _async(function* (this: AuthStore, error: Error | unknown) {
    this.loading = true;

    if (!(error instanceof Error)) {
      return;
    }

    try {
      const message = JSON.parse(error.message);
      const isAssessmentError =
        !!message.nonFieldErrors && message.nonFieldErrors.length > 0;
      const isDeletionError =
        !!message.code && message.code === 'permission_denied';
      const isUpdatingAssessmentError =
        !!message.detail &&
        message.detail === 'Cannot set a submitted assessment as draft.';
      const isNotFoundError =
        !!message.detail && message.detail === 'Not found.';

      if (
        message.code &&
        message.code === 'token_not_valid' &&
        !!this.refreshToken
      ) {
        yield* _await(this.refreshAccessToken());
        return true;
      } else if (
        !isAssessmentError &&
        !isDeletionError &&
        !isUpdatingAssessmentError &&
        !isNotFoundError
      ) {
        this.logOut();
      }
      return false;
    } catch {}
  });

  @modelFlow
  logOut = _async(function* (this: AuthStore) {
    if (this.accessToken) {
      try {
        yield* _await(api.logOut(this.accessToken));
      } catch (error) {
        console.warn('[DEBUG] log out error', error);
      }
    }

    const rootStore = getRoot<Store>(this);
    yield* _await(rootStore.reset());
  });

  @modelFlow
  reset = _async(function* (this: AuthStore) {
    yield* _await(this.removeToken());
    this.setUser(null);
  });

  @modelAction
  updateUser(data: ModelCreationData<User>) {
    if (this.user) {
      this.user.update(data);
    }
  }
}
