import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import HistoricalAssessmentForm from '../models/AssessmentForm';
import HistoricalFormSection from '../models/FormSection';
import HistoricalQuestion from '../models/Question';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('bpEwells/HistoricalAssessmentFormStore')
export default class HistoricalAssessmentFormStore extends Model({
  historicalAssessmentForms: prop(() => objectMap<HistoricalAssessmentForm>()),
  historicalFormSections: prop(() => objectMap<HistoricalFormSection>()),
  historicalQuestions: prop(() => objectMap<HistoricalQuestion>()),
}) {
  @observable
  loading = false;

  getAssessmentForm = (
    versionId: number,
  ): HistoricalAssessmentForm | undefined => {
    return Array.from(this.historicalAssessmentForms.values()).find(
      (form) => form.versionId === versionId,
    );
  };

  getFormSections = (): HistoricalFormSection[] => {
    return Array.from(this.historicalFormSections.values());
  };

  getQuestions = (): HistoricalQuestion[] => {
    return Array.from(this.historicalQuestions.values());
  };

  @modelAction
  createOrUpdateHistoricalAssessmentForm(
    data: ModelCreationData<HistoricalAssessmentForm>,
  ) {
    const versionId = `${data.versionId}`;

    let assessmentForm: HistoricalAssessmentForm;
    if (this.historicalAssessmentForms.has(versionId)) {
      assessmentForm = this.historicalAssessmentForms.get(versionId)!;
    } else {
      assessmentForm = new HistoricalAssessmentForm(data);
      this.historicalAssessmentForms.set(versionId, assessmentForm);
    }

    assessmentForm.update(data);
  }

  @modelAction
  createOrUpdateHistoricalFormSection(
    data: ModelCreationData<HistoricalFormSection>,
  ) {
    const versionId = `${data.versionId}`;

    let formSection: HistoricalFormSection;
    if (this.historicalFormSections.has(versionId)) {
      formSection = this.historicalFormSections.get(versionId)!;
    } else {
      formSection = new HistoricalFormSection(data);
      this.historicalFormSections.set(versionId, formSection);
    }

    formSection.update(data);
  }

  @modelAction
  createOrUpdateHistoricalQuestion(
    data: ModelCreationData<HistoricalQuestion>,
  ) {
    const versionId = `${data.versionId}`;

    let question: HistoricalQuestion;
    if (this.historicalQuestions.has(versionId)) {
      question = this.historicalQuestions.get(versionId)!;
    } else {
      question = new HistoricalQuestion(data);
      this.historicalQuestions.set(versionId, question);
    }

    question.update(data);
  }

  @modelFlow
  fetchAssessmentFormDetails = _async(function* (
    this: HistoricalAssessmentFormStore,
    historyId: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let historicalAssessmentForm: ModelCreationData<HistoricalAssessmentForm>;
    let historicalFormSections: ModelCreationData<HistoricalFormSection>[];
    let historicalQuestions: ModelCreationData<HistoricalQuestion>[];

    try {
      ({
        response: {
          entities: {
            historicalAssessmentForm,
            historicalFormSections,
            historicalQuestions,
          },
        },
      } = yield* _await(
        api.fetchAssessmentFormVersionDetails(
          rootStore.authStore.accessToken,
          historyId,
        ),
      ));
    } catch (error) {
      console.warn(
        '[DEBUG] error fetching assessment form version details',
        error,
      );
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.createOrUpdateHistoricalAssessmentForm(historicalAssessmentForm);
    historicalQuestions.forEach((data) =>
      this.createOrUpdateHistoricalQuestion(data),
    );
    historicalFormSections.forEach((data) =>
      this.createOrUpdateHistoricalFormSection(data),
    );

    this.loading = false;
    return getSuccess();
  });
}
