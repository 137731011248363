import { Icon, Text, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { View } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

const ActionTrackerLabels = () => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();

  return (
    <>
      <View style={styles.labelsContainer}>
        <View
          style={[styles.labelCell, { flex: 125 / 1500, flexDirection: 'row' }]}
        >
          <Icon
            name="chevron-right-outline"
            width={22}
            height={22}
            fill={theme['text-white']}
          />
          <Text style={styles.labelText}>Action ID</Text>
        </View>
        <View style={[styles.labelCell, { flex: 324 / 1500 }]}>
          <Text style={styles.labelText}>Description</Text>
        </View>
        <View style={[styles.labelCell, { flex: 217 / 1500 }]}>
          <Text style={styles.labelText}>Status</Text>
        </View>
        <View style={[styles.labelCell, { flex: 170 / 1500 }]}>
          <Text style={styles.labelText}>Date created</Text>
        </View>
        <View style={[styles.labelCell, { flex: 168 / 1500 }]}>
          <Text style={styles.labelText}>Due date</Text>
        </View>
        <View style={[styles.labelCell, { flex: 196 / 1500 }]}>
          <Text style={styles.labelText}>Date closed</Text>
        </View>
        <View style={[styles.labelCell, { flex: 182 / 1500 }]}>
          <Text style={styles.labelText}>Site</Text>
        </View>
        <View style={[styles.labelCell, { flex: 118 / 1500 }]}>
          <Text style={styles.labelText}>Owner</Text>
        </View>
      </View>
      <View style={styles.separator} />
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    labelsContainer: {
      flexDirection: 'row',
      height: 41,
      paddingTop: 16,
      marginTop: 16,
      backgroundColor: 'text-white',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    labelCell: {
      marginHorizontal: 15,
    },
    labelText: {
      fontSize: 12,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-grey',
    },
    separator: {
      backgroundColor: 'gray-03',
      height: 1,
    },
  },
});

export default observer(ActionTrackerLabels);
