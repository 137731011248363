import { RouteProp, useIsFocused } from '@react-navigation/native';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Linking,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { TabView, Route } from 'react-native-tab-view';

import DrumbeatSchedule from '../../components/Dashboard/DrumbeatSchedule';
import MyStatistics from '../../components/Dashboard/MyStatistics';
import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { MainDrawerParamList } from '../../navigation';
import { useStore } from '../../stores';

type Props = { route: RouteProp<MainDrawerParamList, 'Dashboard'> };

const Dashboard: React.FC<Props> = (_props) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const layout = useWindowDimensions();
  const isFocused = useIsFocused();
  const { setCurrentlyEditingAssessment } = useStore();

  const onLinkPress = async () => {
    const url = config.pbiUrl;

    if (url !== '') {
      const canOpen = await Linking.canOpenURL(url);
      if (canOpen) {
        //@ts-ignore
        Linking.openURL(url, '_blank');
      }
    }
  };

  useEffect(() => {
    if (isFocused) {
      setCurrentlyEditingAssessment(-1);
    }
  }, [isFocused]);

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'first', title: 'Dashboard' },
    { key: 'second', title: 'Schedule' },
  ]);

  const renderScene = ({ route }: { route: Route }) => {
    switch (route.key) {
      case 'first':
        return <MyStatistics setIndex={setIndex} index={index} />;
      case 'second':
        return <DrumbeatSchedule index={index} />;
      default:
        return null;
    }
  };

  const renderTabBar = useCallback(() => {
    return (
      <View style={styles.topContainer}>
        <View style={styles.tabsContainer}>
          <TouchableOpacity
            onPress={() => {
              setIndex(0);
            }}
            activeOpacity={1}
            style={[{ marginRight: 32 }, index === 0 && styles.tabUnderline]}
          >
            <Text
              style={[
                styles.text,
                index === 0 && { color: theme['text-dark'] },
              ]}
            >
              Dashboard
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setIndex(1);
            }}
            style={[{ marginRight: 32 }, index === 1 && styles.tabUnderline]}
            activeOpacity={1}
          >
            <Text
              style={[
                styles.text,
                index === 1 && { color: theme['text-dark'] },
              ]}
            >
              Schedule
            </Text>
          </TouchableOpacity>
          {config.pbiUrl !== '' && (
            <TouchableOpacity
              onPress={() => {
                onLinkPress();
              }}
              activeOpacity={1}
              style={[
                { flexDirection: 'row' },
                index === 2 && styles.tabUnderline,
              ]}
            >
              <Icon
                name="external-link-outline"
                width={20}
                height={20}
                fill={theme['gray-05']}
                style={{ marginRight: 5 }}
              />
              <Text
                style={[
                  styles.text,
                  index === 2 && { color: theme['text-dark'] },
                ]}
              >
                Power BI Dashboard
              </Text>
            </TouchableOpacity>
          )}
        </View>
        {index === 1 && (
          <View style={styles.legendsContainer}>
            <View
              style={[styles.legend, { backgroundColor: theme['bp-green'] }]}
            />
            <Text>COMPLETED</Text>
            <View
              style={[styles.legend, { backgroundColor: theme['badge-red'] }]}
            />
            <Text>OVERDUE</Text>
            <View
              style={[styles.legend, { backgroundColor: theme['badge-blue'] }]}
            />
            <Text>SCHEDULED</Text>
            <View
              style={[
                styles.legend,
                { backgroundColor: theme['badge-yellow'] },
              ]}
            />
            <Text>EVENT-DRIVEN</Text>
          </View>
        )}
      </View>
    );
  }, [index]);

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      renderTabBar={renderTabBar}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
    />
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
    },
    topContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 26,
      marginVertical: 16,
    },
    tabsContainer: {
      flexDirection: 'row',
      width: 164,
    },
    tabUnderline: {
      borderBottomWidth: 3,
      borderBottomColor: 'bp-green',
    },
    legendsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      width: 550,
    },
    legend: {
      height: 12,
      width: 12,
      marginLeft: 25,
      marginRight: 8,
    },
    text: {
      fontSize: 16,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'gray-05',
    },
  },
  tabletStyle: {
    topContainer: {
      paddingHorizontal: 24,
    },
  },
});

export default observer(Dashboard);
