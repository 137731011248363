import { cloneDeep } from 'lodash';
import { observable } from 'mobx';
import {
  applySnapshot,
  getSnapshot,
  Model,
  model,
  modelFlow,
  _async,
  _await,
  prop,
  modelAction,
} from 'mobx-keystone';

import config from '../config';
import { StorageService } from '../services/storage/storageService';
import { FilterData } from '../types';
import ActionStore from './ActionStore';
import AssessmentFormStore from './AssessmentFormStore';
import AssessmentScheduleStore from './AssessmentScheduleStore';
import AssessmentStore from './AssessmentStore';
import AuthStore from './AuthStore';
import CategoryStore from './CategoryStore';
import HistoricalAssessmentFormStore from './HistoricalAssessmentFormStore';
import PerformanceUnitStore from './PerformanceUnitStore';
import SelfVerificationTypeStore from './SelfVerificationTypeStore';
import SiteStore from './SiteStore';
import SiteTypeStore from './SiteTypeStore';
import UserMetricsStore from './UserMetricsStore';
import UserStore from './UserStore';

@model('bpEwells/Store')
export default class Store extends Model({
  authStore: prop<AuthStore>(),
  userStore: prop<UserStore>(),
  actionStore: prop<ActionStore>(),
  assessmentFormStore: prop<AssessmentFormStore>(),
  historicalAssessmentFormStore: prop<HistoricalAssessmentFormStore>(),
  assessmentStore: prop<AssessmentStore>(),
  assessmentScheduleStore: prop<AssessmentScheduleStore>(),
  categoryStore: prop<CategoryStore>(),
  performanceUnitStore: prop<PerformanceUnitStore>(),
  selfVerificationTypeStore: prop<SelfVerificationTypeStore>(),
  siteStore: prop<SiteStore>(),
  siteTypeStore: prop<SiteTypeStore>(),
  userMetricsStore: prop<UserMetricsStore>(),

  isInternetReachable: prop<boolean | null>(false),
  isFromSiteModal: prop<boolean>(false),
  isFromAssessment: prop<boolean>(false),
  isSiteScheduleChanged: prop<boolean>(false),
  isStatisticsChanged: prop<boolean>(false),
  isCatActionsChanged: prop<boolean>(false),
  isAssessmentsChanged: prop<boolean>(false),
  isGlobalAutosaving: prop<boolean>(false),
  currentlyEditingAssessment: prop<number>(-1),
  showToast: prop<boolean>(false),
  toastData: prop<{
    message: string;
    success?: boolean;
    error?: boolean;
    duration?: number;
  } | null>(null),
  filters: prop<FilterData | null>(null),

  // for stack changing
  isSignInComplete: prop<boolean>(false),
}) {
  storageService!: StorageService;
  blacklist = [];

  @observable
  loading = false;

  @observable
  fetching = false;

  @modelAction
  showToastMessage = (
    toast: boolean,
    data: {
      message: string;
      success?: boolean;
      error?: boolean;
      toastDuration?: number;
    } | null,
  ) => {
    this.showToast = toast;
    this.toastData = data;
  };

  @modelAction
  resetToastMessage = () => {
    this.showToast = false;
    this.toastData = null;
  };

  @modelAction
  setFilters = (data: FilterData) => {
    this.filters = data;
  };

  @modelAction
  setIsInternetReachable = (isInternetReachable: boolean | null) => {
    this.isInternetReachable = isInternetReachable;
  };

  @modelAction
  setIsFromSiteModal = (isFromSiteModal: boolean) => {
    this.isFromSiteModal = isFromSiteModal;
  };

  @modelAction
  setIsFromAssessment = (isFromAssessment: boolean) => {
    this.isFromAssessment = isFromAssessment;
  };

  @modelAction
  setIsSiteScheduleChanged = (isSiteScheduleChanged: boolean) => {
    this.isSiteScheduleChanged = isSiteScheduleChanged;
  };

  @modelAction
  setIsAssessmentsChanged = (isAssessmentChanged: boolean) => {
    this.isAssessmentsChanged = isAssessmentChanged;
  };

  @modelAction
  setIsStatisticsChanged = (isStatisticsChanged: boolean) => {
    this.isStatisticsChanged = isStatisticsChanged;
  };

  @modelAction
  setIsCatActionsChanged = (isCatActionsChanged: boolean) => {
    this.isCatActionsChanged = isCatActionsChanged;
  };

  @modelAction
  setIsSignInComplete = (isSignInComplete: boolean) => {
    this.isSignInComplete = isSignInComplete;
  };

  @modelAction
  setIsGlobalAutosaving = (isGlobalAutosaving: boolean) => {
    this.isGlobalAutosaving = isGlobalAutosaving;
  };

  @modelAction
  setCurrentlyEditingAssessment = (currentlyEditingAssessment: number) => {
    this.currentlyEditingAssessment = currentlyEditingAssessment;
  };

  @modelFlow
  setUserDefaultFilterData = _async(function* (this: Store) {
    if (!this.authStore || !this.performanceUnitStore || !this.siteStore) {
      return;
    }

    const user = this.authStore.user?.id
      ? this.authStore.user.id.toString()
      : '';

    const performanceUnit = () => {
      const performanceUnitId = this.authStore.user?.performanceUnit
        ? this.performanceUnitStore.getPerformanceUnit(
            this.authStore.user?.performanceUnit,
          )?.id
        : undefined;

      return performanceUnitId ? performanceUnitId.toString() : '';
    };
    const performanceUnitId = performanceUnit();

    const site = () => {
      const site = this.siteStore
        .getSites()
        .find(
          (site) =>
            this.authStore.user?.sites.includes(site.id) &&
            site.performanceUnit === Number(performanceUnitId),
        );
      return site && site.id ? site.id.toString() : '';
    };
    const siteId = site();

    const dateStart = new Date(new Date().getFullYear(), 0, 1).toISOString();
    const dateEnd = new Date(new Date().getFullYear(), 11, 31).toISOString();

    this.filters = {
      user,
      performanceUnit: performanceUnitId,
      site: siteId,
      dateStart,
      dateEnd,
    };
  });

  @modelFlow
  load = _async(function* (this: Store) {
    this.loading = true;

    let data: any | string | null;
    data = yield* _await(this.storageService.getItemAsync(config.storeKey));
    if (data) {
      data = JSON.parse(data);
      this.blacklist.forEach((key) => {
        delete data[key];
      });

      try {
        applySnapshot(this, {
          ...getSnapshot(this),
          ...data,
          $modelId: this.$modelId,
        });
      } catch (error) {
        console.warn('[DEBUG] error while loading from stored data', error);
      }
    }

    // Load auth store separately.
    yield* _await(this.authStore.load());

    this.loading = false;
  });

  @modelFlow
  fetchInitialData = _async(function* (this: Store) {
    this.loading = true;
    this.fetching = true;

    // yield* _await(
    //   this.storageService.removeAllSets(`${config.filesKeyPrefix}`),
    // );

    this.userStore = new UserStore({});
    this.assessmentStore = new AssessmentStore({});
    this.assessmentFormStore = new AssessmentFormStore({});
    this.historicalAssessmentFormStore = new HistoricalAssessmentFormStore({});
    this.assessmentScheduleStore = new AssessmentScheduleStore({});
    this.actionStore = new ActionStore({});
    this.categoryStore = new CategoryStore({});
    this.performanceUnitStore = new PerformanceUnitStore({});
    this.selfVerificationTypeStore = new SelfVerificationTypeStore({});
    this.siteStore = new SiteStore({});
    this.siteTypeStore = new SiteTypeStore({});

    // yield* _await(this.assessmentStore.fetchAssessments());
    // yield* _await(this.assessmentStore.fetchAnswers());
    // yield* _await(this.assessmentStore.fetchAttachments());
    // yield* _await(this.assessmentFormStore.fetchFormSections());
    // yield* _await(this.assessmentFormStore.fetchQuestions());
    // yield* _await(this.assessmentScheduleStore.fetchAssessmentSchedules());
    // yield* _await(this.actionStore.fetchActions());

    yield* _await(this.actionStore.refreshCatActions());
    yield* _await(this.assessmentFormStore.fetchAssessmentForms());
    yield* _await(this.categoryStore.fetchCategories());
    yield* _await(this.performanceUnitStore.fetchPerformanceUnits());
    yield* _await(this.selfVerificationTypeStore.fetchSelfVerificationTypes());
    yield* _await(this.siteStore.fetchSites());
    yield* _await(this.siteTypeStore.fetchSiteTypes());
    yield* _await(this.userStore.fetchUsers());
    yield* _await(this.assessmentStore.fetchUserDraftAssessments());

    yield* _await(this.setUserDefaultFilterData());
    yield* _await(this.userMetricsStore.populateDeviceInfo());

    this.loading = false;
    this.fetching = false;
  });

  @modelFlow
  reset = _async(function* (this: Store) {
    yield* _await(this.authStore.reset());
    yield* _await(this.storageService.removeItemAsync(config.storeKey));

    this.isSignInComplete = false;
    this.isInternetReachable = false;
    this.isFromSiteModal = false;
    this.isFromAssessment = false;
    this.isSiteScheduleChanged = false;
    this.isAssessmentsChanged = false;
    this.isStatisticsChanged = false;
    this.isCatActionsChanged = false;
    this.isGlobalAutosaving = false;
    this.currentlyEditingAssessment = -1;
    this.resetToastMessage();
    this.filters = {};

    this.userStore = new UserStore({});
    this.assessmentStore = new AssessmentStore({});
    this.assessmentFormStore = new AssessmentFormStore({});
    this.historicalAssessmentFormStore = new HistoricalAssessmentFormStore({});
    this.assessmentScheduleStore = new AssessmentScheduleStore({});
    this.actionStore = new ActionStore({});
    this.categoryStore = new CategoryStore({});
    this.performanceUnitStore = new PerformanceUnitStore({});
    this.selfVerificationTypeStore = new SelfVerificationTypeStore({});
    this.siteStore = new SiteStore({});
    this.siteTypeStore = new SiteTypeStore({});
    this.userMetricsStore = new UserMetricsStore({
      osName: '',
      osVersion: '',
      deviceName: '',
      deviceType: '',
      modelName: '',
    });
  });

  save = async () => {
    const data = cloneDeep(getSnapshot(this));

    // Exclude token from storage
    data.authStore.accessToken = '';
    data.authStore.refreshToken = '';

    data.filters = {};

    await this.storageService.setItemAsync(
      config.storeKey,
      JSON.stringify(data),
    );
  };
}
