export abstract class StorageService {
  abstract getItemAsync: (key: string) => Promise<string | null>;
  abstract setItemAsync: (key: string, item: string) => Promise<void>;
  abstract removeItemAsync: (key: string) => Promise<void>;
  abstract addToSet: (
    setId: string,
    key: string,
    item: string,
  ) => Promise<void>;
  abstract removeSet: (setId: string) => Promise<void>;
  abstract removeAllSets: (prefix: string) => Promise<void>;
  abstract clearAsync: () => Promise<void>;
  abstract getAllKeys: () => Promise<string[] | null>;
}
