import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Button, Icon, Spinner, Text, useTheme } from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import { ModelCreationData } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ActivityIndicator, FlatList, View, Linking } from 'react-native';

import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import useUserDefaultDetails from '../../hooks/useUserDefaultDetails';
import Assessment from '../../models/Assessment';
import { AppStackNavigation } from '../../navigation';
import { useStore } from '../../stores';
import {
  ActionStatistics,
  FilterData,
  GapStatistics,
  ScheduleStatistics,
} from '../../types';
import { useUserFirstName } from '../../utils/helper';
import AssessmentItem from '../Assessments/AssessmentItem';
import AssessmentLabels from '../Assessments/AssessmentLabels';
import AutocompleteComponent from '../Common/AutocompleteComponent';
import ActionGapsGraph from './ActionGapsGraph';
import ActionTrackingGraph from './ActionTrackingGraph';
import AssessmentsGraph from './AssessmentsGraph';
import GraphCard from './GraphCard';
import MyStatisticsFilters from './MyStatisticsFilters';

type Props = {
  setIndex: (page: number) => void;
  index: number;
};

type StatisticsData = {
  gaps: GapStatistics;
  actions: ActionStatistics;
  schedules: ScheduleStatistics[];
};

const MyStatistics: React.FC<Props> = ({ setIndex, index }) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const navigation = useNavigation<AppStackNavigation>();
  const store = useStore();
  const {
    assessmentStore,
    setIsStatisticsChanged,
    isStatisticsChanged,
    setIsFromAssessment,
    isFromAssessment,
    performanceUnitStore,
    siteStore,
    isInternetReachable,
    isGlobalAutosaving,
  } = store;
  const isFocused = useIsFocused();
  const theme = useTheme();
  const userFirstName = useUserFirstName();
  const userDefaultDetails = useUserDefaultDetails();

  const [loadingFooter, setLoadingFooter] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState<StatisticsData>({
    schedules: [
      {
        order: 0,
        month: 0,
        year: 0,
        completed: 0,
        overdue: 0,
        scheduled: 0,
      },
    ],
    actions: {
      actionsPastDueCounter: 0,
      closedActionsCounter: 0,
      openActionsCounter: 0,
    },
    gaps: { createdCounter: 0, requiredCounter: 0, noActionCounter: 0 },
  });
  const [assessments, setAssessments] = useState<
    ModelCreationData<Assessment>[]
  >([]);
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const loadingRef = useRef(false);
  const page = useRef<number>(-1);
  const filters = store.filters || {};

  const [dateRange, setDateRange] = useState<string>(
    `${
      filters.dateStart ? format(parseISO(filters.dateStart), 'MMM yyyy') : ''
    } - ${
      filters.dateEnd ? format(parseISO(filters.dateEnd), 'MMM yyyy') : ''
    }`,
  );
  const dateRangeData = [{ id: dateRange, text: dateRange }];

  const performanceUnitFilters = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    performanceUnitStore
      .getActivePerformanceUnits()
      .forEach((performanceUnit) => {
        values.push({
          id: performanceUnit.id.toString(),
          text: performanceUnit.name,
        });
      });
    return values;
  }, [performanceUnitStore.performanceUnits.values()]);

  const siteFilters = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    siteStore.getActiveSites().forEach((site) => {
      values.push({
        id: site.id.toString(),
        text: site.name,
      });
    });
    return values;
  }, [siteStore.sites.values()]);

  useEffect(() => {
    if (isFocused && isInternetReachable) {
      if (isStatisticsChanged || !isFromAssessment) {
        onFilterSubmit(filters);
        setIsStatisticsChanged(false);
      } else if (isFromAssessment) {
        setIsFromAssessment(false);
      }
    }
  }, [isFocused]);

  useEffect(() => {
    if (refresh) {
      onFilterSubmit(filters);
      setRefresh(false);
    }
  }, [refresh]);

  const userAssessments = useMemo(() => {
    return assessmentStore
      .getAssessments()
      .filter(
        (assessment) =>
          assessment.isDraft &&
          (assessment.assessor === Number(userDefaultDetails.id) ||
            assessment.creator === Number(userDefaultDetails.id)),
      );
  }, [assessmentStore.assessments.values()]);

  useEffect(() => {
    if (!isInternetReachable) {
      setAssessments(userAssessments);
    } else {
      onFilterSubmit(filters);
    }
  }, [isInternetReachable]);

  const onLinkPress = async () => {
    const url = config.amdUrl;

    if (url !== '') {
      const canOpen = await Linking.canOpenURL(url);
      if (canOpen) {
        //@ts-ignore
        Linking.openURL(url, '_blank');
      }
    }
  };

  const onFilterSubmit = async (data: FilterData) => {
    if (
      loading ||
      loadingRef.current ||
      loadingFooter ||
      !isInternetReachable
    ) {
      return;
    }

    loadingRef.current = true;
    setLoading(true);

    page.current = 1;
    const result = await assessmentStore.fetchStatistics(data, page.current);
    if (result.ok) {
      setData(result.extra?.statistics as StatisticsData);
      setAssessments(
        result.extra?.assessments as ModelCreationData<Assessment>[],
      );

      if (result.extra && result.extra.next) {
        page.current += 1;
      } else {
        page.current = -1;
      }

      if (result.extra?.count) {
        setTotalCount(result.extra.count);
      }
    } else {
      page.current = -1;
      setLoadingFooter(false);
    }

    loadingRef.current = false;
    setLoading(false);
  };

  const fetchNextStatistics = async () => {
    if (
      loading ||
      loadingFooter ||
      loadingRef.current ||
      page.current === -1 ||
      !isInternetReachable
    ) {
      return;
    }

    setLoadingFooter(true);

    const result = await assessmentStore.fetchStatistics(filters, page.current);

    if (result.ok) {
      setAssessments([
        ...assessments,
        ...(result.extra?.assessments as ModelCreationData<Assessment>[]),
      ]);

      if (result.extra?.next === null) {
        page.current = -1;
      } else {
        page.current += 1;
      }
    } else {
      page.current = -1;
    }

    setLoadingFooter(false);
  };

  useEffect(() => {
    if (filters) {
      let dateRangeText = '-';
      if (filters.dateStart && filters.dateEnd) {
        dateRangeText = `${format(
          parseISO(filters.dateStart),
          'MMM yyyy',
        )}  - ${format(parseISO(filters.dateEnd), 'MMM yyyy')}`;
      } else if (filters.dateStart) {
        dateRangeText = `${format(
          parseISO(filters.dateStart),
          'MMM yyyy',
        )} onwards`;
      } else if (filters.dateEnd) {
        dateRangeText = `Up to ${format(
          parseISO(filters.dateEnd),
          'MMM yyyy',
        )}`;
      } else {
        dateRangeText = 'All';
      }
      dateRangeData.push({ id: dateRangeText, text: dateRangeText });
      setDateRange(dateRangeText);

      onFilterSubmit(filters);
    }
  }, [filters]);

  const renderItem = useCallback(
    ({ item }: any) => (
      <AssessmentItem
        assessment={item}
        cameFrom="Dashboard"
        setRefresh={setRefresh}
      />
    ),
    [],
  );

  const renderEmptyAssessments = useCallback(
    () =>
      loading || isGlobalAutosaving ? (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={theme['bp-green']} />
        </View>
      ) : (
        <Text style={styles.emptyAssessments}>No assessments found</Text>
      ),
    [loading, isGlobalAutosaving],
  );

  const renderFooter = () => {
    if (loading || assessments.length === 0) {
      return;
    }

    return (
      <View style={styles.footerContainer}>
        {totalCount ? (
          <Text style={styles.footerSummary}>
            Showing {assessments.length} of {totalCount} assessments
          </Text>
        ) : (
          <></>
        )}
        {page.current !== -1 ? (
          <Button
            status="primary"
            appearance="filled"
            accessoryLeft={(props) =>
              loadingFooter ? (
                <Spinner status="info" {...props} />
              ) : (
                <Icon
                  name="plus-outline"
                  fill={theme['text-white']}
                  {...props}
                />
              )
            }
            onPress={() => fetchNextStatistics()}
          >
            Load more assessments
          </Button>
        ) : (
          <></>
        )}
      </View>
    );
  };

  const renderHeader = () => (
    <>
      <View style={styles.filtersContainer}>
        <View style={styles.filtersRightContainer}>
          <View style={{ flex: 1 }}>
            <Text style={styles.userText}>Hello, {userFirstName}</Text>
          </View>
          <View style={{ ...styles.filtersDropdown, paddingLeft: 50 }}>
            <AutocompleteComponent
              label="Performance Unit"
              placeholder="All"
              onSelect={() => {}}
              data={performanceUnitFilters}
              value={filters.performanceUnit}
              disabled
              labelHalf
              marginTop={0}
            />
          </View>
          <View style={styles.filtersDropdown}>
            <AutocompleteComponent
              label="Site"
              placeholder="All"
              onSelect={() => {}}
              data={siteFilters}
              value={filters.site}
              disabled
              labelHalf
              marginTop={0}
            />
          </View>
          <View style={styles.filtersDropdown}>
            <AutocompleteComponent
              label="Date Range"
              placeholder="Select date range"
              onSelect={(id) => setDateRange(id)}
              data={dateRangeData}
              value={dateRange}
              disabled
              labelHalf
              marginTop={0}
            />
          </View>
          <Button
            style={styles.button}
            status="basic"
            size="medium"
            appearance="filled"
            onPress={() => setShowFilters((prev) => !prev)}
            disabled={!isInternetReachable}
            accessoryLeft={(props) => (
              <Icon name="funnel-outline" width={24} height={24} {...props} />
            )}
          >
            Filters
          </Button>
          {/* <Button
            style={styles.button}
            status="basic"
            size="medium"
            appearance="filled"
            onPress={() => console.log('Download')}
            accessoryLeft={(props) => (
              <Icon name="download-outline" width={24} height={24} {...props} />
            )}
          >
            Download
          </Button> */}
        </View>
      </View>

      <MyStatisticsFilters
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        onFilterSubmit={onFilterSubmit}
        actualIndex={index}
        filterIndex={0}
      />

      <View style={styles.graphsContainer}>
        <GraphCard
          title="Assessments"
          leftFooterText="Site Schedule"
          onLeftPressFooterText={() => setIndex(1)}
        >
          {loading ? (
            <View style={styles.loading}>
              <ActivityIndicator size="large" color={theme['bp-green']} />
            </View>
          ) : (
            <AssessmentsGraph data={data.schedules} />
          )}
        </GraphCard>
        <GraphCard
          title="Action created for gaps"
          leftFooterText="View all"
          onLeftPressFooterText={() =>
            navigation.navigate('Main', { screen: 'Actions' })
          }
        >
          {loading ? (
            <View style={styles.loading}>
              <ActivityIndicator size="large" color={theme['bp-green']} />
            </View>
          ) : (
            <ActionGapsGraph gaps={data.gaps} />
          )}
        </GraphCard>
        <GraphCard
          title="Action tracking [Conformance/Quality]"
          leftFooterText="View AMD"
          onLeftPressFooterText={() => {
            onLinkPress();
          }}
        >
          {loading ? (
            <View style={styles.loading}>
              <ActivityIndicator size="large" color={theme['bp-green']} />
            </View>
          ) : (
            <ActionTrackingGraph actions={data.actions} />
          )}
        </GraphCard>
      </View>
      <View style={styles.assessmentsHeaderContainer}>
        <Text style={styles.assessmentsHeaderText}>Assessments</Text>
        {isInternetReachable && !isGlobalAutosaving ? (
          <Button
            style={[styles.button, { width: 264 }]}
            status="basic"
            size="medium"
            appearance="filled"
            onPress={() =>
              navigation.navigate('Add Assessments', { cameFrom: 'Dashboard' })
            }
            accessoryLeft={(props) => (
              <Icon name="plus-outline" width={24} height={24} {...props} />
            )}
          >
            Create new assessment
          </Button>
        ) : (
          <></>
        )}
      </View>
      <AssessmentLabels />
    </>
  );

  return (
    <View style={styles.container}>
      <FlatList
        data={loading || isGlobalAutosaving ? [] : assessments}
        renderItem={renderItem}
        contentContainerStyle={[{ paddingBottom: 80, paddingRight: 26 }]}
        keyExtractor={(item) => `${item.id}`}
        ListHeaderComponent={renderHeader()}
        ListEmptyComponent={renderEmptyAssessments()}
        ListFooterComponent={renderFooter()}
      />
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      paddingLeft: 26,
    },
    filtersContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
      paddingTop: 6,
    },
    userText: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 32,
      color: 'text-dark',
    },
    filtersRightContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    filtersDropdown: {
      flex: 1,
      paddingHorizontal: 8,
    },
    button: {
      width: 180,
      height: 52,
      backgroundColor: 'text-white',
      borderColor: 'text-light',
      marginLeft: 8,
    },
    graphsContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    graphCard: {
      flex: 0.327,
      aspectRatio: 430 / 300,
      borderRadius: 16,
      backgroundColor: 'text-white',
    },
    assessmentsHeaderContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
      height: 52,
    },
    assessmentsHeaderText: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 20,
    },
    emptyAssessments: {
      textAlign: 'center',
      marginTop: 20,
      fontFamily: 'UniversLTPro_Regular',
    },
    loading: {
      width: '100%',
      height: 461,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footerContainer: {
      marginTop: 10,
      alignItems: 'center',
    },
    footerSummary: {
      fontSize: 14,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: '#232323',
      marginBottom: 10,
    },
  },
});

export default observer(MyStatistics);
