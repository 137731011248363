import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('bpEwells/Assessment')
export default class Assessment extends Model({
  id: prop<number>(),
  dateObserved: prop<string>(),
  isActive: prop<boolean>(),
  isDraft: prop<boolean>(),
  migratedAssessor: prop<string>(),
  scheduleId: prop<string>(),
  modified: prop<string>(),
  created: prop<string>(),
  assessmentFormName: prop<string>(),

  // foreign key ids
  performanceUnit: prop<number>(),
  site: prop<number>(),
  selfVerificationType: prop<number>(),
  category: prop<number>(),
  assessmentForm: prop<number>(),
  assessmentFormVersionId: prop<string>(),
  assessor: prop<number>(),
  creator: prop<number>(),
}) {
  @modelAction
  update(data: ModelCreationData<Assessment>) {
    Object.assign(this, data);
  }
}
