import { RouteProp, useNavigation } from '@react-navigation/native';
import { useCardAnimation } from '@react-navigation/stack';
import { Button, Icon, Text, useTheme } from '@ui-kitten/components';
import { format, differenceInMonths, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ActivityIndicator, Animated, Pressable, View } from 'react-native';

import AutocompleteComponent from '../../components/Common/AutocompleteComponent';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { AppStackNavigation, AppStackParamList } from '../../navigation';
import { useStore } from '../../stores';
import { getAssessmentIdFormat } from '../../utils/helper';

type Props = { route: RouteProp<AppStackParamList, 'Drumbeat Modal'> };

type FormData = {
  assessor: string;
};

const DrumbeatModal: React.FC<Props> = (props) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation<AppStackNavigation>();
  const { current } = useCardAnimation();
  const { assessmentScheduleData, categoryTitle } = props?.route?.params ?? {};
  const store = useStore();
  const {
    assessmentStore,
    assessmentFormStore,
    assessmentScheduleStore,
    authStore,
    categoryStore,
    userStore,
    siteStore,
    performanceUnitStore,
    setIsFromSiteModal,
    setIsSiteScheduleChanged,
    isGlobalAutosaving,
  } = store;

  const [loading, setLoading] = useState(true);

  const modalAssessmentForm = assessmentFormStore.getAssessmentForm(
    assessmentScheduleData.assessmentForm!,
  )?.name;
  const modalPerformanceUnit = performanceUnitStore.getPerformanceUnit(
    siteStore.getSite(assessmentScheduleData.site!)!.performanceUnit,
  )?.name;
  const modalSite = siteStore.getSite(assessmentScheduleData.site!)?.name;

  const modalCategory = categoryTitle;
  const modalAssessmentScheduleId =
    assessmentScheduleData.assessmentScheduleId!;
  const modalDateScheduled = assessmentScheduleData.dateScheduled!;
  const modalAssessmentId = assessmentScheduleData.assessment;
  const isEventDriven =
    !!assessmentScheduleData.duration && assessmentScheduleData.duration > 0;
  const modalAssessmentFormVersionId =
    assessmentScheduleData.assessmentFormVersionId!;

  const currentAssignedAssessorNameEmail = useMemo(() => {
    if (assessmentScheduleData.assignedAssessor) {
      return `${
        userStore
          .getUser(assessmentScheduleData.assignedAssessor)
          ?.firstName.toString() || ''
      } ${
        userStore
          .getUser(assessmentScheduleData.assignedAssessor)
          ?.lastName.toString() || ''
      }`;
    }
  }, [assessmentScheduleStore.assessmentSchedules.values()]);

  const users = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    userStore.getUsers({}).forEach((user) => {
      values.push({
        id: user.id.toString(),
        text: `${user.firstName} ${user.lastName}`,
      });
    });
    return values;
  }, [userStore.users.values()]);

  const assessmentDateObserved = useMemo(() => {
    return modalAssessmentId
      ? assessmentStore.getAssessment(modalAssessmentId)?.dateObserved
      : undefined;
  }, [assessmentStore.assessments.values()]);

  const assessmentAssessorId = useMemo(() => {
    return modalAssessmentId
      ? assessmentStore.getAssessment(modalAssessmentId)?.assessor
      : undefined;
  }, [assessmentStore.assessments.values()]);

  const assessor = userStore.getUser(
    assessmentAssessorId
      ? assessmentAssessorId
      : assessmentScheduleData.assignedAssessor!,
  );

  const assessmentAssessorFullName = assessor
    ? `${assessor?.firstName} ${assessor?.lastName}`
    : assessmentScheduleData.migratedAssessor;

  const answeredQuestions = useMemo(() => {
    return assessmentStore
      .getAnswers()
      .filter((answer) => answer.assessment === modalAssessmentId);
  }, [assessmentStore.answers.values()]);

  let noAnswerCounter = 0;
  let naAnswerCounter = 0;
  let yesAnswerCounter = 0;
  for (let i = 0; i < answeredQuestions.length; i++) {
    if (answeredQuestions[i].answer === 'No') {
      noAnswerCounter = noAnswerCounter + 1;
    } else if (answeredQuestions[i].answer === 'N/A') {
      naAnswerCounter = naAnswerCounter + 1;
    } else if (answeredQuestions[i].answer === 'Yes') {
      yesAnswerCounter = yesAnswerCounter + 1;
    }
  }

  const { register, setValue, handleSubmit, watch } = useForm<FormData>();

  useEffect(() => {
    setIsFromSiteModal(true);
  }, []);

  useEffect(() => {
    register('assessor', { required: false });
  }, [register]);

  useEffect(() => {
    if (assessmentScheduleData.assignedAssessor) {
      setValue('assessor', assessmentScheduleData.assignedAssessor.toString());
    }
  }, []);

  const onSubmitAssessor = useCallback(async () => {
    if (watch('assessor') === undefined) {
      return;
    }
    const result = await assessmentScheduleStore.assignAssessor(
      modalAssessmentScheduleId,
      Number(watch('assessor')),
    );
    if (result.ok) {
      setIsSiteScheduleChanged(true);
      navigation.navigate('Main', {
        screen: 'Dashboard',
      });
    }
  }, []);

  const onSubmitAssessorWrapped = handleSubmit(onSubmitAssessor);

  const onSubmitNotApplicable = useCallback(async () => {
    const result = await assessmentScheduleStore.setNotApplicable(
      modalAssessmentScheduleId,
    );
    if (result.ok) {
      setIsSiteScheduleChanged(true);
      navigation.navigate('Main', {
        screen: 'Dashboard',
      });
    }
  }, []);

  const modalAssessmentFormId =
    assessmentScheduleData.assessmentForm!.toString();
  const modalPerformanceUnitId = siteStore
    .getSite(assessmentScheduleData.site!)
    ?.performanceUnit.toString();
  const modalSiteId = assessmentScheduleData.site!.toString();
  const modalCategoryId = categoryStore
    .getCategories()
    .find((c) => c.name === categoryTitle)
    ?.id.toString();
  const modalSvtId = assessmentFormStore
    .getAssessmentForm(Number(modalAssessmentFormId))
    ?.selfVerificationType.toString();

  let isNotLate = false;
  if (modalAssessmentId) {
    const schedule = assessmentScheduleData;
    const dateScheduled = parseISO(schedule.dateScheduled!);
    const dateObserved = parseISO(assessmentDateObserved!);
    isNotLate =
      schedule.duration && schedule.duration > 1
        ? differenceInMonths(
            dateObserved,
            dateScheduled.setMonth(
              dateScheduled.getMonth() + schedule.duration,
            ),
          ) < 1
        : differenceInMonths(dateObserved, dateScheduled) < 2;
  }

  const onCreate = useCallback(
    () =>
      navigation.navigate('Add Assessments', {
        modalAssessmentScheduleId,
        modalAssessmentFormId,
        modalAssessmentFormVersionId,
        modalPerformanceUnitId,
        modalSiteId,
        modalCategoryId,
        modalSvtId,
        cameFrom: 'Schedule',
      }),
    [
      modalAssessmentFormId,
      modalPerformanceUnitId,
      modalSiteId,
      modalCategoryId,
      modalSvtId,
    ],
  );

  useEffect(() => {
    (async () => {
      if (modalAssessmentId) {
        await assessmentStore.fetchAssessmentHistoricalDetails(
          modalAssessmentId,
        );
      }
      setLoading(false);
    })();
  }, []);

  return (
    <View style={styles.container}>
      <Pressable
        style={styles.pressable}
        onPress={() =>
          navigation.navigate('Main', {
            screen: 'Dashboard',
          })
        }
      />
      <Animated.View
        style={[
          styles.card,
          {
            transform: [
              {
                scale: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.9, 1],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}
      >
        {loading || isGlobalAutosaving ? (
          <View style={styles.loading}>
            <ActivityIndicator size="large" color={theme['bp-green']} />
          </View>
        ) : (
          <>
            {!modalAssessmentId ? (
              <Text style={styles.title}>Scheduled</Text>
            ) : differenceInMonths(
                parseISO(assessmentDateObserved!),
                parseISO(modalDateScheduled),
              ) < 1 ? (
              <Text style={styles.title}>Completed</Text>
            ) : (
              <Text style={styles.title}>Overdue</Text>
            )}
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={styles.labelText}>Assessment</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.dataText}>{modalAssessmentForm}</Text>
              </View>
            </View>
            {modalAssessmentId && (
              <View style={styles.row}>
                <View style={styles.label}>
                  <Text style={styles.labelText}>Assessment Id</Text>
                </View>
                <View style={styles.data}>
                  <Text style={styles.dataText}>
                    AS-{getAssessmentIdFormat(modalAssessmentId.toString())}
                  </Text>
                </View>
              </View>
            )}
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={styles.labelText}>Performance Unit</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.dataText}>{modalPerformanceUnit}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={styles.labelText}>Site</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.dataText}>{modalSite}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.label}>
                <Text style={styles.labelText}>Category</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.dataText}>
                  {isEventDriven ? 'Event-Driven  -- ' : ''}
                  {modalCategory}
                </Text>
              </View>
            </View>

            {!modalAssessmentId ? (
              <>
                {authStore.user?.isSiteManager ? (
                  <>
                    <View style={styles.row}>
                      <AutocompleteComponent
                        label="Assessor"
                        placeholder="Select assessor"
                        onSelect={(id) => setValue('assessor', id)}
                        data={users}
                        value={watch('assessor')}
                        initialValue={currentAssignedAssessorNameEmail}
                      />
                    </View>
                    <View style={styles.row}>
                      <Button
                        status="primary"
                        size="large"
                        style={styles.scheduledButton}
                        onPress={() => onSubmitAssessorWrapped()}
                        accessoryLeft={() => (
                          <Icon
                            name="checkmark-outline"
                            width={24}
                            height={24}
                            fill={theme['text-white']}
                          />
                        )}
                      >
                        <Text>Save</Text>
                      </Button>
                    </View>
                  </>
                ) : (
                  (assessor || assessmentScheduleData.migratedAssessor) && (
                    <View style={styles.row}>
                      <View style={styles.label}>
                        <Text style={styles.labelText}>Assessor</Text>
                      </View>
                      <View style={styles.data}>
                        <Text style={styles.dataText}>
                          {assessmentAssessorFullName}
                        </Text>
                      </View>
                    </View>
                  )
                )}

                {assessmentScheduleData.assignedAssessor ===
                  authStore.user?.id && (
                  <View style={styles.row}>
                    <Button
                      status="success"
                      size="large"
                      appearance="outline"
                      style={styles.scheduledButton}
                      onPress={() => {
                        onCreate();
                      }}
                      accessoryLeft={() => (
                        <View style={styles.createButtonIcon}>
                          <Icon
                            name="plus-outline"
                            width={24}
                            height={24}
                            fill={theme['text-dark']}
                          />
                        </View>
                      )}
                    >
                      <Text>Create assessment</Text>
                    </Button>
                  </View>
                )}
                {authStore.user?.isSiteManager && (
                  <View style={styles.row}>
                    <Button
                      status="success"
                      size="large"
                      appearance="outline"
                      style={styles.scheduledButton}
                      onPress={() => onSubmitNotApplicable()}
                      accessoryLeft={(props) => (
                        <Icon
                          name="close-outline"
                          width={24}
                          height={24}
                          {...props}
                        />
                      )}
                    >
                      <Text>Mark as N/A</Text>
                    </Button>
                  </View>
                )}
              </>
            ) : (
              <>
                <View style={styles.row}>
                  <View style={styles.label}>
                    <Text style={styles.labelText}>Date Observed</Text>
                  </View>
                  <View style={styles.data}>
                    {isNotLate ? (
                      <Text style={styles.dataText}>
                        {format(
                          parseISO(assessmentDateObserved!),
                          'dd MMM yyyy',
                        )}
                      </Text>
                    ) : (
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.dataText}>
                          {format(
                            parseISO(assessmentDateObserved!),
                            'dd MMM yyyy',
                          )}
                        </Text>
                        <Text style={styles.overdueText}>(overdue)</Text>
                      </View>
                    )}
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.label}>
                    <Text style={styles.labelText}>Assessor</Text>
                  </View>
                  <View style={styles.data}>
                    <Text style={styles.dataText}>
                      {assessmentAssessorFullName}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.label}>
                    <Text style={styles.labelText}>Answers</Text>
                  </View>
                  <View style={[styles.data, { flexDirection: 'row' }]}>
                    <View style={styles.counterBadge}>
                      <Text style={styles.counterText}>
                        {answeredQuestions.length}/{answeredQuestions.length}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.counterBadge,
                        {
                          backgroundColor:
                            theme['button-success-outline-active'],
                        },
                      ]}
                    >
                      <Text style={styles.counterText}>
                        Yes ({yesAnswerCounter})
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.counterBadge,
                        { backgroundColor: theme['badge-red'] },
                      ]}
                    >
                      <Text style={styles.counterText}>
                        No ({noAnswerCounter})
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.counterBadge,
                        { backgroundColor: theme['badge-blue'] },
                      ]}
                    >
                      <Text style={styles.counterText}>
                        NA ({naAnswerCounter})
                      </Text>
                    </View>
                  </View>
                </View>
                <Button
                  style={styles.completedButton}
                  status="basic"
                  size="medium"
                  appearance="filled"
                  onPress={() =>
                    navigation.navigate('View Assessments', {
                      assessmentId: modalAssessmentId,
                      cameFrom: 'Schedule',
                    })
                  }
                  accessoryLeft={(props) => (
                    <Icon
                      name="external-link-outline"
                      width={24}
                      height={24}
                      {...props}
                    />
                  )}
                >
                  View assessment
                </Button>
              </>
            )}
          </>
        )}
      </Animated.View>
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    createButtonIcon: {
      width: 24,
      height: 24,
      alignItems: 'center',
      justifyContent: 'center',
    },
    pressable: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    card: {
      paddingVertical: 32,
      paddingHorizontal: 58,
      width: '90%',
      maxWidth: 679,
      borderRadius: 4,
      backgroundColor: '#FFFFFF',
    },
    title: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 18,
    },
    row: {
      flexDirection: 'row',
      marginTop: 26,
    },
    label: {
      flexGrow: 1,
      justifyContent: 'center',
    },
    labelText: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 14,
      color: 'text-grey',
    },
    data: {
      justifyContent: 'center',
      marginLeft: 30,
      width: 400,
    },
    dataText: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
    },
    scheduledButton: {
      flex: 1,
    },
    completedButton: {
      marginTop: 26,
      backgroundColor: 'text-white',
      borderColor: 'text-light',
    },
    overdueText: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'badge-red',
      marginLeft: 5,
    },
    counterBadge: {
      height: 32,
      minWidth: 88,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'badge-grey',
      borderRadius: 16,
      paddingTop: 7,
      paddingBottom: 8,
      marginRight: 13,
    },
    counterText: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'normal',
      fontSize: 16,
      color: 'text-white',
    },
    loading: {
      width: '100%',
      height: 461,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

export default observer(DrumbeatModal);
