import config from '../../config';
import { callApiWithToken } from './base';

export const fetchAssessmentForms = async (token: string) =>
  callApiWithToken(`${config.urls.assessmentForms}`, token, 'GET');

export const fetchAssessmentFormDetails = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.assessmentForms}${id}/details/`,
    token,
    'GET',
  );

export const fetchAssessmentFormVersionDetails = async (
  token: string,
  historyId: number,
) =>
  callApiWithToken(
    `${config.urls.assessmentForms}/version-details/?history_id=${historyId}`,
    token,
    'GET',
  );

export const fetchFormSections = async (token: string) =>
  callApiWithToken(`${config.urls.formSections}`, token, 'GET');

export const fetchQuestions = async (token: string) =>
  callApiWithToken(`${config.urls.questions}`, token, 'GET');
