import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Dimensions, Linking, View } from 'react-native';

import { AppStackNavigation } from '..';
import DashboardIcon from '../../../assets/images/dashboard_black_24dp.svg';
import AssessmentsIcon from '../../../assets/images/fact_check_black_24dp.svg';
import ElearningIcon from '../../../assets/images/help_center_black_24dp.svg';
import PowerBi from '../../../assets/images/query_stats.svg';
import ActionItemsIcon from '../../../assets/images/track_changes_black_24dp.svg';
import config from '../../config';
import Actions from '../../containers/Actions';
import Assessments from '../../containers/Assessments';
import Dashboard from '../../containers/Dashboard';
import { useMediaQueries } from '../../hooks/useMediaQueries';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { useStore } from '../../stores';

type Props = object;

const CustomDrawerContent = (props: any) => {
  const theme = useTheme();

  return (
    <>
      <DrawerContentScrollView {...props}>
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
      {config.pbiUrl && (
        <DrawerItem
          label={() => (
            <View>
              <PowerBi />
            </View>
          )}
          onPress={async () => {
            if (config.pbiUrl) {
              const canOpen = await Linking.canOpenURL(config.pbiUrl);
              if (canOpen) {
                Linking.openURL(config.pbiUrl);
              }
            }
          }}
          activeBackgroundColor={theme['green-07']}
        />
      )}
      <DrawerItem
        label={() => (
          <View>
            <ElearningIcon />
          </View>
        )}
        style={
          {
            // width: '100%',
          }
        }
        // onPress={() => props.navigation.navigate('E-learning')}
        onPress={async () => {
          if (config.manualUrl) {
            const canOpen = await Linking.canOpenURL(config.manualUrl);
            if (canOpen) {
              Linking.openURL(config.manualUrl);
            }
          }
        }}
        focused={
          props.state.index ===
          props.state.routes.findIndex((e: any) => e.name === 'E-learning')
        }
        activeBackgroundColor={theme['green-07']}
      />
    </>
  );
};

const Drawer = createDrawerNavigator();

const MainDrawer: React.FC<Props> = () => {
  const { authStore, siteStore, performanceUnitStore, filters, setFilters } =
    useStore();
  const navigation = useNavigation<AppStackNavigation>();
  const theme = useTheme();
  const styles = useResponsiveStyleSheet(themedStyles);

  const [isLoading, setIsLoading] = useState(true);

  const { isDesktop, isTablet } = useMediaQueries();
  const windowHeight = Dimensions.get('window').height;

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const checkUserDetails = async () => {
    // Pings backend and logs out if token is invalid
    await authStore.checkSelf();

    // Shouldn't happen
    const user = authStore.user;
    if (!user) {
      authStore.logOut();
      setIsLoading(false);
      return;
    }

    // Get user's Performance Unit
    const userPerformanceUnit = user.performanceUnit
      ? performanceUnitStore.getPerformanceUnit(user.performanceUnit)
      : undefined;

    // Get user's active Sites that also belong to the user's Performance Unit
    const userActiveSitesInPu = userPerformanceUnit
      ? siteStore
          .getSites()
          .filter(
            (site) =>
              user.sites.includes(site.id) &&
              site.performanceUnit === userPerformanceUnit.id &&
              site.isActive,
          )
      : [];

    // Navigate to Welcome Modal when user has no PU or has an inactive PU
    // Also navigates if all of their sites belonging to their PU is inactive or none
    if (
      !userPerformanceUnit ||
      (userPerformanceUnit && !userPerformanceUnit.isActive) ||
      !userActiveSitesInPu.length
    ) {
      setFilters({ ...filters, performanceUnit: '', site: '' });
      navigation.navigate('Welcome to eVerify');
    }

    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      checkUserDetails();
    }, 2000);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  if (isLoading) {
    return (
      <View style={styles.loading}>
        <ActivityIndicator size="large" color={theme['bp-green']} />
      </View>
    );
  }

  return (
    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      initialRouteName="Dashboard"
      screenOptions={{
        drawerType: isDesktop || isTablet ? 'permanent' : 'front',
        drawerStyle: {
          width: 64,
          paddingTop: windowHeight / 4,
        },
        drawerActiveBackgroundColor: theme['green-07'],
        drawerItemStyle: {
          // width: '100%',
          // borderTopRightRadius: 8,
          // borderBottomRightRadius: 8,
        },
        headerShown: false,
      }}
    >
      <Drawer.Screen
        name="Dashboard"
        component={Dashboard}
        options={{
          // title: 'Dashboard',
          drawerIcon: () => (
            <View>
              <DashboardIcon />
            </View>
          ),
        }}
      />
      <Drawer.Screen
        name="Assessments"
        component={Assessments}
        options={{
          // title: 'Assessments',
          drawerIcon: () => (
            <View>
              <AssessmentsIcon />
            </View>
          ),
        }}
      />
      <Drawer.Screen
        name="Actions"
        component={Actions}
        options={{
          // title: 'Action Tracker',
          drawerIcon: () => (
            <View>
              <ActionItemsIcon />
            </View>
          ),
        }}
      />
    </Drawer.Navigator>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

export default observer(MainDrawer);
