import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('bpEwells/Attachment')
export default class Attachment extends Model({
  id: prop<number>(),
  attachment: prop<string>(''), // file url hosted on server
  attachmentName: prop<string>(''),

  // foreign key id
  answer: prop<number>(),
}) {
  @modelAction
  update(data: ModelCreationData<Attachment>) {
    Object.assign(this, data);
  }
}
