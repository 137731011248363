import { useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { View } from 'react-native';
import { VictoryLegend, VictoryPie } from 'victory-native';

import { GapStatistics } from '../../types';

type Props = {
  gaps: GapStatistics;
};

const ActionGapsGraph: React.FC<Props> = ({ gaps }) => {
  const theme = useTheme();
  const { createdCounter, requiredCounter, noActionCounter } = gaps;

  const DATA = [
    {
      type: 'created',
      count: createdCounter,
      label: createdCounter.toString(),
      fill: theme['bp-green'],
    },
    {
      type: 'required',
      count: requiredCounter,
      label: requiredCounter.toString(),
      fill: theme['green-07'],
    },
    {
      type: 'noAction',
      count: noActionCounter,
      label: noActionCounter.toString(),
      fill: theme['badge-blue'],
    },
  ];

  const legendData = [
    {
      name: 'ACTION CREATED',
      symbol: {
        fill: theme['bp-green'],
        type: 'minus',
      },
    },
    {
      name: 'ACTION REQUIRED',
      symbol: {
        fill: theme['green-07'],
        type: 'minus',
      },
    },
    {
      name: 'NO ACTION REQUIRED',
      symbol: {
        fill: theme['badge-blue'],
        type: 'minus',
      },
    },
  ];

  const chartStyles = {
    text: {
      fontSize: 12,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fill: theme['text-dark'],
    },
    label: {
      fontSize: 32,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      letterSpacing: 5,
    },
  };

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <View style={{ width: '60%' }}>
        <VictoryPie
          y="count"
          data={DATA}
          innerRadius={80}
          labelRadius={(props: any) => {
            return props.innerRadius + 34;
          }}
          labelPlacement="vertical"
          padding={{ top: 15, bottom: 20, left: -10, right: 0 }}
          style={{
            data: {
              fill: ({ datum }) => datum.fill,
            },
            labels: {
              fill: (props: any) => {
                if (props.datum.count === 0) {
                  return 'transparent';
                } else {
                  return props.datum.type === 'created'
                    ? theme['text-white']
                    : props.datum.type === 'required'
                    ? theme['bp-green']
                    : theme['blue-02'];
                }
              },
              ...chartStyles.label,
            },
          }}
        />
      </View>
      <VictoryLegend
        orientation="vertical"
        data={legendData}
        rowGutter={4}
        x={0}
        y={50}
        width={180}
        symbolSpacer={12}
        height={200}
        padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
        style={{
          labels: {
            ...chartStyles.text,
            letterSpacing: 1.8,
            fontSize: 10,
          },
          data: { fontSize: 7 },
        }}
      />
    </View>
  );
};

export default observer(ActionGapsGraph);
