import { RouteProp, useNavigation } from '@react-navigation/native';
import { useCardAnimation } from '@react-navigation/stack';
import { Button, Icon, Text, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Animated, Pressable, View } from 'react-native';

import AutocompleteComponent from '../../components/Common/AutocompleteComponent';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { AppStackNavigation, AppStackParamList } from '../../navigation';
import { useStore } from '../../stores';

type Props = { route: RouteProp<AppStackParamList, 'Welcome to eVerify'> };

type FormData = {
  performanceUnit: string;
  site: string;
};

const WelcomeModal: React.FC<Props> = (props) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation<AppStackNavigation>();
  const { current } = useCardAnimation();

  const store = useStore();
  const {
    authStore,
    performanceUnitStore,
    siteStore,
    userStore,
    setFilters,
    filters,
  } = store;
  const { register, setValue, handleSubmit, watch } = useForm<FormData>();
  const [loading, setLoading] = useState(false);

  const user = authStore.user!;

  const userPerformanceUnit = useMemo(() => {
    // Get user's Performance Unit
    const userPerformanceUnit = user.performanceUnit
      ? performanceUnitStore.getPerformanceUnit(user.performanceUnit)
      : undefined;

    // Return Performance Unit only if it is active
    return userPerformanceUnit && userPerformanceUnit.isActive
      ? performanceUnitStore
          .getPerformanceUnit(user.performanceUnit)
          ?.id.toString()
      : '';
  }, [performanceUnitStore.performanceUnits.values(), authStore.user]);

  const performanceUnitId = Number(watch('performanceUnit'));
  const siteId = Number(watch('site'));

  // Values for Performance unit dropdown UI
  const performanceUnits = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    performanceUnitStore
      .getActivePerformanceUnits()
      .forEach((performanceUnit) => {
        values.push({
          id: performanceUnit.id.toString(),
          text: performanceUnit.name,
        });
      });
    return values;
  }, [performanceUnitStore.performanceUnits.values()]);

  // Values for Site dropdown UI
  const sites = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    siteStore.getActiveSites(performanceUnitId).forEach((site) => {
      values.push({
        id: site.id.toString(),
        text: site.name,
      });
    });
    return values;
  }, [siteStore.sites.values(), watch('performanceUnit')]);

  // Register fields and initial values on component load
  useEffect(() => {
    register('performanceUnit', { required: true });
    register('site', { required: true });
  }, [register]);

  useEffect(() => {
    setValue('performanceUnit', userPerformanceUnit || '');
  }, []);

  useEffect(() => {
    setValue('site', '');
  }, [performanceUnitId]);

  // Submit functions
  const onSubmitUserDetails = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const result = await userStore.setPerformanceUnitSite(
      Number(user.id),
      performanceUnitId,
      siteId,
    );

    if (result.ok) {
      setFilters({
        ...filters,
        site: siteId.toString(),
        performanceUnit: performanceUnitId.toString(),
      });
      navigation.navigate('Main', {
        screen: 'Dashboard',
      });
    }

    setLoading(false);
  }, [loading, performanceUnitId, siteId]);

  const onSubmitUserDetailsWrapped = handleSubmit(onSubmitUserDetails);

  return (
    <View style={styles.container}>
      <Pressable style={styles.pressable} />
      <Animated.View
        style={[
          styles.card,
          {
            transform: [
              {
                scale: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.9, 1],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}
      >
        <View style={styles.textBox}>
          <Text style={[styles.text, { fontSize: 24 }]}>
            Welcome to eVerify!{'\n\n'}
          </Text>
          <Text style={[styles.text, { color: theme['text-grey'] }]}>
            Set your details to get started.
          </Text>
        </View>
        <View style={styles.autocompleteContainer}>
          <AutocompleteComponent
            label="Performance unit"
            placeholder="Select performance unit"
            onSelect={(id) => setValue('performanceUnit', id)}
            data={performanceUnits}
            value={watch('performanceUnit')}
          />
          <AutocompleteComponent
            label="Site"
            placeholder="Select site"
            onSelect={(id) => setValue('site', id)}
            data={sites}
            value={watch('site')}
            disabled={!watch('performanceUnit', '')}
          />
        </View>
        <View style={styles.confirmButton}>
          <Button
            status="primary"
            size="large"
            appearance="filled"
            style={{ width: 200 }}
            disabled={!watch('performanceUnit', '') || !watch('site', '')}
            accessoryLeft={() => (
              <Icon
                name="checkmark-outline"
                width={24}
                height={24}
                fill={theme['text-white']}
              />
            )}
            onPress={onSubmitUserDetailsWrapped}
          >
            Confirm
          </Button>
        </View>
      </Animated.View>
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    pressable: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    card: {
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingVertical: 30,
      paddingHorizontal: 30,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      height: 400,
    },
    textBox: {
      textAlign: 'center',
    },
    text: {
      fontSize: 16,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
    },
    autocompleteContainer: {
      marginTop: 16,
      alignSelf: 'stretch',
    },
    confirmButton: {
      flexDirection: 'row',
      marginTop: 40,
    },
  },
});

export default observer(WelcomeModal);
