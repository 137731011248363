export default {
  AppStack: {
    screens: {
      Main: {
        path: '',
        screens: {
          Dashboard: '',
          Assessments: 'assessments',
          Actions: 'actions',
        },
      },
      // 'Add Assessments': 'add-assessments',
      // 'Edit Assessments': 'edit-assessments',
      // 'View Assessments': 'view-assessments',
      'Create Action': 'create-action',
      // 'Welcome to eVerify': 'welcome-to-everify',
    },
  },
  AuthStack: {
    screens: {
      'Sign In': 'sign-in',
      'Auth Callback': 'auth-callback',
    },
  },
};
