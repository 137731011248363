import { debounce } from 'lodash';
import { onSnapshot, registerRootStore } from 'mobx-keystone';
import React from 'react';

import { StorageService } from '../services/storage/storageService';
import ActionStore from './ActionStore';
import AssessmentFormStore from './AssessmentFormStore';
import AssessmentScheduleStore from './AssessmentScheduleStore';
import AssessmentStore from './AssessmentStore';
import AuthStore from './AuthStore';
import CategoryStore from './CategoryStore';
import HistoricalAssessmentFormStore from './HistoricalAssessmentFormStore';
import PerformanceUnitStore from './PerformanceUnitStore';
import SelfVerificationTypeStore from './SelfVerificationTypeStore';
import SiteStore from './SiteStore';
import SiteTypeStore from './SiteTypeStore';
import Store from './Store';
import UserMetricsStore from './UserMetricsStore';
import UserStore from './UserStore';

const StoreContext = React.createContext<Store>({} as Store);

const useStore = () => React.useContext(StoreContext);
const { Provider: StoreProvider } = StoreContext;

const createStore = (storageService: StorageService) => {
  const authStore = new AuthStore({});
  const userStore = new UserStore({});
  const actionStore = new ActionStore({});
  const assessmentFormStore = new AssessmentFormStore({});
  const historicalAssessmentFormStore = new HistoricalAssessmentFormStore({});
  const assessmentStore = new AssessmentStore({});
  const categoryStore = new CategoryStore({});
  const assessmentScheduleStore = new AssessmentScheduleStore({});
  const performanceUnitStore = new PerformanceUnitStore({});
  const selfVerificationTypeStore = new SelfVerificationTypeStore({});
  const siteStore = new SiteStore({});
  const siteTypeStore = new SiteTypeStore({});
  const userMetricsStore = new UserMetricsStore({
    osName: '',
    osVersion: '',
    deviceName: '',
    deviceType: '',
    modelName: '',
  });

  const store = new Store({
    authStore,
    userStore,
    actionStore,
    assessmentFormStore,
    historicalAssessmentFormStore,
    assessmentStore,
    categoryStore,
    assessmentScheduleStore,
    performanceUnitStore,
    selfVerificationTypeStore,
    siteStore,
    siteTypeStore,
    userMetricsStore,
  });
  authStore.storageService = storageService;
  store.storageService = storageService;

  registerRootStore(store);
  onSnapshot(
    store,
    debounce(() => {
      store.save();
    }, 3000),
  );

  return store;
};

export { Store, StoreProvider, createStore, useStore };
