import config from '../../config';
import { callApiWithToken } from './base';

export const fetchUsers = async (token: string) =>
  callApiWithToken(`${config.urls.users}`, token, 'GET');

export const setPerformanceUnitSite = async (
  token: string,
  user: number,
  performanceUnit: number,
  site: number,
) => {
  return callApiWithToken(
    `${config.urls.users}${user}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ performance_unit: performanceUnit, sites: [site] }),
  );
};

export const checkSelf = async (token: string) =>
  callApiWithToken(`${config.urls.users}self/`, token, 'GET');
