import { Icon, Text, useTheme } from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Animated, Easing, View, TouchableOpacity } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import Assessment from '../../models/Assessment';
import { useStore } from '../../stores';
import { getAssessmentIdFormat } from '../../utils/helper';

type Props = {
  questionCounter: number;
  draftAssessment: Assessment;
  onGoBack?: () => void;
};

const EditAssessmentFormHeader: React.FC<Props> = ({
  draftAssessment,
  questionCounter,
  onGoBack,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const {
    performanceUnitStore,
    selfVerificationTypeStore,
    assessmentFormStore,
    siteStore,
    categoryStore,
    userStore,
    isInternetReachable,
  } = store;
  const [showDetails, setShowDetails] = useState(false);
  const { getValues } = useFormContext();

  const site = useMemo(() => {
    const id = draftAssessment?.site || Number(getValues('site'));
    return siteStore.getSite(id);
  }, [useWatch({ name: 'site' }), draftAssessment, siteStore.sites.values()]);

  const performanceUnit = useMemo(() => {
    const id =
      draftAssessment?.performanceUnit || Number(getValues('performanceUnit'));
    return performanceUnitStore.getPerformanceUnit(id);
  }, [
    useWatch({ name: 'performanceUnit' }),
    draftAssessment,
    performanceUnitStore.performanceUnits.values(),
  ]);

  const assessor = useMemo(() => {
    const id = draftAssessment?.assessor || Number(getValues('assessor'));
    return userStore.getUser(id);
  }, [
    useWatch({ name: 'assessor' }),
    draftAssessment,
    userStore.users.values(),
  ]);

  const assessmentForm = useMemo(() => {
    const id =
      draftAssessment?.assessmentForm || Number(getValues('assessmentForm'));
    return assessmentFormStore.getAssessmentForm(id);
  }, [
    useWatch({ name: 'assessmentForm' }),
    draftAssessment,
    assessmentFormStore.assessmentForms.values(),
  ]);

  const category = useMemo(() => {
    const id = draftAssessment?.category || Number(getValues('category'));
    return categoryStore.getCategory(id);
  }, [
    useWatch({ name: 'category' }),
    draftAssessment,
    categoryStore.categories.values(),
  ]);

  const selfVerificationType = useMemo(() => {
    const id =
      draftAssessment?.selfVerificationType ||
      Number(getValues('selfVerificationType'));
    return selfVerificationTypeStore.getSelfVerificationType(id);
  }, [
    useWatch({ name: 'selfVerificationType' }),
    draftAssessment,
    selfVerificationTypeStore.selfVerificationTypes.values(),
  ]);

  const dateObserved =
    draftAssessment && parseISO(draftAssessment?.dateObserved);
  const assessmentId = draftAssessment?.id;

  const spinValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(spinValue, {
      duration: 200,
      easing: Easing.linear,
      toValue: showDetails ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [showDetails]);

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-180deg'],
  });

  const answerKeys = [];
  for (let i = 0; i < questionCounter; i++) {
    answerKeys.push(`answers.${i}.answer`);
  }
  const answerArray = useWatch({ name: answerKeys });

  const answeredQuestions = useMemo(() => {
    return answerArray.filter((a) => !(a === '' || a === undefined)).length;
  }, [answerArray]);

  return (
    <>
      <>
        <TouchableOpacity
          onPress={() => setShowDetails((previous) => !previous)}
          activeOpacity={1}
        >
          <View style={styles.header}>
            <View style={styles.headerTitle}>
              <Text style={[styles.title, { paddingRight: 46 }]}>
                {assessmentId ? (
                  <Text style={[styles.title, { color: theme['text-grey'] }]}>
                    AS-{getAssessmentIdFormat(assessmentId.toString())}{' '}
                  </Text>
                ) : (
                  <></>
                )}
                {assessmentForm?.name || ''}
              </Text>

              <Animated.View
                style={{
                  height: 32,
                  width: 32,
                  transform: [{ rotate: spin }],
                }}
              >
                <Icon
                  name="chevron-down-outline"
                  width={32}
                  height={32}
                  fill={theme['text-dark']}
                />
              </Animated.View>
            </View>
            <View style={styles.headerSubTitle}>
              <Text style={[styles.title, { fontSize: 16 }]}>
                {assessmentForm && assessmentForm.version
                  ? `Version ${
                      assessmentForm.version
                    }  |  Last updated: ${format(
                      parseISO(assessmentForm.modified),
                      'dd MMMM yyyy',
                    )}`
                  : assessmentForm && assessmentForm.modified
                  ? `Last updated: ${format(
                      parseISO(assessmentForm.modified),
                      'dd MMMM yyyy',
                    )}`
                  : ''}
              </Text>
              <View style={styles.counterBadge}>
                <Text
                  style={[
                    styles.title,
                    {
                      fontSize: 16,
                      fontWeight: 'normal',
                      color: theme['text-white'],
                    },
                  ]}
                >
                  {answeredQuestions}/{questionCounter}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
        {showDetails && (
          <>
            <View style={styles.detailsContainer}>
              <View style={styles.detailsLeft}>
                <View style={styles.infoRow}>
                  <Text style={styles.infoTitle}>Assessment Id</Text>
                  {assessmentId && (
                    <Text style={styles.infoContent}>
                      AS-{getAssessmentIdFormat(assessmentId.toString())}
                    </Text>
                  )}
                </View>
                <View style={styles.infoRow}>
                  <Text style={styles.infoTitle}>Performance Unit</Text>
                  {performanceUnit && (
                    <Text style={styles.infoContent}>
                      {performanceUnit.name}
                    </Text>
                  )}
                </View>
                <View style={styles.infoRow}>
                  <Text style={styles.infoTitle}>Site</Text>
                  {site && <Text style={styles.infoContent}>{site.name}</Text>}
                </View>
                <View style={styles.infoRow}>
                  <Text style={styles.infoTitle}>Category</Text>
                  {category && (
                    <Text style={styles.infoContent}>{category.name}</Text>
                  )}
                </View>
              </View>
              <View style={styles.detailsRight}>
                <View style={styles.infoRow}>
                  <Text style={styles.infoTitle}>Assessment Form</Text>
                  {assessmentForm && (
                    <Text style={styles.infoContent}>
                      {assessmentForm.name}
                    </Text>
                  )}
                </View>
                <View style={styles.infoRow}>
                  <Text style={styles.infoTitle}>Date Observed</Text>
                  {dateObserved && (
                    <Text style={styles.infoContent}>
                      {format(dateObserved, 'dd MMMM yyyy h:MM:ssaaa')}
                    </Text>
                  )}
                </View>
                <View style={styles.infoRow}>
                  <Text style={styles.infoTitle}>Observer</Text>
                  {assessor && (
                    <Text style={styles.infoContent}>{assessor.fullName}</Text>
                  )}
                </View>
                <View style={styles.infoRow}>
                  <Text style={styles.infoTitle}>Self-verification Type</Text>
                  {selfVerificationType && (
                    <Text style={styles.infoContent}>
                      {selfVerificationType.name}
                    </Text>
                  )}
                </View>
              </View>
            </View>
            {onGoBack && isInternetReachable && (
              <TouchableOpacity style={styles.editButton} onPress={onGoBack}>
                <Text style={styles.edit}>Edit details</Text>
              </TouchableOpacity>
            )}
          </>
        )}
      </>
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    header: {
      paddingTop: 24,
      paddingBottom: 17,
      minHeight: 69,
    },
    headerTitle: {
      flexDirection: 'row',
      minWidth: 769,
    },
    title: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 24,
      color: 'text-dark',
    },
    headerSubTitle: {
      flexDirection: 'row',
      paddingTop: 5,
      alignItems: 'center',
    },
    counterBadge: {
      height: 32,
      minWidth: 88,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'badge-grey',
      borderRadius: 16,
      paddingTop: 7,
      paddingBottom: 8,
      marginLeft: 13,
    },
    detailsContainer: {
      marginTop: 20,
      flexDirection: 'row',
    },
    detailsLeft: {
      flexDirection: 'column',
      minWidth: 522,
    },
    detailsRight: {
      flexDirection: 'column',
      minWidth: 686,
    },
    infoRow: {
      flexDirection: 'row',
      alignItems: 'baseline',
      paddingBottom: 24,
    },
    infoTitle: {
      minWidth: 182,
      fontFamily: 'UniversLTPro_Regular',
      fontSize: 14,
      color: 'text-grey',
    },
    infoContent: {
      minWidth: 223,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    editButton: {
      marginTop: 8,
      marginBottom: 24,
    },
    edit: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'bp-green',
    },
    separator: {
      height: 1,
      backgroundColor: 'input-field-outline',
    },
  },
});

export default observer(EditAssessmentFormHeader);
