import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import AssessmentForm from '../models/AssessmentForm';
import FormSection from '../models/FormSection';
import Question from '../models/Question';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('bpEwells/AssessmentFormStore')
export default class AssessmentFormStore extends Model({
  assessmentForms: prop(() => objectMap<AssessmentForm>()),
  formSections: prop(() => objectMap<FormSection>()),
  questions: prop(() => objectMap<Question>()),
}) {
  @observable
  loading = false;

  getAssessmentForm = (id: number): AssessmentForm | undefined => {
    return this.assessmentForms.get(`${id}`);
  };

  getAssessmentForms = (): AssessmentForm[] => {
    return Array.from(this.assessmentForms.values());
  };

  getFormSection = (id: number): FormSection | undefined => {
    return this.formSections.get(`${id}`);
  };

  getFormSections = (): FormSection[] => {
    return Array.from(this.formSections.values());
  };

  getQuestion = (id: number): Question | undefined => {
    return this.questions.get(`${id}`);
  };

  getQuestions = (): Question[] => {
    return Array.from(this.questions.values());
  };

  getActiveAssessmentForms = (
    selfVerificationTypeId?: number,
    categoryId?: number,
  ): AssessmentForm[] => {
    if (selfVerificationTypeId && categoryId) {
      return Array.from(this.assessmentForms.values()).filter(
        (s) =>
          s.isActive &&
          s.selfVerificationType === selfVerificationTypeId &&
          s.category === categoryId,
      );
    } else if (selfVerificationTypeId) {
      return Array.from(this.assessmentForms.values()).filter(
        (s) => s.isActive && s.selfVerificationType === selfVerificationTypeId,
      );
    } else if (categoryId) {
      return Array.from(this.assessmentForms.values()).filter(
        (s) => s.isActive && s.category === categoryId,
      );
    }
    return Array.from(this.assessmentForms.values()).filter((s) => s.isActive);
  };

  @modelAction
  createOrUpdateAssessmentForm(data: ModelCreationData<AssessmentForm>) {
    const id = `${data.id}`;

    let assessmentForm: AssessmentForm;
    if (this.assessmentForms.has(id)) {
      assessmentForm = this.assessmentForms.get(id)!;
    } else {
      assessmentForm = new AssessmentForm(data);
      this.assessmentForms.set(id, assessmentForm);
    }

    assessmentForm.update(data);
  }

  @modelAction
  createOrUpdateFormSection(data: ModelCreationData<FormSection>) {
    const id = `${data.id}`;

    let formSection: FormSection;
    if (this.formSections.has(id)) {
      formSection = this.formSections.get(id)!;
    } else {
      formSection = new FormSection(data);
      this.formSections.set(id, formSection);
    }

    formSection.update(data);
  }

  @modelAction
  createOrUpdateQuestion(data: ModelCreationData<Question>) {
    const id = `${data.id}`;

    let question: Question;
    if (this.questions.has(id)) {
      question = this.questions.get(id)!;
    } else {
      question = new Question(data);
      this.questions.set(id, question);
    }

    question.update(data);
  }

  @modelFlow
  fetchAssessmentForms = _async(function* (this: AssessmentFormStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<AssessmentForm>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchAssessmentForms(rootStore.authStore.accessToken),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching assessment forms', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateAssessmentForm(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchAssessmentFormDetails = _async(function* (
    this: AssessmentFormStore,
    id: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let assessmentForm: ModelCreationData<AssessmentForm>;
    let questions: ModelCreationData<Question>[];
    let formSections: ModelCreationData<FormSection>[];

    try {
      ({
        response: {
          entities: { assessmentForm, questions, formSections },
        },
      } = yield* _await(
        api.fetchAssessmentFormDetails(rootStore.authStore.accessToken, id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching assessment form details', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.createOrUpdateAssessmentForm(assessmentForm);
    questions.forEach((data) => this.createOrUpdateQuestion(data));
    formSections.forEach((data) => this.createOrUpdateFormSection(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchFormSections = _async(function* (this: AssessmentFormStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<FormSection>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchFormSections(rootStore.authStore.accessToken),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching form sections', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateFormSection(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchQuestions = _async(function* (this: AssessmentFormStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Question>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchQuestions(rootStore.authStore.accessToken)));
    } catch (error) {
      console.warn('[DEBUG] error fetching questions', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateQuestion(data));

    this.loading = false;
    return getSuccess();
  });
}
