import { RouteProp, useNavigation } from '@react-navigation/native';
import { useTheme, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, Linking } from 'react-native';

import EverifyLogo from '../../../assets/images/everify_logo_gradient.svg';
import WarningIcon from '../../../assets/images/warning_icon.svg';
import MainContainer from '../../components/Common/MainContainer';
import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import {
  AuthStackNavigation,
  AuthStackParamList,
} from '../../navigation/stacks/AuthStack';
import { useStore } from '../../stores';
import { getErrors } from '../../utils/errors';

type Props = { route: RouteProp<AuthStackParamList, 'Auth Callback'> };

const AuthCallback: React.FC<Props> = (props) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const navigation = useNavigation<AuthStackNavigation>();
  const { authStore, setIsSignInComplete } = useStore();
  const { code } = props?.route.params ?? {};
  const theme = useTheme();

  const [errorMessage, setErrorMessage] = useState('');
  const [isUserNotFound, setIsUserNotFound] = useState(false);

  useEffect(() => {
    (async () => {
      if (code) {
        setErrorMessage('');

        const result = await authStore.signIn(code);

        if (result && !result.ok) {
          setIsUserNotFound(false);

          if (result.errors.error === 'invalid_grant') {
            navigation.navigate('Sign In');
          } else if (
            result.errors.detail === 'User cannot be found in the system'
          ) {
            setIsUserNotFound(true);
          } else {
            setErrorMessage(`Error: ${getErrors(result.errors)}`);
          }
        } else {
          setIsSignInComplete(true);
        }
      }
    })();
  }, [code]);

  return (
    <MainContainer style={styles.container}>
      {isUserNotFound ? (
        config.env === 'production' ? (
          <View style={styles.card}>
            <EverifyLogo height={40} />
            <Text style={[styles.text, { marginTop: 25 }]}>
              You do not have access to eVerify yet.
            </Text>
            <Text style={[styles.text, { marginTop: 5 }]}>
              Please contact eVerify Admin to request permission.
            </Text>
          </View>
        ) : (
          <>
            <EverifyLogo height={80} />
            <Text style={[styles.text, { fontSize: 20, paddingBottom: 20 }]}>
              is now live!
            </Text>
            <Text style={styles.text}>
              Please refrain from using {config.env} site and access the
              production url at
            </Text>
            <Text
              style={[styles.text, { color: 'blue', marginTop: 5 }]}
              onPress={() => Linking.openURL('https://everify.bpglobal.com')}
            >
              https://everify.bpglobal.com
            </Text>
          </>
        )
      ) : errorMessage !== '' ? (
        <View style={styles.card}>
          <WarningIcon />
          <Text style={[styles.text, { marginTop: 25 }]}>
            Something went wrong.
          </Text>
          <Text style={[styles.text, { marginTop: 5 }]}>
            Please contact eVerify Support Team for assistance.
          </Text>
          <View style={styles.errorCard}>
            <Text style={[styles.errorText, { marginBottom: 5 }]}>Error:</Text>
            <Text style={styles.errorText}>{errorMessage}</Text>
          </View>
        </View>
      ) : (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={theme['bp-green']} />
        </View>
      )}
    </MainContainer>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    errorText: {
      fontFamily: 'UniversLTPro_Regular',
      fontSize: 14,
      color: 'text-dark',
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: 540,
      minHeight: 240,
      borderRadius: 4,
      paddingVertical: 40,
      paddingHorizontal: 20,
    },
    errorCard: {
      flex: 1,
      marginTop: 20,
      maxWidth: 540,
      backgroundColor: '#FFFFFF',
      paddingVertical: 20,
      paddingHorizontal: 20,
    },
  },
});

export default observer(AuthCallback);
