import { useTheme, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { ActionStatistics } from '../../types';

type Props = {
  actions: ActionStatistics;
};

const ActionTrackingGraph: React.FC<Props> = ({ actions }) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const [width, setWidth] = useState<number>(0);
  const { actionsPastDueCounter, closedActionsCounter, openActionsCounter } =
    actions;

  const normalize = (digits: number = 12) => {
    const fontSize = (width * 1.2) / digits;
    const maxSize = (width * 1.2) / 3;
    return Math.min(fontSize, maxSize);
  };

  const responsiveFontSize = { fontSize: normalize() };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 14,
        marginTop: 12,
      }}
    >
      <View
        style={[
          styles.actionTrackingCard,
          {
            borderWidth: 1,
            borderColor: theme['bp-green'],
            borderStyle: 'dashed',
          },
        ]}
        onLayout={(e: LayoutChangeEvent) =>
          setWidth(e.nativeEvent.layout.width)
        }
      >
        <View style={styles.actionTrackingContainer}>
          <Text
            style={[
              styles.actionTrackingNumber,
              { fontSize: normalize(openActionsCounter.toString().length) },
            ]}
            numberOfLines={1}
          >
            {openActionsCounter}
          </Text>
        </View>
        <View>
          <Text style={[styles.actionTrackingText, responsiveFontSize]}>
            OPEN
          </Text>
          <Text style={[styles.actionTrackingText, responsiveFontSize]}>
            ACTIONS
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.actionTrackingCard,
          { backgroundColor: theme['button-danger-background-active'] },
        ]}
      >
        <View style={styles.actionTrackingContainer}>
          <Text
            style={[
              styles.actionTrackingNumber,
              { fontSize: normalize(actionsPastDueCounter.toString().length) },
            ]}
          >
            {actionsPastDueCounter}
          </Text>
        </View>
        <View>
          <Text style={[styles.actionTrackingText, responsiveFontSize]}>
            ACTIONS
          </Text>
          <Text style={[styles.actionTrackingText, responsiveFontSize]}>
            PAST DUE
          </Text>
        </View>
      </View>
      <View style={[styles.actionTrackingCard, { backgroundColor: '#E7F5E6' }]}>
        <View style={styles.actionTrackingContainer}>
          <Text
            style={[
              styles.actionTrackingNumber,
              { fontSize: normalize(closedActionsCounter.toString().length) },
            ]}
          >
            {closedActionsCounter}
          </Text>
        </View>
        <View>
          <Text style={[styles.actionTrackingText, responsiveFontSize]}>
            CLOSED
          </Text>
          <Text style={[styles.actionTrackingText, responsiveFontSize]}>
            ACTIONS
          </Text>
        </View>
      </View>
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    actionTrackingCard: {
      flex: 130 / 428,
      alignItems: 'center',
      padding: 5,
      marginBottom: 30,
      borderRadius: 16,
      backgroundColor: 'text-white',
    },
    actionTrackingContainer: {
      flex: 1,
      width: '100%',
      justifyContent: 'center',
    },
    actionTrackingNumber: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    oneTwoDigits: {
      fontSize: 80,
    },
    threeFourDigits: {
      fontSize: 50,
    },
    fiveSixDigits: {
      fontSize: 40,
    },
    actionTrackingText: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 14,
      letterSpacing: 2.1,
      textAlign: 'center',
    },
  },
});
export default observer(ActionTrackingGraph);
