import { Text } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { View } from 'react-native';
import Tooltip from 'react-native-walkthrough-tooltip';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

type Props = {
  showTip: boolean;
  setShowTip: (data: boolean) => void;
  contentText?: string;
  placement?: 'center' | 'top' | 'bottom' | 'left' | 'right' | undefined;
  childContentSpacing?: number;
  children?: any;
  multiline?: boolean;
};

const CustomTooltip: React.FC<Props> = ({
  showTip,
  setShowTip,
  contentText,
  placement,
  childContentSpacing,
  children,
  multiline,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);

  return (
    <Tooltip
      isVisible={showTip}
      content={
        <View>
          <Text style={{ color: '#FFFFFF' }}>{contentText}</Text>
        </View>
      }
      onClose={() => setShowTip(false)}
      placement={placement}
      backgroundColor="rgba(0,0,0,0)"
      disableShadow
      arrowStyle={styles.arrow}
      contentStyle={[styles.content, multiline && styles.multiline]}
      childContentSpacing={childContentSpacing}
    >
      {children}
    </Tooltip>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    arrow: {
      borderTopColor: 'rgba(79, 195, 247, 1)',
    },
    content: {
      backgroundColor: 'rgba(79, 195, 247, 1)',
    },
    multiline: {
      maxWidth: 300,
      minHeight: 60,
    },
  },
});

export default observer(CustomTooltip);
