import {
  EventArg,
  RouteProp,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import { useTheme } from '@ui-kitten/components';
import { parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ActivityIndicator, View } from 'react-native';

import CustomModal from '../../components/Common/CustomModal';
import MainContainer from '../../components/Common/MainContainer';
import UnsavedChangesPrompt from '../../components/Common/UnsavedChangesPrompt';
import EditAssessmentFormFill from '../../components/EditAssessments/EditAssessmentFormFill';
import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { AppStackNavigation, AppStackParamList } from '../../navigation';
import { useStore } from '../../stores';
import { AssessmentFormData, StoredFormData } from '../../types';

type Props = { route: RouteProp<AppStackParamList, 'Edit Assessments'> };

const EditAssessments: React.FC<Props> = (props) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const navigation = useNavigation<AppStackNavigation>();
  const theme = useTheme();
  const store = useStore();
  const {
    isInternetReachable,
    storageService,
    assessmentStore,
    setIsFromAssessment,
    setCurrentlyEditingAssessment,
  } = store;

  const [loading, setLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isBackPromptVisible, setIsBackPromptVisible] = useState(false);
  const [initialLocalFormData, setInitialLocalFormData] =
    useState<AssessmentFormData | null>();

  const { draftAssessmentId, cameFrom } = props?.route?.params ?? {};

  const methods = useForm();
  const { reset } = methods;
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setIsFromAssessment(true);
      setCurrentlyEditingAssessment(draftAssessmentId);
    }
  }, [isFocused, draftAssessmentId]);

  useEffect(() => {
    (async () => {
      // Get the database modified datetime of assessment from api (or from store if offline)
      let onlineModifiedDateString = '';
      if (isInternetReachable) {
        const result = await assessmentStore.fetchAssessmentDetails(
          draftAssessmentId,
        );
        if (result.ok) {
          onlineModifiedDateString = result.extra!.modified;
        }
      } else {
        const assessment = assessmentStore.getAssessment(draftAssessmentId);
        onlineModifiedDateString = assessment?.modified || '';
      }

      // Get local modified datetime of assessment from localforage
      const storedFormData = await storageService.getItemAsync(
        `${config.formsKeyPrefix}${draftAssessmentId}`,
      );
      if (storedFormData && onlineModifiedDateString) {
        const { form, modified } = JSON.parse(storedFormData) as StoredFormData;
        const offlineModifiedDate = parseISO(modified);
        const onlineModifiedDate = parseISO(onlineModifiedDateString);

        // If local modified datetime exists, compare it with database modified datetime
        if (offlineModifiedDate > onlineModifiedDate) {
          reset(form);
          setInitialLocalFormData(form);
        }
      }

      setLoading(false);
    })();
  }, [draftAssessmentId, reset]);

  const exitEvent = useRef<
    EventArg<
      'beforeRemove',
      true,
      {
        action: Readonly<{
          type: string;
          payload?: object | undefined;
          source?: string | undefined;
          target?: string | undefined;
        }>;
      }
    >
  >();

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      if (isChanged) {
        e.preventDefault();
        exitEvent.current = e;
        setIsBackPromptVisible(true);
      } else {
        setCurrentlyEditingAssessment(-1);
      }
    });
    return unsubscribe;
  }, [navigation, isChanged]);

  return (
    <MainContainer style={styles.container} paddingTop={0}>
      <FormProvider {...methods}>
        {loading ? (
          <View style={styles.loading}>
            <ActivityIndicator size="large" color={theme['bp-green']} />
          </View>
        ) : (
          <EditAssessmentFormFill
            draftAssessmentId={draftAssessmentId}
            cameFrom={cameFrom}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            initialLocalFormData={initialLocalFormData}
          />
        )}
        <CustomModal
          visible={isBackPromptVisible}
          onBackdropPress={() => setIsBackPromptVisible(false)}
        >
          <View>
            <UnsavedChangesPrompt
              onDiscard={async () => {
                if (exitEvent.current) {
                  setCurrentlyEditingAssessment(-1);
                  navigation.dispatch(exitEvent.current.data.action);
                }
              }}
              onContinue={() => setIsBackPromptVisible(false)}
            />
          </View>
        </CustomModal>
      </FormProvider>
    </MainContainer>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      paddingRight: 0,
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

export default observer(EditAssessments);
