import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('bpEwells/AssessmentSchedule')
export default class AssessmentSchedule extends Model({
  id: prop<number>(),
  dateScheduled: prop<string>(''),
  isApplicable: prop<boolean>(),
  scheduleType: prop<string>(''),
  duration: prop<number>(),
  migratedAssessor: prop<string>(),

  // foreign key id
  site: prop<number>(),
  assignedAssessor: prop<number>(),
  drumbeatSchedule: prop<number>(),
  assessmentForm: prop<number>(),
  assessment: prop<number>(),
}) {
  @modelAction
  update(data: ModelCreationData<AssessmentSchedule>) {
    Object.assign(this, data);
  }
}
