import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('bpEwells/FormSection')
export default class FormSection extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  isActive: prop<boolean>(),
  order: prop<number>(),
  versionId: prop<number>(),

  // foreign key id
  assessmentForm: prop<number>(),
  assessmentFormVersionId: prop<string>(),
}) {
  @modelAction
  update(data: ModelCreationData<FormSection>) {
    Object.assign(this, data);
  }
}
