import { Layout } from '@ui-kitten/components';
import React, { forwardRef, Ref } from 'react';
import {
  StyleProp,
  ViewStyle,
  ScrollView,
  View,
  ViewProps,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

// import { useMediaQueries } from '../../hooks/useMediaQueries';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import ConditionalWrapper from './ConditionalWrapper';

type Props = ViewProps & {
  scrollEnabled?: boolean;
  scrollStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  isDrawerScreen?: boolean;
  paddingTop?: number;
  paddingHorizontal?: number;
};

const ContainerView = forwardRef<ScrollView, Props>(
  (containerProps: Props, ref?: Ref<ScrollView>) => {
    const {
      scrollEnabled = false,
      scrollStyle,
      children,
      isDrawerScreen = false,
      style,
      paddingTop = 16,
      paddingHorizontal = 55,
      ...props
    } = containerProps;

    const styles = useResponsiveStyleSheet(responsiveStyles);
    // const { isDesktop, isTablet } = useMediaQueries();
    const insets = useSafeAreaInsets();

    return (
      <Layout style={styles.container}>
        <ConditionalWrapper
          condition={scrollEnabled}
          wrapper={(children: any) => (
            <ScrollView
              ref={ref}
              style={styles.container}
              contentContainerStyle={[
                styles.scroll,
                { paddingBottom: insets.bottom + 100 },
                scrollStyle,
              ]}
              keyboardShouldPersistTaps="handled"
            >
              {children}
            </ScrollView>
          )}
        >
          <View
            {...props}
            style={[
              style,
              styles.innerContainer,
              { paddingHorizontal, paddingTop },
              isDrawerScreen && { paddingHorizontal: 26 },
            ]}
          >
            {children}
          </View>
        </ConditionalWrapper>
      </Layout>
    );
  },
);

const responsiveStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
    },
    scroll: {
      flexGrow: 1,
    },
    innerContainer: {
      alignSelf: 'center',
      width: '100%',
    },
  },
  mobileStyle: {
    innerContainer: {
      paddingHorizontal: 16,
    },
  },
});

export default ContainerView;
