import {
  Autocomplete,
  AutocompleteItem,
  Icon,
  Text,
} from '@ui-kitten/components';
import React, { useCallback, useEffect, useRef } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useActive, useHover } from 'react-native-web-hooks';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { getValueById } from '../../utils/helper';

type Data = {
  id: string;
  text: string;
};

type Props = {
  label?: string;
  data: { id: string; text: string }[];
  onSelect: (index: any) => void;
  size?: string;
  status?: string;
  placeholder?: string;
  value: string | undefined;
  initialValue?: string;
  disabled?: boolean;
  marginTop?: number;
  hideCloseIcon?: boolean;
  labelHalf?: boolean;
};

const AutocompleteComponent: React.FC<Props> = ({
  label,
  data,
  onSelect,
  size = 'large',
  status = 'basic',
  placeholder,
  value,
  initialValue,
  disabled = false,
  marginTop = 16,
  hideCloseIcon,
  labelHalf,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);

  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isActive = useActive(ref);

  const [choices, setChoices] = React.useState<Data[]>(data);
  const [choiceText, setChoiceText] = React.useState<string>('');
  const [showIcon, setShowIcon] = React.useState<boolean>(false);

  const onChangeText = (query: string): void => {
    const visibleChoices = data.filter((item) => {
      return item.text.toLowerCase().includes(query.toLowerCase());
    });

    setChoiceText(query);
    setChoices(visibleChoices);
    setShowIcon(true);
  };

  const clearInput = () => {
    setChoiceText('');
    onSelect('');
    setChoices(data);
    setShowIcon(false);
  };

  const onSelectItem = (index: number) => {
    setChoiceText(choices[index].text);
    onSelect(choices[index].id);
  };

  useEffect(() => {
    setChoices(data);
  }, [data]);

  useEffect(() => {
    if (value === '' || value === null) {
      setChoiceText('');
      setChoices(data);
      setShowIcon(false);
    }
  }, [value]);

  useEffect(() => {
    if (choiceText) {
      setShowIcon(true);
    }
  }, [choiceText]);

  useEffect(() => {
    if (initialValue && value !== '') {
      setChoiceText(initialValue);
    }
    setChoices(choices.sort((a, b) => a.text.localeCompare(b.text)));
  }, []);

  useEffect(() => {
    if (value) {
      const valueText = getValueById(choices, value);
      setChoiceText(valueText);
    }
  }, [value]);

  const renderOption = useCallback(
    (item: any, index: number) => (
      <AutocompleteItem key={item.id} title={item.text} />
    ),
    [],
  );

  const renderCloseIcon = useCallback(
    (props: any) =>
      showIcon && !hideCloseIcon && !disabled ? (
        <TouchableOpacity
          onPress={() => {
            clearInput();
          }}
        >
          <Icon name="close" {...props} />
        </TouchableOpacity>
      ) : (
        <></>
      ),
    [showIcon],
  );

  return (
    <View style={[styles.container, { marginTop: 5 + marginTop }]} ref={ref}>
      {label && (
        <View style={styles.label}>
          {labelHalf && <View style={styles.labelHalf} />}
          <Text
            style={[
              styles.labelText,
              isActive && !disabled
                ? styles.active
                : isHovered && !disabled
                ? styles.hover
                : styles.inactive,
            ]}
          >
            {label}
          </Text>
        </View>
      )}

      <View
        style={[
          styles.selectContainer,
          isActive && !disabled
            ? styles.active
            : isHovered && !disabled
            ? styles.hover
            : styles.inactive,
        ]}
      >
        <Autocomplete
          disabled={disabled}
          status={status}
          size={size}
          placeholder={placeholder}
          value={choiceText}
          accessoryRight={renderCloseIcon}
          onSelect={(index) => onSelectItem(index)}
          onChangeText={onChangeText}
          style={styles.select}
        >
          {choices.map(renderOption)}
        </Autocomplete>
      </View>
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      position: 'relative',
    },
    label: {
      flex: 1,
      position: 'absolute',
      backgroundColor: 'input-field-background',
      left: 7,
      top: -8,
      paddingHorizontal: 5,
      paddingVertical: 0,
      margin: 0,
      zIndex: 1,
    },
    labelHalf: {
      height: '50%',
      backgroundColor: 'input-field-background-focused',
      width: '100%',
      position: 'absolute',
      left: 0,
    },
    active: {
      color: 'input-field-outline-active',
      borderColor: 'input-field-outline-active',
    },
    hover: {
      color: 'input-field-outline-hover',
      borderColor: 'input-field-outline-hover',
    },
    inactive: {
      color: 'text-grey',
      borderColor: 'input-field-outline',
    },
    labelText: {
      fontSize: 14,
      fontFamily: 'UniversLTPro_Regular',
      color: 'input-field-outline',
      zIndex: 2,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    select: {
      alignSelf: 'stretch',
      overflow: 'hidden',
    },
    selectContainer: {
      alignSelf: 'stretch',
      borderRadius: 3,
      borderWidth: 1,
      backgroundColor: 'input-field-background',
      color: 'text-grey',
      borderColor: 'input-field-outline',
    },
  },
});

export default AutocompleteComponent;
