import { computed } from 'mobx';
import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('bpEwells/User')
export default class User extends Model({
  id: prop<number>(),
  firstName: prop<string>(''),
  lastName: prop<string>(''),
  email: prop<string>(''),
  photoSizes: prop<string>(''),
  isSiteManager: prop<boolean>(false),
  isActive: prop<boolean>(true),

  // foreign key ids
  performanceUnit: prop<number>(),
  sites: prop<number[]>(),
}) {
  @computed
  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  @modelAction
  update(data: ModelCreationData<User>) {
    Object.assign(this, data);
  }
}
