import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { AssessmentFormFrequency } from '../constants/AssessmentForm';

@model('bpEwells/AssessmentForm')
export default class AssessmentForm extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  isActive: prop<boolean>(),
  description: prop<string>(''),
  frequency: prop<AssessmentFormFrequency>(),
  created: prop<string>(),
  modified: prop<string>(),
  version: prop<number>(),
  versionId: prop<number>(),

  // foreign key id
  category: prop<number>(),
  selfVerificationType: prop<number>(),
}) {
  @modelAction
  update(data: ModelCreationData<AssessmentForm>) {
    Object.assign(this, data);
  }
}
