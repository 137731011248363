export default {
  // https://akveo.github.io/react-native-ui-kitten/docs/design-system/customize-mapping
  strict: {},
  components: {
    Layout: {
      meta: {},
      appearances: {
        default: {
          mapping: {},
          variantGroups: {
            level: {
              '1': {
                backgroundColor: 'grey-100',
              },
            },
          },
        },
      },
    },
    Button: {
      meta: {},
      appearances: {
        filled: {
          mapping: {
            textFontFamily: 'UniversBP_Light',
            textFontWeight: 'regular',
          },
          variantGroups: {
            status: {
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'bp-green',
                textColor: 'text-white',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'bp-green',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'green-05',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'bp-green',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'gray-04',
                    iconTintColor: 'text-white',
                    textColor: 'text-white',
                  },
                },
              },
              basic: {
                borderColor: 'transparent',
                backgroundColor: 'grey-300',
                textColor: 'text-dark',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'grey-300',
                  },
                  active: {
                    borderColor: 'transparent',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'grey-300',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'grey-300',
                  },
                },
              },
              success: {
                borderColor: 'transparent',
                backgroundColor: 'green-02',
                textColor: 'text-white',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'green-02',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'green-02',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'green-02',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'green-02',
                    iconTintColor: 'green-03',
                    textColor: 'green-03',
                  },
                },
              },
              danger: {
                borderColor: 'transparent',
                backgroundColor: 'badge-red',
                textColor: 'text-white',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'badge-red',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'badge-red-2',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'badge-red-2',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'button-danger-outline-active',
                    iconTintColor: 'text-white',
                    textColor: 'text-white',
                  },
                },
              },
            },
            size: {
              small: {
                borderRadius: 2,
              },
              medium: {
                borderRadius: 2,
                paddingVertical: 9,
                textFontSize: 14,
                minHeight: 36,
                iconMarginHorizontal: 0,
              },
              large: {
                borderRadius: 4,
                paddingVertical: 16.5,
                textFontSize: 16,
                minHeight: 52,
              },
            },
          },
        },
        outline: {
          mapping: {
            textFontFamily: 'UniversBP_Light',
            textFontWeight: 'regular',
          },
          variantGroups: {
            status: {
              primary: {
                borderColor: 'gray-01',
                backgroundColor: 'input-field-background',
                textColor: 'text-dark',
                state: {
                  focused: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                  active: {
                    borderColor: 'text-dark',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                  hover: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                  disabled: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-grey',
                  },
                },
              },
              basic: {},
              success: {
                borderColor: 'gray-01',
                backgroundColor: 'input-field-background',
                textColor: 'text-dark',
                textFontFamily: 'UniversBP_Regular',
                textFontWeight: 'normal',
                iconTintColor: 'text-dark',
                state: {
                  focused: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    iconTintColor: 'text-dark',
                    textColor: 'text-dark',
                  },
                  active: {
                    borderColor: 'button-success-outline-active',
                    backgroundColor: 'input-field-background',
                    iconTintColor: 'text-dark',
                    textColor: 'text-dark',
                  },
                  hover: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    iconTintColor: 'text-dark',
                    textColor: 'text-dark',
                  },
                  disabled: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    iconTintColor: 'input-field-outline',
                    textColor: 'text-dark',
                  },
                },
              },
              danger: {
                borderColor: 'gray-01',
                backgroundColor: 'input-field-background',
                textColor: 'text-dark',
                textFontFamily: 'UniversBP_Regular',
                textFontWeight: 'normal',
                state: {
                  focused: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                  active: {
                    borderColor: 'button-danger-outline-active',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                  hover: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                  disabled: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                },
              },
              info: {
                borderColor: 'gray-01',
                backgroundColor: 'input-field-background',
                textColor: 'text-dark',
                textFontFamily: 'UniversBP_Regular',
                textFontWeight: 'normal',
                state: {
                  focused: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                  active: {
                    borderColor: 'button-info-outline-active',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                  hover: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                  disabled: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-dark',
                  },
                },
              },
            },
            size: {
              small: {
                borderRadius: 2,
                textFontSize: 14,
                paddingVertical: 9,
              },
              medium: {
                borderRadius: 4,
                borderWidth: 1,
                textFontSize: 14,
                paddingVertical: 12,
                minHeight: 40,
              },
              large: {
                borderRadius: 2,
                textFontSize: 16,
                minHeight: 52,
              },
            },
          },
        },
      },
    },
    Spinner: {
      meta: {},
      appearances: {
        default: {
          mapping: {},
          variantGroups: {
            status: {
              success: {
                borderColor: 'bp-green',
              },
              info: {
                borderColor: 'text-white',
              },
              basic: {
                borderColor: 'text-dark',
              },
            },
          },
        },
      },
    },
    Input: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            paddingHorizontal: 0,
            textMarginHorizontal: 0,
            textFontFamily: 'UniversBP_Regular',
          },
          variantGroups: {
            status: {
              primary: {
                borderColor: 'transparent',
                backgroundColor: 'input-field-background',
                textColor: 'text-dark',
                placeholderColor: 'text-grey',
                textFontFamily: 'UniversBP_Regular',
                paddingHorizontal: 0,
                state: {
                  focused: {
                    backgroundColor: 'input-field-background',
                    borderColor: 'transparent',
                  },
                  hover: {
                    backgroundColor: 'input-field-background',
                    borderColor: 'transparent',
                  },
                  disabled: {
                    backgroundColor: 'grey-100',
                    borderColor: 'transparent',
                  },
                },
              },
              basic: {
                borderColor: 'input-field-outline',
                backgroundColor: 'input-field-background',
                iconTintColor: 'text-dark',
                textColor: 'text-dark',
                placeholderColor: 'text-dark',
                textFontFamily: 'UniversBP_Light',
                borderRadius: 3,
                paddingHorizontal: 16,
                paddingVertical: 16,
                iconWidth: 22,
                iconHeight: 22,
                iconMarginHorizontal: 0,
                state: {
                  focused: {
                    borderColor: 'input-field-outline-focused',
                    backgroundColor: 'input-field-background',
                    iconTintColor: 'input-field-outline-focused',
                  },
                  active: {
                    borderColor: 'input-field-outline-active',
                    backgroundColor: 'input-field-background',
                  },
                  hover: {
                    borderColor: 'input-field-outline-hover',
                    backgroundColor: 'input-field-background',
                  },
                  disabled: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'input-field-outline',
                    iconTintColor: 'input-field-outline',
                  },
                },
              },
              info: {
                borderColor: 'input-field-outline',
                backgroundColor: 'input-field-background',
                iconTintColor: 'text-dark',
                textColor: 'text-dark',
                placeholderColor: 'text-grey',
                textFontFamily: 'UniversBP_Light',
                borderRadius: 3,
                paddingHorizontal: 16,
                paddingVertical: 16,
                iconWidth: 22,
                iconHeight: 22,
                iconMarginHorizontal: 0,
                state: {
                  focused: {
                    borderColor: 'input-field-outline-focused',
                    backgroundColor: 'input-field-background',
                    iconTintColor: 'input-field-outline-focused',
                  },
                  active: {
                    borderColor: 'input-field-outline-active',
                    backgroundColor: 'input-field-background',
                  },
                  hover: {
                    borderColor: 'input-field-outline-hover',
                    backgroundColor: 'input-field-background',
                  },
                  disabled: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'input-field-outline',
                    iconTintColor: 'input-field-outline',
                  },
                },
              },
            },
            size: {
              medium: {
                borderRadius: 4,
                borderWidth: 1,
                textFontSize: 14,
                paddingVertical: 0,
              },
              large: {
                minHeight: 50,
                textFontSize: 16,
                textFontWeight: 'bold',
                borderWidth: 0,
              },
            },
          },
        },
      },
    },
    ListItem: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            paddingHorizontal: 16,
            paddingVertical: 16,
            backgroundColor: 'input-field-background',
            titleFontSize: 16,
            titleFontFamily: 'UniversBP_Light',
            titleFontWeight: 'bold',
            titleColor: 'text-dark',
            state: {
              active: {
                backgroundColor: 'input-field-background-focused',
              },
            },
          },
        },
      },
    },
    SelectOption: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            paddingHorizontal: 16,
            paddingVertical: 16,
            backgroundColor: 'input-field-background',
            textFontSize: 16,
            textFontFamily: 'UniversBP_Light',
            textFontWeight: 'bold',
            textColor: 'text-dark',
            state: {
              hover: {
                backgroundColor: 'input-field-background-focused',
              },
              focused: {
                backgroundColor: 'input-field-background',
              },
              active: {
                backgroundColor: 'input-field-background',
              },
              selected: {
                backgroundColor: 'input-field-background-focused',
              },
            },
          },
        },
      },
    },
    Select: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            borderRadius: 3,
            paddingHorizontal: 16,
            paddingVertical: 16,
            textMarginHorizontal: 0,
            iconWidth: 22,
            iconHeight: 22,
            iconMarginHorizontal: 0,
            popoverBorderRadius: 3,
            popoverBorderColor: 'input-field-outline',
          },
          variantGroups: {
            status: {
              basic: {
                borderColor: 'input-field-outline',
                backgroundColor: 'input-field-background',
                iconTintColor: 'text-dark',
                textColor: 'text-dark',
                placeholderColor: 'text-dark',
                textFontFamily: 'UniversBP_Light',
                placeholderFontFamily: 'UniversBP_Light',
                state: {
                  focused: {
                    borderColor: 'input-field-outline-focused',
                    backgroundColor: 'input-field-background',
                  },
                  active: {
                    borderColor: 'input-field-outline-active',
                    backgroundColor: 'input-field-background',
                  },
                  hover: {
                    borderColor: 'input-field-outline-hover',
                    backgroundColor: 'input-field-background',
                  },
                  disabled: {
                    borderColor: 'input-field-outline',
                    backgroundColor: 'input-field-background',
                    textColor: 'input-field-outline',
                    iconTintColor: 'input-field-outline',
                  },
                },
              },
            },
            size: {
              large: {
                minHeight: 50,
                borderRadius: 3,
                borderWidth: 1,
                textFontSize: 16,
                textFontWeight: 'bold',
                placeholderFontSize: 16,
                placeholderFontWeight: 'bold',
              },
            },
          },
        },
      },
    },
    Datepicker: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            paddingHorizontal: 16,
            paddingVertical: 16,
            textMarginHorizontal: 0,
            iconWidth: 22,
            iconHeight: 22,
            iconMarginHorizontal: 0,
          },
          variantGroups: {
            status: {
              primary: {
                iconTintColor: 'text-dark',
                textColor: 'text-dark',
                textFontFamily: 'UniversBP_Light',
                backgroundColor: 'input-field-background',
                state: {
                  focused: {
                    borderColor: 'transparent',
                    backgroundColor: 'input-field-background',
                  },
                  active: {
                    borderColor: 'transparent',
                    backgroundColor: 'input-field-background',
                  },
                  hover: {
                    borderColor: 'transparent',
                    backgroundColor: 'input-field-background',
                  },
                  disabled: {
                    borderColor: 'transparent',
                    backgroundColor: 'input-field-background',
                    textColor: 'text-grey',
                    iconTintColor: 'text-grey',
                  },
                },
              },
            },
            size: {
              large: {
                minHeight: 50,
                textFontSize: 16,
                textFontWeight: 'bold',
                borderWidth: 0,
              },
            },
          },
        },
      },
    },
    Calendar: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            borderRadius: 3,
            borderWidth: 1,
            borderColor: 'input-field-outline',
            titleFontSize: 16,
            titleFontWeight: 'bold',
            titleFontFamily: 'UniversBP_Light',
            titleColor: 'text-dark',
            weekdayTextFontFamily: 'UniversBP_Light',
            weekdayTextFontWeight: 'bold',
            weekdayTextColor: 'text-grey',
            iconTintColor: 'text-dark',
          },
        },
      },
    },
    CalendarCell: {
      meta: {},
      appearances: {
        default: {
          mapping: {
            backgroundColor: 'input-field-background',
            contentBorderRadius: 3,
            contentBorderWidth: 1,
            contentBorderColor: 'transparent',
            contentTextFontSize: 14,
            contentTextFontWeight: 'bold',
            contentTextFontFamily: 'UniversBP_Light',
            contentTextColor: 'text-dark',
            state: {
              bounding: {
                contentTextColor: 'text-grey',
              },
              selected: {
                contentBorderColor: 'transparent',
                contentBackgroundColor: 'input-field-background-focused',
                contentTextColor: 'text-dark',
              },
              disabled: {
                contentTextColor: 'text-grey',
              },
              today: {
                contentBorderColor: 'transparent',
                contentBackgroundColor: 'transparent',
              },
              range: {
                borderRadius: 3,
                backgroundColor: 'input-field-background',
                contentTextColor: 'text-dark',
              },
              'today.range': {
                contentBorderColor: 'transparent',
                contentBackgroundColor: 'transparent',
              },
            },
          },
        },
      },
    },
    Popover: {
      appearances: {
        default: {
          mapping: {
            borderRadius: 3,
            borderWidth: 1,
            borderColor: 'input-field-outline',
            backgroundColor: 'input-field-background',
          },
        },
      },
    },
  },
} as any;
