export enum CatActionStatus {
  CLOSED = 'Closed',
  CLOSED_REQUIRES_VERIFICATION = 'Closed, requires verification',
  CANCELLED = 'Cancelled',
  IN_PROGRESS = 'In progress',
  CHANGE_REQUESTED = 'Change requested',
  DRAFT = 'Draft',
  WAITING_OWNER_ACKNOWLEDGEMENT = 'Waiting owner acknowledgement',
  WAITING_APPROVAL = 'Waiting approval',
}
