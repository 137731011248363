import * as Device from 'expo-device';
import {
  model,
  Model,
  prop,
  _async,
  _await,
  modelFlow,
  modelAction,
} from 'mobx-keystone';

@model('bpEwells/UserMetricsStore')
export default class UserMetricsStore extends Model({
  osName: prop<string>(''),
  osVersion: prop<string>(''),
  deviceName: prop<string>(''),
  deviceType: prop<string>(''),
  modelName: prop<string>(''),
}) {
  @modelFlow
  populateDeviceInfo = _async(function* (this: UserMetricsStore) {
    const type = yield* _await(Device.getDeviceTypeAsync());
    switch (type) {
      case Device.DeviceType.PHONE:
        this.deviceType = 'Mobile';
        break;
      case Device.DeviceType.TABLET:
        this.deviceType = 'Tablet';
        break;
      case Device.DeviceType.DESKTOP:
        this.deviceType = 'Desktop';
        break;
      default:
        this.deviceType = 'Unknown';
    }

    this.osName = Device.osName || '';
    this.osVersion = Device.osVersion || '';
    this.deviceName = Device.deviceName || '';
    this.modelName = Device.modelName || '';
  });

  @modelAction
  getDeviceInfo(): { [key: string]: string } {
    return {
      osName: this.osName,
      osVersion: this.osVersion,
      deviceName: this.deviceName,
      deviceType: this.deviceType,
      modelName: this.modelName,
    };
  }
}
