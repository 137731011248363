import { RouteProp, useIsFocused } from '@react-navigation/native';
import { useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ActivityIndicator, View } from 'react-native';

import MainContainer from '../../components/Common/MainContainer';
import ViewAssessmentFormFill from '../../components/ViewAssessments/ViewAssessmentFormFill';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { AppStackParamList } from '../../navigation';
import { useStore } from '../../stores';

type Props = { route: RouteProp<AppStackParamList, 'View Assessments'> };

const ViewAssessments: React.FC<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const styles = useResponsiveStyleSheet(themedStyles);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { assessmentStore, setIsFromAssessment } = useStore();

  const { assessmentId, cameFrom } = props?.route?.params ?? {};

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setIsFromAssessment(true);
    }
  }, [isFocused]);

  const onGoBack = () => {
    setSelectedIndex(selectedIndex - 1);
  };

  const methods = useForm();

  useEffect(() => {
    (async () => {
      if (loading || (cameFrom && cameFrom === 'Schedule')) {
        return;
      }
      setLoading(true);
      await assessmentStore.fetchAssessmentHistoricalDetails(assessmentId);
      setLoading(false);
    })();
  }, [assessmentId]);

  return (
    <MainContainer style={styles.container} paddingTop={0}>
      <FormProvider {...methods}>
        {loading ? (
          <View style={styles.loading}>
            <ActivityIndicator size="large" color={theme['bp-green']} />
          </View>
        ) : (
          <ViewAssessmentFormFill
            onGoBack={onGoBack}
            assessmentId={assessmentId}
            selectedIndex={selectedIndex}
            cameFrom={cameFrom}
          />
        )}
      </FormProvider>
    </MainContainer>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      paddingRight: 0,
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

export default observer(ViewAssessments);
