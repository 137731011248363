import snakeCaseKeys from 'snakecase-keys';

import config from '../../config';
import { callApi } from './base';

export const signUp = async (data: {
  email: string;
  password: string;
  fullName: string;
}) =>
  callApi(
    config.urls.signUp,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      snakeCaseKeys({
        email: data.email,
        password1: data.password,
        password2: data.password,
        fullName: data.fullName,
      }),
    ),
  );

export const signIn = async (data: {
  code: string;
  osName: string;
  osVersion: string;
  deviceName: string;
  deviceType: string;
  modelName: string;
}) =>
  callApi(
    config.urls.signIn,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const refreshAccessToken = async (data: { refresh: string }) =>
  callApi(
    config.urls.refreshToken,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const signInWithMicrosoft = async (data: { code: string }) =>
  callApi(
    config.urls.signInWithMicrosoft,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const logOut = async (token: string) =>
  callApi(
    config.urls.logOut,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ token })),
  );
