import {
  useStyleSheet,
  StyleService,
  Text,
  Icon,
  useTheme,
} from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  runOnJS,
} from 'react-native-reanimated';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  message: string;
  success?: boolean;
  error?: boolean;
  toastDuration?: number;
};

const Toast: React.FC<Props> = ({
  visible,
  setVisible,
  message,
  success = false,
  error = false,
  toastDuration = 1500,
}) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const [duration, setDuration] = useState(1);

  const opacity = useSharedValue(0);

  useEffect(() => {
    if (visible) {
      opacity.value = withTiming(1, {
        duration: 1000,
      });
    }
  }, [visible]);

  useEffect(() => {
    let interval: any;
    if (visible) {
      if (toastDuration > 0) {
        interval = setInterval(() => {
          if (duration === 0) {
            opacity.value = withTiming(
              0,
              {
                duration: 400,
              },
              (finished?: boolean) => {
                if (finished) {
                  runOnJS(closeToast)();
                }
              },
            );
          } else {
            setDuration(duration - 1);
          }
        }, toastDuration);
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [duration, visible]);

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }));

  const closeToast = () => {
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <Animated.View style={[styles.container, { bottom: 40 }, animatedStyle]}>
      {success && (
        <Icon
          name="checkmark-circle-2"
          width={28}
          height={28}
          fill={theme['bp-green']}
        />
      )}
      {error && (
        <Icon
          name="close-circle"
          width={28}
          height={28}
          fill={theme['badge-red']}
        />
      )}
      <Text style={styles.message}>{message}</Text>
    </Animated.View>
  );
};

const themedStyles = StyleService.create({
  container: {
    position: 'absolute',
    backgroundColor: 'input-field-background',
    borderWidth: 1,
    borderColor: 'input-field-outline',
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  message: {
    marginLeft: 16,
    fontSize: 16,
    fontFamily: 'UniversBP_Light',
    fontWeight: 'bold',
  },
});

export default Toast;
