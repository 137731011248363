export const FontMapping = {
  UniversBP_Light: require('../../assets/fonts/UniversforBP_Light.ttf'),
  UniversBP_Regular: require('../../assets/fonts/UniversforBP_Roman.ttf'),
  UniversBP_Bold: require('../../assets/fonts/UniversforBP_Bold.ttf'),
  UniversLTPro_Light: require('../../assets/fonts/UniversLTPro_Light.otf'),
  UniversLTPro_Regular: require('../../assets/fonts/UniversLTPro_Roman.otf'),
  UniversLTPro_Bold: require('../../assets/fonts/UniversLTPro_Bold.otf'),
};

export default Object.keys(FontMapping).reduce(
  (obj, k) => ({ ...obj, [k]: k }),
  {},
);
