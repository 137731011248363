import localForage from 'localforage';

import { StorageService } from './storageService';

export class NativeStorageService extends StorageService {
  storageInstance = localForage.createInstance({
    driver: localForage.INDEXEDDB,
    name: 'bp-ewells',
  });

  getItemAsync = async (key: string): Promise<string | null> => {
    try {
      const value = await this.storageInstance.getItem(key);
      return value as string | null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  setItemAsync = async (key: string, item: string): Promise<void> => {
    try {
      await this.storageInstance.setItem(key, item);
    } catch (error) {
      console.error(error);
    }
  };

  removeItemAsync = async (key: string): Promise<void> => {
    try {
      await this.storageInstance.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  };

  clearAsync = async (): Promise<void> => {
    try {
      await this.storageInstance.clear();
    } catch (error) {
      console.error(error);
    }
  };

  getAllKeys = async (): Promise<string[] | null> => {
    try {
      return await this.storageInstance.keys();
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  removeAllSets = async (prefix: string): Promise<void> => {
    try {
      const keys = await this.storageInstance.keys();
      const matchingKeys = keys.filter((key: string) => key.startsWith(prefix));
      for (const key of matchingKeys) {
        await this.removeSet(key);
      }
    } catch (error) {
      console.error(error);
    }
  };

  addToSet = async (
    setId: string,
    key: string,
    item: string,
  ): Promise<void> => {
    try {
      // Store the item
      await this.storageInstance.setItem(key, item);
      // Store the key as part of a list of keys
      const set: string[] = (await this.storageInstance.getItem(setId)) || [];
      set.push(key);
      await this.storageInstance.setItem(setId, set);
    } catch (error) {
      console.error(error);
    }
  };

  removeSet = async (setId: string): Promise<void> => {
    try {
      const set = await this.storageInstance.getItem(setId);
      if (!set) {
        return;
      }
      // Get the list of keys and delete the items of the keys
      for (const key of set as string[]) {
        await this.storageInstance.removeItem(key);
      }
      // Delete the list of keys itself
      await this.storageInstance.removeItem(setId);
    } catch (error) {
      console.error(error);
    }
  };
}
