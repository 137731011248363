import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { View, TouchableWithoutFeedback, Modal } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

type Props = {
  visible: boolean;
  onBackdropPress: () => void;
  children?: any;
  seconds?: number;
};

const CustomModal: React.FC<Props> = ({
  children,
  visible,
  onBackdropPress,
  seconds = 0,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);

  useEffect(() => {
    if (seconds > 0 && visible) {
      const timer = setTimeout(() => {
        onBackdropPress();
      }, seconds * 1000);
      return () => clearTimeout(timer);
    }
  }, [seconds, visible]);

  return (
    <Modal visible={visible} animationType="none" transparent>
      <TouchableWithoutFeedback
        onPress={() => {
          onBackdropPress();
        }}
      >
        <View style={styles.backdrop}>
          <TouchableWithoutFeedback>{children}</TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    backdrop: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 0,
    },
  },
});

export default observer(CustomModal);
