import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { ActionTypeChoices } from '../constants/Action';

@model('bpEwells/Action')
export default class Action extends Model({
  id: prop<number>(),
  actionType: prop<ActionTypeChoices>(),
  description: prop<string>(''),
  submitted: prop<boolean>(),
  modified: prop<string>(),
  created: prop<string>(),

  // foreign key ids
  creator: prop<number>(),
  answer: prop<number>(),
}) {
  @modelAction
  update(data: ModelCreationData<Action>) {
    Object.assign(this, data);
  }
}
