import { StyleService, useTheme } from '@ui-kitten/components';
import { merge, cloneDeep } from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useMediaQueries } from './useMediaQueries';

export function createResponsiveStyle<T, U, V extends Partial<T>>({
  baseStyle,
  tabletStyle,
  mobileStyle,
}: {
  baseStyle: StyleSheet.NamedStyles<T>;
  tabletStyle?: StyleSheet.NamedStyles<U>;
  mobileStyle?: StyleSheet.NamedStyles<V>;
}) {
  return { baseStyle, tabletStyle, mobileStyle };
}

/**
 * Takes in an object of three stylesheets (base, tablet, mobile)
 * and returns the appropriate themed stylesheet based on the window
 * dimensions.
 *
 * The tablet and mobile styles merge and overwrite the base style,
 * but not each other (i.e. mobile won't overwrite the tablet style).
 */
export default function useResponsiveStyleSheet<T, U, V extends Partial<T>>({
  baseStyle,
  tabletStyle,
  mobileStyle,
}: {
  baseStyle: StyleSheet.NamedStyles<T>;
  tabletStyle?: StyleSheet.NamedStyles<U>;
  mobileStyle?: StyleSheet.NamedStyles<V>;
}) {
  const { isMobile, isTablet } = useMediaQueries();
  const mergedTabletStyle = merge(cloneDeep(baseStyle), cloneDeep(tabletStyle));
  const mergedMobileStyle = merge(cloneDeep(baseStyle), cloneDeep(mobileStyle));

  const [style, setStyle] = useState<StyleSheet.NamedStyles<T | U | V>>(
    isMobile && mobileStyle
      ? mergedMobileStyle
      : isTablet && tabletStyle
      ? mergedTabletStyle
      : baseStyle,
  );

  const theme = useTheme();

  useEffect(() => {
    let newStyle: StyleSheet.NamedStyles<T | U | V>;

    if (isMobile && mobileStyle) {
      newStyle = mergedMobileStyle;
    } else if (isTablet && tabletStyle) {
      newStyle = mergedTabletStyle;
    } else {
      newStyle = baseStyle;
    }

    setStyle(newStyle);
  }, [isMobile, isTablet]);

  return useMemo(() => {
    return StyleService.createThemed(style, theme) as StyleSheet.NamedStyles<T>;
  }, [theme, style]);
}
