import { HeaderTitle } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import { Button, Icon, Spinner, useTheme } from '@ui-kitten/components';
import { parseISO } from 'date-fns';
import { pick, orderBy, debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldValues, useFormContext, useWatch } from 'react-hook-form';
import {
  View,
  Text,
  TouchableOpacity,
  Linking,
  ScrollView,
} from 'react-native';

import DraftCheckMark from '../../../assets/images/check_circle_draft_outline_24dp.svg';
import CheckMark from '../../../assets/images/check_circle_outline_24dp.svg';
import SaveIcon from '../../../assets/images/save_24dp.svg';
import WarningIcon from '../../../assets/images/warning_icon.svg';
import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import Question from '../../models/Question';
import { AppStackNavigation } from '../../navigation';
import { useStore } from '../../stores';
import {
  AnswerData,
  AnswerForm,
  AssessmentData,
  AssessmentFormData,
  AttachmentForm,
  FileItem,
  StoredFormData,
} from '../../types';
import { getErrors } from '../../utils/errors';
import { chunkArray } from '../../utils/helper';
import CustomLinking from '../../utils/linking';
import CustomModal from '../Common/CustomModal';
import CustomTooltip from '../Common/CustomTooltip';
import SyncIndicator from '../Common/SyncIndicator';
import EditAssessmentFormHeader from './EditAssessmentFormHeader';
import EditQuestionFill from './EditQuestionFill';

type Props = {
  draftAssessmentId: number;
  cameFrom?: string;
  isChanged: boolean;
  setIsChanged: (state: boolean) => void;
  onGoBack?: () => void;
  selectedIndex?: number;
  setIsSubmitted?: (state: boolean) => void;
  initialLocalFormData?: AssessmentFormData | null;
};

const EditAssessmentFormFill: React.FC<Props> = ({
  draftAssessmentId,
  cameFrom,
  isChanged,
  setIsChanged,
  onGoBack,
  selectedIndex = 1,
  setIsSubmitted = (state: boolean) => {},
  initialLocalFormData,
}) => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(true);
  const [saveTrigger, setSaveTrigger] = useState(false);
  const [modalVisibility, setModalVisibility] = useState({
    isVisible: false,
    isDraftVisible: false,
    isOfflineVisible: false,
    isErrorVisible: false,
    isDeleteVisible: false,
    isExportPdfVisible: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showTip, setShowTip] = useState<boolean>(false);
  const [submitCounter, setSubmitCounter] = useState(0);
  const [isAutosaving, setIsAutosaving] = useState(false);

  const isAutosavingRef = useRef(false);
  const isFirstRender = useRef(true);
  const isExport = useRef(false);

  const styles = useResponsiveStyleSheet(themedStyles);
  const store = useStore();
  const {
    isInternetReachable,
    setIsSiteScheduleChanged,
    setIsAssessmentsChanged,
    setIsStatisticsChanged,
    storageService,
    isGlobalAutosaving,
    assessmentStore,
    assessmentFormStore,
  } = store;
  const theme = useTheme();
  const navigation = useNavigation<AppStackNavigation>();

  const isAddAssessments = !!onGoBack;

  const { handleSubmit, register, getValues } = useFormContext();

  const formData = useWatch();

  useEffect(() => {
    register('dateObserved', {
      required: true,
      value:
        initialLocalFormData?.dateObserved ||
        parseISO(draftAssessment.dateObserved),
    });
    register('performanceUnit', {
      required: true,
      value:
        initialLocalFormData?.performanceUnit ||
        draftAssessment.performanceUnit,
    });
    register('selfVerificationType', {
      required: true,
      value:
        initialLocalFormData?.selfVerificationType ||
        draftAssessment.selfVerificationType,
    });
    register('assessmentForm', {
      required: true,
      value:
        initialLocalFormData?.assessmentForm || draftAssessment.assessmentForm,
    });
    register('assessmentFormVersionId', {
      required: true,
      value:
        initialLocalFormData?.assessmentFormVersionId ||
        assessmentForm?.versionId,
    });
    register('site', {
      required: true,
      value: initialLocalFormData?.site || draftAssessment.site,
    });
    register('category', {
      required: true,
      value: initialLocalFormData?.category || draftAssessment.category,
    });
    register('scheduleId', {
      required: false,
      value: initialLocalFormData?.scheduleId || draftAssessment.scheduleId,
    });
    register('assessor', {
      required: true,
      value: initialLocalFormData?.assessor || draftAssessment.assessor,
    });
    register('isActive', { required: true, value: 'true' });
    register('isDraft', { required: true, value: 'true' });
  }, [register]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (saveTrigger) {
      onSubmitWrapped();
      setSaveTrigger(false);
    }
  }, [isDraft, saveTrigger]);

  const storeLocalFormData = async (formData: FieldValues) => {
    const modifiedFormData = {
      modified: new Date().toISOString(),
      form: formData,
    };
    await storageService.setItemAsync(
      `${config.formsKeyPrefix}${draftAssessmentId}`,
      JSON.stringify(modifiedFormData),
    );
    if (!isAddAssessments) {
      setIsChanged(false);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={styles.headerRightContainer}>
          <CustomTooltip
            showTip={showTip}
            contentText="This is your offline indicator. If it is gray, you are
                  offline, and your inputs will be temporarily stored until you
                  are reconnected to the internet"
            setShowTip={setShowTip}
            placement="bottom"
            multiline
          >
            <TouchableOpacity
              onLongPress={() => setShowTip(true)}
              // @ts-ignore
              onMouseEnter={() => setShowTip(true)}
              // @ts-ignore
              onMouseLeave={() => setShowTip(false)}
            >
              <SyncIndicator />
            </TouchableOpacity>
          </CustomTooltip>
          {selectedIndex === 1 && (
            <>
              {isInternetReachable && (
                <>
                  <Button
                    style={{ marginLeft: 16, width: 166 }}
                    status="success"
                    size="medium"
                    appearance="filled"
                    disabled={loading}
                    onPress={() => {
                      setModalVisibility({
                        ...modalVisibility,
                        isExportPdfVisible: true,
                      });
                    }}
                    accessoryLeft={
                      <Icon
                        name="file-text-outline"
                        width={24}
                        height={24}
                        fill={theme['text-white']}
                      />
                    }
                  >
                    Export as PDF
                  </Button>
                  <Button
                    style={{ marginLeft: 16, width: 164 }}
                    status="success"
                    size="medium"
                    appearance="filled"
                    disabled={loading}
                    onPress={() => {
                      setModalVisibility({
                        ...modalVisibility,
                        isDeleteVisible: true,
                      });
                    }}
                    accessoryLeft={(props) => (
                      <Icon
                        name="trash-2-outline"
                        width={24}
                        height={24}
                        {...props}
                      />
                    )}
                  >
                    Delete draft
                  </Button>
                </>
              )}
              <Button
                style={{ marginLeft: 16, width: 169 }}
                status="success"
                size="medium"
                appearance="filled"
                disabled={loading}
                onPress={async () => {
                  if (!isInternetReachable) {
                    await storeLocalFormData(getValues());
                    setModalVisibility({
                      ...modalVisibility,
                      isDraftVisible: true,
                    });
                  } else {
                    setIsDraft(true);
                    setSaveTrigger(true);
                  }
                }}
                accessoryLeft={(props) =>
                  loading && isDraft ? (
                    <Spinner status="info" {...props} />
                  ) : (
                    <SaveIcon {...props} />
                  )
                }
              >
                Save as draft
              </Button>
              {isInternetReachable && (
                <Button
                  style={{ marginLeft: 16, width: 131 }}
                  status="success"
                  size="medium"
                  appearance="filled"
                  disabled={loading}
                  onPress={() => {
                    setIsDraft(false);
                    setSaveTrigger(true);
                  }}
                  accessoryLeft={(props) =>
                    loading && !isDraft ? (
                      <Spinner status="info" {...props} />
                    ) : (
                      <Icon
                        name="checkmark-outline"
                        width={24}
                        height={24}
                        {...props}
                      />
                    )
                  }
                >
                  Submit
                </Button>
              )}
            </>
          )}
        </View>
      ),
      headerTitle: () => {
        const headerText = `Back to ${
          cameFrom === 'Add Assessments' ? 'Dashboard' : cameFrom
        }`;

        const renderHeaderTitle = () => (
          <TouchableOpacity
            onPress={() =>
              cameFrom === 'Add Assessments'
                ? navigation.navigate('Main', { screen: 'Dashboard' })
                : navigation.goBack()
            }
          >
            <HeaderTitle style={styles.headerTitle} tintColor="#ffffff">
              {headerText}
            </HeaderTitle>
          </TouchableOpacity>
        );

        return renderHeaderTitle();
      },
      headerLeft: () => {
        const renderHeaderLeft = () => (
          <TouchableOpacity
            onPress={() =>
              cameFrom === 'Add Assessments'
                ? navigation.navigate('Main', { screen: 'Dashboard' })
                : navigation.goBack()
            }
            style={styles.headerLeftContainer}
          >
            <Icon
              name="arrow-back-outline"
              width={32}
              height={32}
              fill="#ffffff"
            />
          </TouchableOpacity>
        );

        return renderHeaderLeft();
      },
    });
  }, [
    navigation,
    loading,
    isDraft,
    selectedIndex,
    isInternetReachable,
    showTip,
    modalVisibility,
  ]);

  const exportPdf = useCallback(async () => {
    const url = `${config.urls.api}${config.urls.exportPdf}${draftAssessmentId}`;
    const canOpen = await Linking.canOpenURL(url);
    if (canOpen) {
      return CustomLinking.openURL(url, '_blank');
    }
  }, [draftAssessmentId]);

  const draftAssessment = assessmentStore.getAssessment(draftAssessmentId)!;
  const assessmentForm =
    draftAssessment &&
    assessmentFormStore.getAssessmentForm(draftAssessment.assessmentForm);

  const questions = useMemo(() => {
    const id = assessmentStore.getAssessment(draftAssessmentId)?.assessmentForm;

    // Get and sort form sections belonging to the assessment form
    const formSections = orderBy(
      assessmentFormStore
        .getFormSections()
        .filter(
          (formSection) =>
            formSection.assessmentForm === id && formSection.isActive,
        ),
      ['order'],
      ['asc'],
    );

    // Sort questions under each form section
    const questions: Question[] = [];
    formSections.forEach((formSection) => {
      const formSectionQuestions = orderBy(
        assessmentFormStore
          .getQuestions()
          .filter(
            (question) =>
              question.formSection === formSection.id && question.isActive,
          ),
        ['order'],
        ['asc'],
      );
      questions.push(...formSectionQuestions);
    });

    return questions;
  }, [
    assessmentStore.assessments.values(),
    assessmentFormStore.formSections.values(),
    assessmentFormStore.questions.values(),
  ]);

  const scrollViewRef = useRef<ScrollView>(null);
  const [offsets, setOffsets] = useState(new Array(questions.length).fill(0));

  const scrollToQuestion = (questionIndex: number) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        x: 0,
        y: offsets[questionIndex],
        animated: true,
      });
    }
  };

  const onSubmit = useCallback(
    async (
      data: FieldValues,
      isDraft: boolean,
      id: number,
      autosave: boolean = false,
    ) => {
      if (!isInternetReachable) {
        setModalVisibility({
          ...modalVisibility,
          isOfflineVisible: true,
        });
        return;
      }

      if (
        loading ||
        deleteLoading ||
        isAutosavingRef.current ||
        isAutosaving ||
        isGlobalAutosaving
      ) {
        return;
      }

      if (autosave) {
        setIsAutosaving(true);
        isAutosavingRef.current = true;
      } else {
        setLoading(true);
      }

      debouncedAutosave.cancel();

      const assessment: AssessmentData = pick(data, [
        'dateObserved',
        'scheduleId',
        'performanceUnit',
        'selfVerificationType',
        'assessmentForm',
        'assessmentFormVersionId',
        'site',
        'category',
        'assessor',
        'isActive',
        'isDraft',
      ]);
      assessment.isDraft = `${isDraft}`;
      assessment.assessmentFormVersionId = `${assessmentForm?.versionId}`;

      const result = await assessmentStore.updateAssessment(
        assessment,
        id,
        autosave,
      );
      if (result.ok) {
        const assessmentId = result.extra!.id;
        const existingAnswerList: AnswerData[] = [];
        const newAnswerList: AnswerData[] = [];

        data.answers.forEach((answer: any) => {
          if (answer.id) {
            existingAnswerList.push({
              id: answer.id,
              answer: answer.answer,
              description: answer.description,
              question: answer.question,
              questionVersionId: answer.questionVersionId,
              assessment: assessmentId,
            });
          } else {
            newAnswerList.push({
              answer: answer.answer,
              description: answer.description,
              question: answer.question,
              questionVersionId: answer.questionVersionId,
              assessment: assessmentId,
            });
          }
        });

        const existingAnswerListResult = await assessmentStore.updateAnswer(
          existingAnswerList,
        );

        let newAnswerListIds = {};
        if (newAnswerList.length > 0) {
          const newAnswerListResult = await assessmentStore.addAnswer(
            newAnswerList,
          );
          newAnswerListIds = newAnswerListResult.extra!.ids;
        }

        if (existingAnswerListResult.ok) {
          const questionAndAnswerIds = {
            ...existingAnswerListResult.extra!.ids,
            ...newAnswerListIds,
          };
          const fileList: FileItem[] = [];

          data.answers.forEach((answer: AnswerForm) => {
            if (answer.attachments) {
              answer.attachments.forEach((attachment: AttachmentForm) => {
                if (!attachment.isSaved) {
                  fileList.push({
                    attachmentKey: attachment.key!,
                    attachmentName: attachment.name,
                    answer: questionAndAnswerIds[answer.question],
                  });
                }
              });
            }
          });

          if (fileList.length !== 0) {
            const chunks: FileItem[][] = chunkArray(fileList, 5);

            for (let i = 0; i < chunks.length; i++) {
              const fileListChunk = chunks[i];

              const chunkFilePromises = fileListChunk.map((fileItem) =>
                storageService.getItemAsync(fileItem.attachmentKey),
              );
              const chunkFiles = await Promise.all(chunkFilePromises);

              const filteredFileListChunk = fileListChunk.filter(
                (fileItem, index) => {
                  if (chunkFiles[index]) {
                    fileItem.attachment = chunkFiles[index]!;
                    return true;
                  }
                  return false;
                },
              );

              if (filteredFileListChunk.length > 0) {
                await assessmentStore.addAttachments(filteredFileListChunk);
              }
            }
          }

          const attachmentsToDelete: any[] = [];
          data.answers.forEach((answer: AnswerForm) => {
            if (answer.attachments) {
              answer.attachments.forEach((attachment: AttachmentForm) => {
                if (attachment.toDelete) {
                  attachmentsToDelete.push({ id: attachment.id });
                }
              });
            }
          });

          if (attachmentsToDelete.length !== 0) {
            await assessmentStore.deleteAttachments(attachmentsToDelete);
          }

          // Signal question component to fetch new ids
          setSubmitCounter((prev) => prev + 1);
          setIsAssessmentsChanged(true);
          setIsStatisticsChanged(true);

          // Remove stored files for the assessment when successfully submitted
          await storageService.removeSet(
            `${config.filesKeyPrefix}${draftAssessmentId}`,
          );

          // On Edit, no back modal after autosaving
          // On Add, back modal still exists after autosaving
          if (autosave && !isAddAssessments) {
            setIsChanged(false);
          }

          if (!autosave) {
            // Prevent deletion of draft when backing in Add Assessments
            setIsSubmitted(true);
            setIsChanged(false);

            if (isDraft && !isExport.current) {
              setModalVisibility({
                ...modalVisibility,
                isDraftVisible: true,
              });
            } else if (isDraft && isExport.current) {
              setModalVisibility({
                ...modalVisibility,
                isExportPdfVisible: false,
                isDraftVisible: true,
              });
              exportPdf();
            } else {
              // Delete local form data when assessment is submitted (not draft)
              await storageService.removeItemAsync(
                `${config.formsKeyPrefix}${draftAssessmentId}`,
              );
              setModalVisibility({
                ...modalVisibility,
                isVisible: true,
              });
              setIsSiteScheduleChanged(true);
            }
          }
        }
      } else {
        if (!autosave) {
          setErrorMessage(getErrors(result.errors));
          setModalVisibility({
            ...modalVisibility,
            isErrorVisible: true,
          });
        }
      }

      if (autosave) {
        setIsAutosaving(false);
        isAutosavingRef.current = false;
      } else {
        setLoading(false);
      }

      debouncedAutosave.cancel();
    },
    [
      loading,
      deleteLoading,
      isAutosaving,
      draftAssessmentId,
      isDraft,
      isInternetReachable,
      isGlobalAutosaving,
    ],
  );

  const onError = useCallback(
    (errors: any, isDraft: boolean) => {
      if (loading) {
        return;
      }
      setLoading(true);

      if (!isDraft && errors && errors.answers) {
        errors.answers.every((answerError: any, index: number) => {
          if (answerError && scrollViewRef && scrollViewRef.current) {
            scrollToQuestion(index);
            return false;
          }
          return true;
        });
        setIsDraft(true);
      }
      setLoading(false);
    },
    [loading, isDraft],
  );

  const onDelete = useCallback(async () => {
    if (loading || deleteLoading || isAutosaving) {
      return;
    }
    setDeleteLoading(true);

    const result = await assessmentStore.deleteAssessment(draftAssessmentId);

    if (result.ok) {
      await storageService.removeSet(
        `${config.filesKeyPrefix}${draftAssessmentId}`,
      );
      await storageService.removeItemAsync(
        `${config.formsKeyPrefix}${draftAssessmentId}`,
      );
      setIsAssessmentsChanged(true);
      setIsStatisticsChanged(true);
      setIsChanged(false);
      setIsSubmitted(true);
      setModalVisibility({ ...modalVisibility, isDeleteVisible: false });
      navigation.navigate('Main', { screen: 'Dashboard' });
    } else {
      setErrorMessage(getErrors(result.errors));
      setModalVisibility({
        ...modalVisibility,
        isDeleteVisible: false,
        isErrorVisible: true,
      });
    }

    setDeleteLoading(false);
  }, [loading, deleteLoading, isAutosaving, draftAssessmentId]);

  const onSubmitWrapped = handleSubmit(
    (data) => onSubmit(data, isDraft, draftAssessmentId!),
    (errors) => onError(errors, isDraft),
  );

  const debouncedAutosave = useCallback(
    debounce(
      async (formData: FieldValues) => {
        if (
          isInternetReachable &&
          selectedIndex === 1 &&
          Object.keys(formData).length !== 0 &&
          !loading &&
          !deleteLoading &&
          (!isAutosaving || !isAutosavingRef.current) &&
          !isGlobalAutosaving &&
          isDraft
        ) {
          let data = formData;
          let onlineModifiedDateString = '';

          const assessment = assessmentStore.getAssessment(draftAssessmentId);
          onlineModifiedDateString = assessment?.modified || '';

          const storedFormData = await storageService.getItemAsync(
            `${config.formsKeyPrefix}${draftAssessmentId}`,
          );

          if (storedFormData && onlineModifiedDateString) {
            const { form, modified } = JSON.parse(
              storedFormData,
            ) as StoredFormData;
            const offlineModifiedDate = parseISO(modified);
            const onlineModifiedDate = parseISO(onlineModifiedDateString);

            if (offlineModifiedDate > onlineModifiedDate) {
              data = form;
            }
          }

          onSubmit(data, true, draftAssessmentId, true);
        } else if (!isInternetReachable && selectedIndex === 1) {
          storeLocalFormData(formData);
        }
      },
      3000,
      { leading: false, trailing: true },
    ),
    [
      loading,
      deleteLoading,
      isAutosaving,
      draftAssessmentId,
      selectedIndex,
      isDraft,
      isInternetReachable,
      isGlobalAutosaving,
    ],
  );

  const manualAutosave = useCallback(async () => {
    if (!isInternetReachable || isAutosaving || isAutosavingRef.current) {
      return;
    }

    debouncedAutosave.cancel();

    await onSubmit(getValues(), true, draftAssessmentId, true);

    debouncedAutosave.cancel();
  }, [isInternetReachable, isAutosaving, onSubmit]);

  useEffect(() => {
    debouncedAutosave(getValues());
  }, [formData, isInternetReachable]);

  const renderItem = useCallback(
    (item: Question, index: number) => {
      return (
        <View
          key={item.id}
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            offsets[index] = layout.y;
            setOffsets([...offsets]);
          }}
        >
          <EditQuestionFill
            question={item}
            index={index}
            isDraft={isDraft}
            draftAssessmentId={draftAssessmentId}
            setIsChanged={setIsChanged}
            isChanged={isChanged}
            submitCounter={submitCounter}
            populateLocally={!!initialLocalFormData}
            manualAutosave={manualAutosave}
            isAutosaving={isAutosaving}
          />
          {index !== questions.length - 1 && <View style={styles.separator} />}
        </View>
      );
    },
    [isDraft, isChanged, submitCounter, isAutosaving, debouncedAutosave],
  );

  const renderSubmit = () => (
    <View style={styles.card}>
      <CheckMark />
      <Text style={[styles.text, styles.submitTitle]}>Success!</Text>
      <Text style={styles.text}>Assessment has been submittted</Text>
      <Text style={styles.text}>and updated in the system.</Text>
    </View>
  );

  const renderDraftSubmit = () => (
    <View style={styles.draftCard}>
      <DraftCheckMark />
      <View style={[styles.textBox, { marginTop: 20 }]}>
        {isInternetReachable ? (
          <Text style={styles.text}>
            Assessment has been saved as draft.{'\n\n'}
            <Text style={[styles.text, { color: theme['text-grey'] }]}>
              Note: Drafts are editable and can be accessed in the Assessments
              Table.
            </Text>
          </Text>
        ) : (
          <Text style={styles.text}>
            Assessment has been saved
            <Text style={{ fontFamily: 'UniversBP_Regular' }}> locally</Text>.
            {'\n\n'}
            <Text style={[styles.text, { color: theme['text-grey'] }]}>
              Please connect to the internet to sync your offline data and
              ensure data safety.
            </Text>
          </Text>
        )}
      </View>
      <View style={styles.backButtons}>
        <Button
          status="primary"
          size="large"
          appearance="filled"
          style={{ marginRight: 20, width: 230 }}
          accessoryLeft={(props) => (
            <Icon
              name="arrow-back-outline"
              width={20}
              height={20}
              fill={theme['text-white']}
            />
          )}
          onPress={() => {
            if (isInternetReachable) {
              navigation.navigate('Main', { screen: 'Dashboard' });
            } else {
              navigation.navigate('Main', { screen: 'Assessments' });
            }
          }}
        >
          {isInternetReachable
            ? 'Return to Dashboard'
            : 'Return to Assessments'}
        </Button>
        <Button
          status="primary"
          size="large"
          appearance="outline"
          style={{ width: 230 }}
          accessoryLeft={(props) => (
            <Icon
              name="edit-outline"
              width={24}
              height={24}
              fill={theme['text-dark']}
            />
          )}
          onPress={() =>
            setModalVisibility({
              ...modalVisibility,
              isDraftVisible: false,
            })
          }
        >
          Continue Editing
        </Button>
      </View>
    </View>
  );

  const renderOfflinePrompt = (isDraft: boolean) => (
    <View style={styles.warningCard}>
      <WarningIcon />
      <Text style={[styles.text, { marginBottom: 15, marginTop: 10 }]}>
        You are offline
      </Text>
      <Text style={styles.text}>Assessment cannot be saved. Please</Text>
      <Text style={styles.text}>
        connect to the internet to {isDraft ? 'save as draft.' : 'submit.'}
      </Text>
    </View>
  );

  const renderErrorMessage = () => (
    <View style={[styles.warningCard]}>
      <WarningIcon />
      <Text style={[styles.text, { marginTop: 20 }]}>An error occurred.</Text>
      <View style={{ marginTop: 15, flex: 1, marginHorizontal: 10 }}>
        <Text style={styles.text}>{errorMessage}</Text>
      </View>
    </View>
  );

  const renderDeletePrompt = () => (
    <View style={styles.backCard}>
      <WarningIcon />
      <View style={styles.textBox}>
        <Text style={[styles.text, { marginTop: 15 }]}>
          Are you sure you want to delete this item?{'\n\n'}This action cannot
          be undone.
        </Text>
      </View>
      <View style={styles.backButtons}>
        <Button
          status="danger"
          size="large"
          appearance="filled"
          disabled={deleteLoading}
          style={{ marginRight: 20, width: 200 }}
          accessoryLeft={(props) =>
            deleteLoading ? (
              <Spinner status="info" {...props} />
            ) : (
              <Icon
                name="trash-outline"
                width={20}
                height={20}
                fill={theme['text-white']}
              />
            )
          }
          onPress={() => onDelete()}
        >
          Delete
        </Button>
        <Button
          status="primary"
          size="large"
          appearance="outline"
          style={{ width: 200 }}
          disabled={deleteLoading}
          accessoryLeft={(props) => (
            <Icon
              name="close-outline"
              width={24}
              height={24}
              fill={theme['text-dark']}
            />
          )}
          onPress={() =>
            setModalVisibility({
              ...modalVisibility,
              isDeleteVisible: false,
            })
          }
        >
          Cancel
        </Button>
      </View>
    </View>
  );

  const renderExportPdfPrompt = () => (
    <View style={styles.backCard}>
      <WarningIcon />
      <View style={styles.textBox}>
        <Text style={[styles.text, { marginTop: 15 }]}>
          Exporting to PDF will save the assessment as a draft before exporting.
          {'\n\n'}
          Do you want to save as draft?
        </Text>
      </View>
      <View style={styles.backButtons}>
        <Button
          status="primary"
          size="large"
          appearance="filled"
          disabled={loading}
          style={{ marginRight: 20, width: 200 }}
          accessoryLeft={(props) =>
            loading && isDraft ? (
              <Spinner status="info" {...props} />
            ) : (
              <SaveIcon {...props} />
            )
          }
          onPress={() => {
            isExport.current = true;
            setIsDraft(true);
            setSaveTrigger(true);
          }}
        >
          Save as Draft
        </Button>
        <Button
          status="primary"
          size="large"
          appearance="outline"
          disabled={loading}
          style={{ width: 200 }}
          accessoryLeft={() => (
            <Icon
              name="edit-outline"
              width={24}
              height={24}
              fill={theme['text-dark']}
            />
          )}
          onPress={() =>
            setModalVisibility({
              ...modalVisibility,
              isExportPdfVisible: false,
            })
          }
        >
          Continue Editing
        </Button>
      </View>
    </View>
  );

  const renderHeader = () => (
    <EditAssessmentFormHeader
      questionCounter={questions.length}
      draftAssessment={draftAssessment}
      onGoBack={onGoBack}
    />
  );

  return (
    <>
      <ScrollView
        ref={scrollViewRef}
        style={{ paddingBottom: 80, paddingRight: 55 }}
      >
        {renderHeader()}
        {questions.map(renderItem)}
      </ScrollView>
      <CustomModal
        visible={modalVisibility.isVisible}
        onBackdropPress={() => {
          setModalVisibility({
            ...modalVisibility,
            isVisible: false,
          });
          navigation.navigate('Main', { screen: 'Dashboard' });
        }}
        children={renderSubmit()}
        seconds={3}
      />
      <CustomModal
        visible={modalVisibility.isDraftVisible}
        onBackdropPress={() => {
          setModalVisibility({
            ...modalVisibility,
            isDraftVisible: false,
          });
          isExport.current = false;
        }}
        children={renderDraftSubmit()}
      />
      <CustomModal
        visible={modalVisibility.isErrorVisible}
        onBackdropPress={() =>
          setModalVisibility({
            ...modalVisibility,
            isErrorVisible: false,
          })
        }
        children={renderErrorMessage()}
        seconds={3}
      />
      <CustomModal
        visible={modalVisibility.isOfflineVisible}
        onBackdropPress={() =>
          setModalVisibility({
            ...modalVisibility,
            isOfflineVisible: false,
          })
        }
        children={renderOfflinePrompt(isDraft)}
        seconds={3}
      />
      <CustomModal
        visible={modalVisibility.isDeleteVisible}
        onBackdropPress={() => {
          if (!deleteLoading) {
            setModalVisibility({
              ...modalVisibility,
              isDeleteVisible: false,
            });
          }
        }}
        children={renderDeletePrompt()}
      />
      <CustomModal
        visible={modalVisibility.isExportPdfVisible}
        onBackdropPress={() => {
          if (!loading) {
            setModalVisibility({
              ...modalVisibility,
              isExportPdfVisible: false,
            });
          }
        }}
        children={renderExportPdfPrompt()}
      />
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    loading: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      paddingTop: 8,
      paddingBottom: 17,
      minHeight: 69,
    },
    headerRightContainer: {
      marginHorizontal: 20,
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerLeftContainer: {
      marginHorizontal: 20,
    },
    headerTitle: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 18,
      letterSpacing: 1,
    },
    title: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 24,
      color: 'text-dark',
    },
    dropDownIcon: {
      paddingLeft: 46,
    },
    headerSubTitle: {
      flexDirection: 'row',
      paddingTop: 5,
      alignItems: 'center',
    },
    counterBadge: {
      height: 32,
      minWidth: 88,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'badge-grey',
      borderRadius: 16,
      paddingTop: 7,
      paddingBottom: 8,
      marginLeft: 13,
    },
    detailsContainer: {
      marginTop: 20,
      flexDirection: 'row',
    },
    detailsLeft: {
      flexDirection: 'column',
      minWidth: 522,
    },
    detailsRight: {
      flexDirection: 'column',
      minWidth: 686,
    },
    infoRow: {
      flexDirection: 'row',
      alignItems: 'baseline',
      paddingBottom: 24,
    },
    infoTitle: {
      minWidth: 182,
      fontFamily: 'UniversLTPro_Regular',
      fontSize: 14,
      color: 'text-grey',
    },
    infoContent: {
      minWidth: 223,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    editButton: {
      marginTop: 8,
      marginBottom: 24,
    },
    edit: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'bp-green',
    },
    separator: {
      height: 1,
      backgroundColor: 'grey-100',
    },
    questionsContainer: {
      flex: 1,
    },
    buttonContainer: {
      paddingTop: 24,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    button: {
      marginLeft: 15,
      width: 312,
      justifyContent: 'flex-start',
    },
    card: {
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingVertical: 38,
      paddingHorizontal: 47,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      height: 228,
    },
    draftCard: {
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingTop: 32,
      paddingBottom: 53,
      paddingHorizontal: 47,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 570,
      minHeight: 263,
    },
    textBox: {
      marginTop: 20,
    },
    text: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    submitTitle: {
      marginTop: 5,
      marginBottom: 10,
      fontSize: 24,
    },
    warningCard: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      minHeight: 240,
      borderRadius: 4,
      paddingTop: 42,
      paddingBottom: 62,
    },
    backCard: {
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingVertical: 20,
      paddingHorizontal: 47,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      minHeight: 320,
    },
    deleteCard: {
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingVertical: 20,
      paddingHorizontal: 47,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 540,
      height: 320,
    },
    backButtons: {
      flexDirection: 'row',
      marginTop: 40,
    },
  },
});

export default observer(EditAssessmentFormFill);
