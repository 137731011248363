import { useIsFocused } from '@react-navigation/native';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Linking,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { TabView, Route } from 'react-native-tab-view';

import ActionItems from '../../components/Actions/ActionItems';
import ActionTracker from '../../components/Actions/ActionTracker';
import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { useStore } from '../../stores';

const Actions: React.FC = () => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const layout = useWindowDimensions();
  const isFocused = useIsFocused();
  const { setCurrentlyEditingAssessment } = useStore();

  useEffect(() => {
    if (isFocused) {
      setCurrentlyEditingAssessment(-1);
    }
  }, [isFocused]);

  const onRatLinkPress = async () => {
    const url = config.ratUrl;

    if (url !== '') {
      const canOpen = await Linking.canOpenURL(url);
      if (canOpen) {
        //@ts-ignore
        Linking.openURL(url, '_blank');
      }
    }
  };

  const onCatLinkPress = async () => {
    const url = `${config.catUrl}?hint=0`;

    if (url !== '') {
      const canOpen = await Linking.canOpenURL(url);
      if (canOpen) {
        //@ts-ignore
        Linking.openURL(url, '_blank');
      }
    }
  };

  const onPbiLinkPress = async () => {
    const url = config.pbiUrl;

    if (url !== '') {
      const canOpen = await Linking.canOpenURL(url);
      if (canOpen) {
        //@ts-ignore
        Linking.openURL(url, '_blank');
      }
    }
  };

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'first', title: 'Action items' },
    { key: 'second', title: 'Action tracker [Conformance/Quality]' },
  ]);

  const renderScene = ({ route }: { route: Route }) => {
    switch (route.key) {
      case 'first':
        return <ActionItems index={index} />;
      case 'second':
        return <ActionTracker index={index} />;
      default:
        return null;
    }
  };

  const renderTabBar = useCallback(() => {
    return (
      <View style={styles.topContainer}>
        <View style={styles.tabsContainer}>
          <TouchableOpacity
            onPress={() => {
              setIndex(0);
            }}
            activeOpacity={1}
            style={[{ marginRight: 32 }, index === 0 && styles.tabUnderline]}
          >
            <Text
              style={[
                styles.text,
                index === 0 && { color: theme['text-dark'] },
              ]}
            >
              Action items
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setIndex(1);
            }}
            activeOpacity={1}
            style={[{ marginRight: 32 }, index === 1 && styles.tabUnderline]}
          >
            <Text
              style={[
                styles.text,
                index === 1 && { color: theme['text-dark'] },
              ]}
            >
              Action tracker [Conformance/Quality]
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              onRatLinkPress();
            }}
            activeOpacity={1}
            style={[
              { flexDirection: 'row', marginRight: 32 },
              index === 2 && styles.tabUnderline,
            ]}
          >
            <Icon
              name="external-link-outline"
              width={20}
              height={20}
              fill={theme['gray-05']}
              style={{ marginRight: 5 }}
            />
            <Text
              style={[
                styles.text,
                index === 2 && { color: theme['text-dark'] },
              ]}
            >
              RAT
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              onCatLinkPress();
            }}
            activeOpacity={1}
            style={[
              { flexDirection: 'row', marginRight: 32 },
              index === 3 && styles.tabUnderline,
            ]}
          >
            <Icon
              name="external-link-outline"
              width={20}
              height={20}
              fill={theme['gray-05']}
              style={{ marginRight: 5 }}
            />
            <Text
              style={[
                styles.text,
                index === 3 && { color: theme['text-dark'] },
              ]}
            >
              CAT
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              onPbiLinkPress();
            }}
            activeOpacity={1}
            style={[
              { flexDirection: 'row' },
              index === 4 && styles.tabUnderline,
            ]}
          >
            <Icon
              name="external-link-outline"
              width={20}
              height={20}
              fill={theme['gray-05']}
              style={{ marginRight: 5 }}
            />
            <Text
              style={[
                styles.text,
                index === 4 && { color: theme['text-dark'] },
              ]}
            >
              Power BI Dashboard
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }, [index]);

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      renderTabBar={renderTabBar}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
      lazy
      lazyPreloadDistance={0}
    />
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
    },
    loading: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    topContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 26,
      marginVertical: 16,
    },
    tabsContainer: {
      flexDirection: 'row',
      width: 298,
    },
    tabUnderline: {
      borderBottomWidth: 3,
      borderBottomColor: 'bp-green',
    },
    text: {
      fontSize: 16,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'gray-05',
    },
  },
});

export default observer(Actions);
