import { Input, Text } from '@ui-kitten/components';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import ErrorIcon from '../../../assets/images/error.svg';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

type Props = {
  error?: any;
  draftComment?: string;
  setValue: (value: string) => void;
};

const CommentBox: React.FC<Props> = ({ error, draftComment, setValue }) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const ref = useRef(null);

  const isHovered = useHover(ref);
  const [commentText, setCommentText] = useState<string>();

  const onChangeText = (value: string): void => {
    setCommentText(value);
    setValue(value);
  };

  useEffect(() => {
    if (draftComment) {
      setCommentText(draftComment);
    }
  }, []);

  return (
    <View style={styles.container} ref={ref}>
      {!isUndefined(error) ? (
        <View style={styles.errorRow}>
          <ErrorIcon />
          <Text style={styles.errorText}>{error.message}</Text>
        </View>
      ) : (
        <View style={styles.errorEmpty} />
      )}
      <View
        style={[
          styles.commentContainer,
          isHovered
            ? styles.hover
            : !isUndefined(error) && error.message === 'Comment is required'
            ? styles.error
            : styles.inactive,
        ]}
      >
        <Input
          status="primary"
          size="medium"
          multiline
          textStyle={{ minHeight: 129 }}
          onChangeText={onChangeText}
          value={commentText}
          placeholder="Write a comment"
        />
      </View>
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flexDirection: 'column',
    },
    errorRow: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 4,
      height: 24,
    },
    errorText: {
      fontSize: 14,
      fontFamily: 'UniversBP_Regular',
      color: 'text-dark',
    },
    error: {
      borderColor: 'button-danger-outline-active',
      borderWidth: 2,
    },
    errorEmpty: {
      height: 28,
    },
    hover: {
      borderColor: 'input-field-outline-hover',
    },
    inactive: {
      borderColor: 'input-field-outline',
    },
    commentContainer: {
      alignSelf: 'stretch',
      borderRadius: 4,
      borderWidth: 1,
      backgroundColor: 'input-field-background',
      padding: 16,
    },
  },
});

export default observer(CommentBox);
