import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('bpEwells/Category')
export default class Category extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  isActive: prop<boolean>(),

  // foreign key id
  selfVerificationType: prop<number>(),
}) {
  @modelAction
  update(data: ModelCreationData<Category>) {
    Object.assign(this, data);
  }
}
