import { format, parseISO } from 'date-fns';
import { cloneDeep, isEmpty } from 'lodash';
import qs from 'qs';
import { default as snakeCaseKeys } from 'snakecase-keys';

import config from '../../config';
import { ActionData, FilterData } from '../../types/';
import { callApiWithToken } from './base';

export const addAction = async (token: string, data: ActionData) =>
  callApiWithToken(
    `${config.urls.actions}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateAction = async (
  token: string,
  id: number,
  data: ActionData,
) =>
  callApiWithToken(
    `${config.urls.actions}${id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const refreshCatActions = async (token: string) =>
  callApiWithToken(`${config.urls.refreshCatActions}`, token, 'POST', {
    'Content-Type': 'application/json',
  });

export const fetchActions = async (token: string) =>
  callApiWithToken(`${config.urls.actions}`, token, 'GET');

export const fetchCatActions = async (
  token: string,
  filters: FilterData,
  pageNum: number = 1,
) => {
  let dateFilters = '';
  const paramFilters = cloneDeep(filters) || {};
  if (filters && filters.dateStart) {
    dateFilters += `&date_start=${format(
      parseISO(filters.dateStart),
      'MM/dd/yyyy',
    )}`;
    paramFilters.dateStart = undefined;
  }
  if (filters && filters.dateEnd) {
    dateFilters += `&date_end=${format(
      parseISO(filters.dateEnd),
      'MM/dd/yyyy',
    )}`;
    paramFilters.dateEnd = undefined;
  }
  if (filters && filters.status && filters.status === 'Overdue') {
    dateFilters += `&local_current_date=${format(new Date(), 'MM/dd/yyyy')}`;
  }
  const url = `${config.urls.catActions}?page=${pageNum}`;
  const params = Object.fromEntries(
    Object.entries(paramFilters).filter(([k, v]) => v),
  );
  return callApiWithToken(
    `${url}${isEmpty(params) ? '' : '&'}${qs.stringify(
      snakeCaseKeys(params),
    )}${dateFilters}`,
    token,
    'GET',
  );
};
