import { useStore } from '../stores';

export const getValueById = (object: any, id: string) => {
  return object.find((obj: any) => obj.id === id).text;
};

export const useUserId = () => {
  const store = useStore();
  const { authStore } = store;

  return authStore.user?.id.toString() || '';
};

export const useUserFirstName = () => {
  const store = useStore();
  const { authStore } = store;

  return `${authStore.user?.firstName}`;
};

export const useUserFullName = () => {
  const store = useStore();
  const { authStore } = store;

  return `${authStore.user?.firstName} ${authStore.user?.lastName}`;
};

export const useUserNameEmail = () => {
  const store = useStore();
  const { authStore } = store;

  return `${authStore.user?.firstName} ${authStore.user?.lastName} <${authStore.user?.email}>`;
};

export const getAssessmentIdFormat = (id: string) => {
  return id.padStart(6, '0');
};

const imageFormats = ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'webp'];
// viewable formats of react native image component

export const isFileImage = (file: File) => {
  if (file.type && imageFormats.includes(file.type.split('/')[1])) {
    return true;
  } else {
    return false;
  }
};

export const isNameImage = (name: string) => {
  return imageFormats.includes(name.split('.')[1]);
};

export const isExtImage = (ext: string | undefined) => {
  return ext ? imageFormats.includes(ext.toLowerCase()) : false;
};

export const getExtensionFromImageBase64 = (base64: string) => {
  const mimeAndExtensions: { [key: string]: string } = {
    'image/jpeg': 'jpg',
    'image/jpg': 'jpeg',
    'image/gif': 'gif',
    'image/png': 'png',
    'image/svg+xml': 'svg',
    'image/webp': 'webp',
    'image/tiff': 'tiff',
    'image/bmp': 'bmp',
  };
  const mime = base64.substring(base64.indexOf(':') + 1, base64.indexOf(';'));
  return mimeAndExtensions[mime];
};

export const convertFileToBase64 = async (file: File | Blob) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event && event.target) {
        resolve(event.target.result as string);
      }
      resolve('');
    };

    reader.readAsDataURL(file);
  });
};

export async function preparePhotoForUpload(base64: any, name: string) {
  if (base64.startsWith('data:')) {
    const response = await fetch(base64);
    const blob = await response.blob();
    return new File([blob], name);
  }
  return base64;
}

export const chunkArray = (array: any[], size: number) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};
