import { EventArg, RouteProp, useNavigation } from '@react-navigation/native';
import { Button, useTheme, Text, Icon } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { View } from 'react-native';

import OfflineIcon from '../../../assets/images/warning_icon.svg';
import CustomModal from '../../components/Common/CustomModal';
import MainContainer from '../../components/Common/MainContainer';
import CreateActionHeader from '../../components/CreateAction/CreateActionHeader';
import CreateActionType from '../../components/CreateAction/CreateActionType';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { AppStackNavigation, AppStackParamList } from '../../navigation';
import { useStore } from '../../stores';
import { getAssessmentIdFormat } from '../../utils/helper';

type Props = { route: RouteProp<AppStackParamList, 'Create Action'> };

const CreateAction: React.FC<Props> = (props) => {
  const [isChanged, setIsChanged] = useState(false);
  const [isBackPromptVisible, setIsBackPromptVisible] = useState(false);
  const styles = useResponsiveStyleSheet(themedStyles);
  const navigation = useNavigation<AppStackNavigation>();
  const theme = useTheme();
  const store = useStore();
  const { isInternetReachable, assessmentStore } = store;

  const {
    answerId,
    actionId,
    questionId,
    assessmentId,
    assessmentForm,
    questionString,
  } = props?.route?.params ?? {};

  const answer = useMemo(() => {
    return assessmentStore.getAnswers().find((a) => a.id === Number(answerId));
  }, [assessmentStore.answers.values()]);

  const methods = useForm();

  const exitEvent = useRef<
    EventArg<
      'beforeRemove',
      true,
      {
        action: Readonly<{
          type: string;
          payload?: object | undefined;
          source?: string | undefined;
          target?: string | undefined;
        }>;
      }
    >
  >();

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      if (!isInternetReachable) {
        e.preventDefault();
      } else {
        if (isChanged) {
          e.preventDefault();
          exitEvent.current = e;
          setIsBackPromptVisible(true);
        }
      }
    });
    return unsubscribe;
  }, [navigation, isChanged, isInternetReachable]);

  const renderBackPrompt = () => (
    <View style={styles.card}>
      <OfflineIcon />
      <View style={styles.textBox}>
        <Text style={styles.text}>
          You have unsaved changes.{'\n\n'}
          <Text style={[styles.text, { color: theme['text-grey'] }]}>
            Leaving this page will discard your changes. Confirm navigation?
          </Text>
        </Text>
      </View>
      <View style={styles.backButtons}>
        <Button
          status="primary"
          size="large"
          appearance="outline"
          style={{ marginRight: 20, width: 200 }}
          accessoryLeft={() => (
            <Icon
              name="trash-outline"
              width={20}
              height={20}
              fill={theme['text-dark']}
            />
          )}
          onPress={() => {
            if (exitEvent.current) {
              navigation.dispatch(exitEvent.current.data.action);
            }
          }}
        >
          Discard Changes
        </Button>
        <Button
          status="primary"
          size="large"
          appearance="filled"
          style={{ width: 200 }}
          accessoryLeft={() => (
            <Icon
              name="edit-outline"
              width={24}
              height={24}
              fill={theme['text-white']}
            />
          )}
          onPress={() => setIsBackPromptVisible(false)}
        >
          Continue Editing
        </Button>
      </View>
    </View>
  );

  return (
    <MainContainer>
      <FormProvider {...methods}>
        <View style={styles.container}>
          <CreateActionHeader
            assessmentId={getAssessmentIdFormat(assessmentId || '')}
            assessmentForm={assessmentForm}
            question={questionString}
            comment={answer ? answer.description : ''}
          />
          <CreateActionType
            assessmentId={Number(assessmentId)}
            questionId={questionId}
            answerId={Number(answerId)}
            setIsChanged={setIsChanged}
            actionId={actionId}
          />
          <CustomModal
            visible={isBackPromptVisible}
            onBackdropPress={() => setIsBackPromptVisible(false)}
            children={renderBackPrompt()}
          />
        </View>
      </FormProvider>
    </MainContainer>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      overflow: 'hidden',
      paddingBottom: 40,
    },
    textBox: {
      marginTop: 30,
    },
    text: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    card: {
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingVertical: 20,
      paddingHorizontal: 47,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      height: 320,
    },
    backButtons: {
      flexDirection: 'row',
      marginTop: 40,
    },
    row: {
      flexDirection: 'row',
      marginTop: 16,
    },
    label: {
      width: 171,
    },
    data: {
      width: 477,
    },
  },
});

export default observer(CreateAction);
