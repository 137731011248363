import { useTheme } from '@ui-kitten/components';
import MDEditor from '@uiw/react-md-editor';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { View } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

type Props = {
  comment: string;
};

const ViewCommentBox: React.FC<Props> = ({ comment }) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();

  return (
    <View style={styles.container}>
      <MDEditor.Markdown
        data-color-mode="light"
        source={comment}
        style={{
          fontFamily: 'UniversBP_Regular',
          fontSize: 14,
          color: theme['text-grey'],
          backgroundColor: 'transparent',
        }}
      />
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      paddingTop: 28,
    },
  },
});

export default observer(ViewCommentBox);
