import { useNavigation } from '@react-navigation/native';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import MDEditor from '@uiw/react-md-editor';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Easing,
  FlatList,
  Linking,
  TouchableOpacity,
  View,
} from 'react-native';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';
import TurndownService from 'turndown';

import config from '../../config';
import { ActionTypeChoices } from '../../constants/Action';
import { useMediaQueries } from '../../hooks/useMediaQueries';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { AppStackNavigation } from '../../navigation';
import { useStore } from '../../stores';
import { ActionItemEntryData } from '../../types';
import { getAssessmentIdFormat } from '../../utils/helper';

type Props = {
  actionItem: ActionItemEntryData;
};

type ActionItemDetails = {
  id?: number;
  title: string;
  description: string;
  type: string;
  owner: string;
  dueDate: string;
};

const ActionItem: React.FC<Props> = ({ actionItem }) => {
  const [expand, setExpand] = useState(false);
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation<AppStackNavigation>();
  const store = useStore();
  const { actionStore, userStore, isInternetReachable } = store;
  const { isDesktop } = useMediaQueries();

  const markdownItInstance = MarkdownIt({ typographer: true, linkify: true });
  const tdservice = new TurndownService();
  tdservice.escape = (string) => string;

  const action = actionStore
    .getActions()
    .find((a) => a.answer === actionItem.id);

  const questionString = `${actionItem.questionString}${
    actionItem.questionDescription ? `\n${actionItem.questionDescription}` : ''
  }`;

  const onLinkPress = async () => {
    let url = '';
    if (action) {
      if (action.actionType === ActionTypeChoices.RISK) {
        url = config.ratUrl;
      } else {
        url = `${config.catUrl}`;
        url += `?AssessmentID=${actionItem.assessment}`;
        url += `&QuestionID=${actionItem.question}`;
        url += `&EventDate=${format(new Date(), 'yyyy/MM/dd/')}`;
        url += '&hint=0';
        if (action.actionType === ActionTypeChoices.CONFORMANCE) {
          url += '&ActionSource=FI';
        } else if (action.actionType === ActionTypeChoices.QUALITY) {
          url += '&ActionSource=QI';
        }
        if (config.env !== 'production') {
          url += `&DataSource=${config.env}`;
        }
      }
    }

    if (url !== '') {
      const canOpen = await Linking.canOpenURL(url);
      if (canOpen) {
        //@ts-ignore
        Linking.openURL(url, '_blank');
      }
    }
  };

  const itemSpinValue = useRef(new Animated.Value(0)).current;

  const spin = itemSpinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '90deg'],
  });

  useEffect(() => {
    Animated.timing(itemSpinValue, {
      duration: 150,
      easing: Easing.linear,
      toValue: expand ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [expand]);

  const expandDetailsList = useMemo(() => {
    const details: ActionItemDetails[] = [];
    if (action) {
      const creator = userStore.getUser(action.creator);
      if (action.actionType === ActionTypeChoices.RISK) {
        details.push({
          title: 'RAT [Risk Assurance Tool]',
          description: 'See bp RAT for details',
          type: action.actionType,
          owner: actionItem.migratedAssessor
            ? actionItem.migratedAssessor
            : creator
            ? `${creator.firstName} ${creator.lastName}`
            : '-',
          dueDate: '-',
        });
      } else if (action.actionType === ActionTypeChoices.NO_ACTION) {
        details.push({
          id: action.id,
          title: 'No Action',
          description: action.description,
          type: action.actionType,
          owner: actionItem.migratedAssessor
            ? actionItem.migratedAssessor
            : creator
            ? `${creator.firstName} ${creator.lastName}`
            : '-',
          dueDate: '-',
        });
      } else if (
        action.actionType === ActionTypeChoices.CONFORMANCE ||
        action.actionType === ActionTypeChoices.QUALITY
      ) {
        const catActions = actionStore
          .getCatActions()
          .filter(
            (ca) =>
              ca.action === action.id && ca.status !== 'Draft' && ca.isActive,
          );
        catActions.forEach((ca) => {
          details.push({
            title: ca.title,
            description: ca.description,
            type: action.actionType,
            owner: ca.owner,
            dueDate: format(parseISO(ca.dueDate), 'dd MMM yyyy'),
          });
        });
      }
    }
    return details;
  }, [actionStore.catActions.values(), actionStore.actions.values(), action]);

  const renderExpandHeader = useCallback(
    () => (
      <View style={styles.expandHeader}>
        <View style={{ flex: 147 / 1330, marginLeft: 34 }}>
          <Text style={styles.expandHeaderText}>Action title</Text>
        </View>
        <View style={{ flex: 264 / 1330 }}>
          <Text style={styles.expandHeaderText}>Action description</Text>
        </View>
        <View style={{ flex: 161 / 1330 }}>
          <Text style={styles.expandHeaderText}>Action type</Text>
        </View>
        <View style={{ flex: 192 / 1330 }}>
          <Text style={styles.expandHeaderText}>Action owner</Text>
        </View>
        <View style={{ flex: 398 / 1330 }}>
          <Text style={[styles.expandHeaderText, { marginLeft: 20 }]}>
            Due date
          </Text>
        </View>
        <View style={{ flex: 168 / 1330 }} />
      </View>
    ),
    [],
  );

  const renderExpandDetails = useCallback(
    ({ item }: { item: ActionItemDetails }) => (
      <View style={styles.expandDetails}>
        <View style={{ flex: 147 / 1330, marginLeft: 34 }}>
          <Text style={styles.expandDetailsText}>{item.title}</Text>
        </View>
        <View style={{ flex: 264 / 1330 }}>
          <MDEditor.Markdown
            data-color-mode="light"
            source={item.description}
            style={{
              fontSize: 14,
              fontFamily: 'UniversBP_Light',
              fontWeight: 'bold',
              color: theme['text-dark'],
              backgroundColor: 'transparent',
              paddingRight: 20,
            }}
          />
        </View>
        <View style={{ flex: 161 / 1330 }}>
          <Text style={styles.expandDetailsText}>{item.type}</Text>
        </View>
        <View style={{ flex: 192 / 1330 }}>
          <Text style={styles.expandDetailsText}>{item.owner}</Text>
        </View>
        <View style={{ flex: 398 / 1330 }}>
          <Text style={[styles.expandDetailsText, { marginLeft: 20 }]}>
            {item.dueDate}
          </Text>
        </View>
        <View style={{ flex: 168 / 1330 }}>
          <TouchableOpacity
            disabled={!isInternetReachable}
            onPress={() => {
              if (item.type === ActionTypeChoices.NO_ACTION) {
                navigation.navigate('Create Action', {
                  answerId: actionItem.id!.toString(),
                  actionId: item.id!.toString(),
                  questionId: actionItem.question,
                  assessmentId: actionItem.assessmentId,
                  assessmentForm: actionItem.assessmentForm,
                  questionString,
                });
              } else {
                onLinkPress();
              }
            }}
          >
            <Text
              style={[styles.expandDetailsText, { color: theme['bp-green'] }]}
            >
              {isInternetReachable
                ? item.type === ActionTypeChoices.NO_ACTION
                  ? 'Modify details'
                  : 'Add new action'
                : ''}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    ),
    [isInternetReachable],
  );

  const renderEmptyDetails = useCallback(
    () => (
      <View
        style={[styles.expandDetails, { flex: 1, justifyContent: 'center' }]}
      >
        <Text style={[styles.expandDetailsText, { textAlign: 'center' }]}>
          No actions found
        </Text>
      </View>
    ),
    [],
  );

  const renderCreateActionButton = useCallback(
    () => (
      <View style={styles.createAction}>
        <TouchableOpacity
          disabled={!isInternetReachable}
          onPress={() =>
            navigation.navigate('Create Action', {
              answerId: actionItem.id!.toString(),
              questionId: actionItem.question,
              assessmentId: actionItem.assessmentId,
              assessmentForm: actionItem.assessmentForm,
              questionString,
            })
          }
        >
          {isDesktop ? (
            <Text
              style={[
                styles.actionText,
                { marginRight: 0, alignSelf: 'center' },
              ]}
            >
              Create action
            </Text>
          ) : (
            <Icon
              name="plus-outline"
              fill={theme['text-dark']}
              width={18}
              height={18}
            />
          )}
        </TouchableOpacity>
      </View>
    ),
    [styles],
  );

  const renderSubmittedTag = useCallback(
    () => (
      <View style={styles.submitted}>
        {isDesktop ? (
          <Text
            style={[
              styles.actionText,
              {
                color: theme['text-white'],
                marginRight: 0,
                alignSelf: 'center',
              },
            ]}
          >
            Submitted
          </Text>
        ) : (
          <Icon
            name="checkmark-outline"
            fill={theme['text-white']}
            width={16}
            height={16}
          />
        )}
      </View>
    ),
    [styles],
  );

  return (
    <>
      <TouchableOpacity
        style={[
          styles.actionItem,
          expand && { backgroundColor: theme['gray-07'] },
        ]}
        activeOpacity={1}
        onPress={() => {
          if (action && action.submitted) {
            setExpand(!expand);
          } else if (isInternetReachable) {
            navigation.navigate('Create Action', {
              answerId: actionItem.id!.toString(),
              questionId: actionItem.question,
              assessmentId: actionItem.assessmentId,
              assessmentForm: actionItem.assessmentForm,
              questionString,
            });
          }
        }}
      >
        <View
          style={[
            styles.actionCell,
            { flex: 160 / 1330, flexDirection: 'row' },
          ]}
        >
          <Animated.View
            style={{ transform: [{ rotate: spin }], width: 22, height: 22 }}
          >
            <Icon
              name="chevron-right-outline"
              width={22}
              height={22}
              fill={
                !!action && action.submitted
                  ? theme['text-dark']
                  : theme['text-white']
              }
            />
          </Animated.View>
          <Text style={styles.actionText}>
            AS-{getAssessmentIdFormat(actionItem.assessmentId || '')}
          </Text>
        </View>
        <View style={[styles.actionCell, { flex: 208 / 1330 }]}>
          <Text style={styles.actionText}>
            {actionItem.assessmentForm || ''}
          </Text>
        </View>
        <View style={[styles.actionCell, { flex: 180 / 1330 }]}>
          <Text style={styles.actionText}>
            {actionItem.assessmentDateObserved
              ? format(
                  parseISO(actionItem.assessmentDateObserved),
                  'dd MMM yyyy',
                )
              : '-'}
          </Text>
        </View>
        <View style={[styles.actionCell, { flex: 300 / 1330 }]}>
          <Markdown
            style={{
              body: styles.actionText,
              paragraph: { marginTop: 0, marginBottom: 0 },
            }}
            markdownit={markdownItInstance}
          >
            {tdservice.turndown(
              questionString.replace(/(?:\r\n|\r|\n)/g, '<br>') || '',
            )}
          </Markdown>
        </View>
        <View style={[styles.actionCell, { flex: 240 / 1330 }]}>
          <MDEditor.Markdown
            data-color-mode="light"
            source={actionItem.description || ''}
            style={{
              fontSize: 14,
              fontFamily: 'UniversBP_Light',
              fontWeight: 'bold',
              color: theme['text-dark'],
              backgroundColor: 'transparent',
            }}
          />
        </View>
        <View
          style={[
            styles.actionCell,
            isDesktop ? { flex: 121 / 1330 } : { flex: 60 / 1330 },
          ]}
        >
          {action && action.submitted
            ? renderSubmittedTag()
            : renderCreateActionButton()}
        </View>
        <View
          style={[
            styles.actionCell,
            isDesktop ? { flex: 121 / 1330 } : { flex: 181 / 1330 },
          ]}
        >
          <Text style={styles.actionText}>
            {actionItem.assessorId
              ? `${actionItem.assessorFirstName} ${actionItem.assessorLastName}`
              : `${actionItem.migratedAssessor}`}
          </Text>
        </View>
      </TouchableOpacity>
      {expand && (
        <FlatList
          data={expandDetailsList}
          renderItem={renderExpandDetails}
          ListHeaderComponent={renderExpandHeader}
          ListEmptyComponent={renderEmptyDetails}
        />
      )}
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    actionItem: {
      flexDirection: 'row',
      paddingVertical: 20,
      backgroundColor: 'text-white',
    },
    actionCell: {
      marginHorizontal: 15,
    },
    actionText: {
      fontSize: 14,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-dark',
    },
    submitted: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 80,
      height: 25,
      borderRadius: 12,
      backgroundColor: 'green-500',
    },
    actionRequired: {
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 25,
      borderRadius: 12,
      backgroundColor: 'gray-05',
    },
    createAction: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 100,
      height: 30,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: 'gray-01',
    },
    expandHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 32,
      backgroundColor: 'grey-100',
    },
    expandTextContainer: {
      flex: 0.15,
    },
    expandHeaderText: {
      fontSize: 12,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-grey',
    },
    expandDetails: {
      flexDirection: 'row',
      paddingVertical: 20,
      backgroundColor: 'gray-07',
    },
    expandDetailsText: {
      fontSize: 14,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-dark',
    },
  },
  tabletStyle: {
    createAction: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 30,
      height: 30,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: 'gray-01',
    },
    submitted: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 25,
      height: 25,
      borderRadius: 12.5,
      backgroundColor: 'green-500',
    },
  },
});

export default observer(ActionItem);
