import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { AnswerChoices } from '../constants/Answer';

@model('bpEwells/Answer')
export default class Answer extends Model({
  id: prop<number>(),
  answer: prop<AnswerChoices>(),
  description: prop<string>(''),
  created: prop<string>(),
  modified: prop<string>(),

  // foreign key ids
  assessment: prop<number>(),
  question: prop<number>(),
  questionVersionId: prop<string>(),
}) {
  @modelAction
  update(data: ModelCreationData<Answer>) {
    Object.assign(this, data);
  }
}
