import { Text, useTheme } from '@ui-kitten/components';
import MDEditor from '@uiw/react-md-editor';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { View } from 'react-native';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';
import TurndownService from 'turndown';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

type Props = {
  assessmentId?: string;
  assessmentForm?: string;
  question?: string;
  comment?: string;
};

const CreateActionHeader: React.FC<Props> = (props) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const markdownItInstance = MarkdownIt({ typographer: true, linkify: true });
  const tdservice = new TurndownService();
  tdservice.escape = (string) => string;

  return (
    <>
      <View>
        <Text style={[styles.text, { fontSize: 24, marginVertical: 16 }]}>
          Create action
        </Text>
      </View>
      <View>
        <View style={styles.row}>
          <View style={styles.label}>
            <Text
              style={[styles.text, { fontSize: 14, color: theme['text-grey'] }]}
            >
              Assessment ID
            </Text>
          </View>
          <View style={styles.data}>
            <Text style={styles.text}>AS-{props.assessmentId || ''}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.label}>
            <Text
              style={[styles.text, { fontSize: 14, color: theme['text-grey'] }]}
            >
              Assessment
            </Text>
          </View>
          <View style={styles.data}>
            <Text style={styles.text}>{props.assessmentForm}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.label}>
            <Text
              style={[styles.text, { fontSize: 14, color: theme['text-grey'] }]}
            >
              Question
            </Text>
          </View>
          <View style={styles.data}>
            <Markdown
              style={{
                body: styles.text,
                paragraph: { marginTop: 0, marginBottom: 0 },
              }}
              markdownit={markdownItInstance}
            >
              {tdservice.turndown(
                props.question
                  ? props.question.replace(/(?:\r\n|\r|\n)/g, '<br>')
                  : '',
              )}
            </Markdown>
            {/* <Text style={styles.text}>{props.question}</Text> */}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.label}>
            <Text
              style={[styles.text, { fontSize: 14, color: theme['text-grey'] }]}
            >
              Comment
            </Text>
          </View>
          <View style={styles.data}>
            <MDEditor.Markdown
              data-color-mode="light"
              source={props.comment}
              style={{
                fontFamily: 'UniversBP_Light',
                fontWeight: 'bold',
                fontSize: 16,
                color: theme['text-dark'],
                backgroundColor: 'transparent',
              }}
            />
          </View>
        </View>
      </View>
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    text: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    row: {
      flexDirection: 'row',
      marginTop: 16,
    },
    label: {
      width: 171,
    },
    data: {
      width: 477,
    },
  },
});

export default observer(CreateActionHeader);
