import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('bpEwells/CatAction')
export default class CatAction extends Model({
  id: prop<number>(),
  catId: prop<string>(''),
  status: prop<string>(''),
  title: prop<string>(''),
  owner: prop<string>(''),
  description: prop<string>(''),
  dueDate: prop<string>(''),
  completedDate: prop<string>(''),
  catCreated: prop<string>(''),
  catModified: prop<string>(''),
  isActive: prop<boolean>(),

  // foreign key ids
  action: prop<number>(),
}) {
  @modelAction
  update(data: ModelCreationData<CatAction>) {
    Object.assign(this, data);
  }
}
