import {
  EventArg,
  RouteProp,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import { useTheme, ViewPager } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ActivityIndicator, ScrollView, View } from 'react-native';

import AssessmentInfo from '../../components/AddAssessments/AssessmentInfo';
import CustomModal from '../../components/Common/CustomModal';
import MainContainer from '../../components/Common/MainContainer';
import UnsavedChangesPrompt from '../../components/Common/UnsavedChangesPrompt';
import EditAssessmentFormFill from '../../components/EditAssessments/EditAssessmentFormFill';
import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { AppStackNavigation, AppStackParamList } from '../../navigation';
import { useStore } from '../../stores';
import { ModalData } from '../../types';

type Props = { route: RouteProp<AppStackParamList, 'Add Assessments'> };

const AddAssessments: React.FC<Props> = (props) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const navigation = useNavigation<AppStackNavigation>();
  const theme = useTheme();
  const store = useStore();
  const {
    isInternetReachable,
    storageService,
    assessmentStore,
    setIsFromAssessment,
    setIsAssessmentsChanged,
    setIsStatisticsChanged,
    setCurrentlyEditingAssessment,
  } = store;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [draftAssessmentId, setDraftAssessmentId] = useState<number>();
  const [isChanged, setIsChanged] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isBackPromptVisible, setIsBackPromptVisible] = useState(false);

  const {
    modalAssessmentScheduleId,
    modalAssessmentFormId,
    modalAssessmentFormVersionId,
    modalPerformanceUnitId,
    modalSiteId,
    modalCategoryId,
    modalSvtId,
    cameFrom,
  } = props?.route?.params ?? {};

  const methods = useForm();
  const isFocused = useIsFocused();

  const modalData: ModalData = {
    scheduleId: modalAssessmentScheduleId,
    modalAssessmentFormId,
    modalAssessmentFormVersionId,
    performanceUnitId: modalPerformanceUnitId,
    siteId: modalSiteId,
    categoryId: modalCategoryId,
    svtId: modalSvtId,
  };

  useEffect(() => {
    if (isFocused) {
      setIsFromAssessment(true);
    }
  }, [isFocused]);

  const deleteDraft = useCallback(async () => {
    if (draftAssessmentId && !isSubmitted) {
      const deleteResult = await assessmentStore.deleteAssessment(
        Number(draftAssessmentId),
      );
      if (deleteResult.ok) {
        setIsAssessmentsChanged(true);
        setIsStatisticsChanged(true);
        await storageService.removeSet(
          `${config.filesKeyPrefix}${draftAssessmentId}`,
        );
        await storageService.removeItemAsync(
          `${config.formsKeyPrefix}${draftAssessmentId}`,
        );
      }
    }
  }, [draftAssessmentId, isSubmitted]);

  const onNext = () => {
    setSelectedIndex(selectedIndex + 1);
  };

  const onGoBack = () => {
    setSelectedIndex(selectedIndex - 1);
  };

  const exitEvent = useRef<
    EventArg<
      'beforeRemove',
      true,
      {
        action: Readonly<{
          type: string;
          payload?: object | undefined;
          source?: string | undefined;
          target?: string | undefined;
        }>;
      }
    >
  >();

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      e.preventDefault();

      // create promise to force it to wait for deletion
      new Promise<void>((resolve, reject) => {
        if (isInternetReachable) {
          if (isChanged) {
            exitEvent.current = e;
            setIsBackPromptVisible(true);
          } else if (!isSubmitted) {
            deleteDraft()
              .then(() => {
                resolve();
              })
              .catch((error) => {
                console.error(error);
                resolve();
              });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      }).then(() => {
        setCurrentlyEditingAssessment(-1);
        navigation.dispatch(e.data.action);
      });
    });

    return unsubscribe;
  }, [
    navigation,
    isChanged,
    isInternetReachable,
    draftAssessmentId,
    isSubmitted,
  ]);

  return (
    <MainContainer paddingTop={0} style={styles.container}>
      <FormProvider {...methods}>
        <View style={styles.container}>
          <ViewPager
            style={styles.main}
            selectedIndex={selectedIndex}
            swipeEnabled={false}
            onSelect={(index) => setSelectedIndex(index)}
          >
            <ScrollView style={styles.tabScroll}>
              <AssessmentInfo
                onNext={onNext}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
                modalData={modalData}
                draftAssessmentId={draftAssessmentId}
                setDraftAssessmentId={setDraftAssessmentId}
                deleteDraft={deleteDraft}
              />
            </ScrollView>
            <View style={styles.tab}>
              {!draftAssessmentId ? (
                <View style={styles.loading}>
                  <ActivityIndicator size="large" color={theme['bp-green']} />
                </View>
              ) : (
                <EditAssessmentFormFill
                  draftAssessmentId={draftAssessmentId}
                  setIsChanged={setIsChanged}
                  isChanged={isChanged}
                  cameFrom={cameFrom}
                  onGoBack={isSubmitted ? undefined : onGoBack}
                  selectedIndex={selectedIndex}
                  setIsSubmitted={setIsSubmitted}
                />
              )}
            </View>
          </ViewPager>
          <CustomModal
            visible={isBackPromptVisible}
            onBackdropPress={() => setIsBackPromptVisible(false)}
          >
            <View>
              <UnsavedChangesPrompt
                onDiscard={async () => {
                  if (exitEvent.current) {
                    await deleteDraft();
                    navigation.dispatch(exitEvent.current.data.action);
                  }
                }}
                onContinue={() => setIsBackPromptVisible(false)}
              />
            </View>
          </CustomModal>
        </View>
      </FormProvider>
    </MainContainer>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    loading: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      flex: 1,
      overflow: 'hidden',
      paddingRight: 0,
    },
    tabScroll: {
      flex: 1,
      paddingTop: 16,
      paddingBottom: 80,
      paddingRight: 55,
      width: '100%',
    },
    tab: {
      flex: 1,
      width: '100%',
    },
    main: {
      flex: 1,
      width: '100%',
    },
    backdrop: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 0,
    },
    textBox: {
      marginTop: 30,
    },
    text: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    card: {
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingVertical: 20,
      paddingHorizontal: 47,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      height: 320,
    },
    backButtons: {
      flexDirection: 'row',
      marginTop: 40,
    },
  },
});

export default observer(AddAssessments);
