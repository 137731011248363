import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import Category from '../models/Category';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('bpEwells/CategoryStore')
export default class CategoryStore extends Model({
  categories: prop(() => objectMap<Category>()),
}) {
  @observable
  loading = false;

  getCategory = (id: number): Category | undefined => {
    return this.categories.get(`${id}`);
  };

  getCategories = (): Category[] => {
    return Array.from(this.categories.values());
  };

  getActiveCategories = (selfVerificationTypeId?: number): Category[] => {
    if (selfVerificationTypeId) {
      return Array.from(this.categories.values()).filter(
        (c) => c.isActive && c.selfVerificationType === selfVerificationTypeId,
      );
    }
    return Array.from(this.categories.values()).filter((c) => c.isActive);
  };

  @modelAction
  createOrUpdateCategory(data: ModelCreationData<Category>) {
    const id = `${data.id}`;

    let category: Category;
    if (this.categories.has(id)) {
      category = this.categories.get(id)!;
    } else {
      category = new Category(data);
      this.categories.set(id, category);
    }

    category.update(data);
  }

  @modelFlow
  fetchCategories = _async(function* (this: CategoryStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Category>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchCategories(rootStore.authStore.accessToken)));
    } catch (error) {
      console.warn('[DEBUG] error fetching categories', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateCategory(data));

    this.loading = false;
    return getSuccess();
  });
}
