import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import React from 'react';

import AuthCallback from '../../containers/AuthCallback';
import SignIn from '../../containers/SignIn';

export type AuthStackParamList = {
  'Sign In': undefined;
  'Auth Callback': { code?: string };
};

export type AuthStackNavigation = StackNavigationProp<AuthStackParamList>;

type Props = object;

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStack: React.FC<Props> = (_props) => {
  return (
    <Stack.Navigator
      initialRouteName="Sign In"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="Sign In" component={SignIn} />
      <Stack.Screen name="Auth Callback" component={AuthCallback} />
    </Stack.Navigator>
  );
};

export default AuthStack;
