import { Button, Icon, Spinner, Text, useTheme } from '@ui-kitten/components';
import { orderBy, pick } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { View } from 'react-native';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';
import TurndownService from 'turndown';

import DatePicker from '../../components/Common/DatePicker';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import useUserDefaultDetails from '../../hooks/useUserDefaultDetails';
import Question from '../../models/Question';
import { useStore } from '../../stores';
import { AnswerData, AssessmentData, ModalData } from '../../types';
import AutocompleteComponent from '../Common/AutocompleteComponent';

type Props = {
  onNext: () => void;
  isChanged: boolean;
  setIsChanged: (state: boolean) => void;
  modalData: ModalData;
  draftAssessmentId?: number;
  setDraftAssessmentId: (id: number) => void;
  deleteDraft: () => Promise<void>;
};

const AssessmentInfo: React.FC<Props> = ({
  onNext,
  isChanged,
  setIsChanged,
  modalData,
  draftAssessmentId,
  setDraftAssessmentId,
  deleteDraft,
}) => {
  const store = useStore();
  const {
    performanceUnitStore,
    selfVerificationTypeStore,
    assessmentFormStore,
    assessmentStore,
    siteStore,
    categoryStore,
    userStore,
    setCurrentlyEditingAssessment,
  } = store;
  const tdservice = new TurndownService();
  tdservice.escape = (string) => string;

  const styles = useResponsiveStyleSheet(responsiveStyle);
  const theme = useTheme();
  const isFirstRender = useRef(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [description, setDescription] = useState('');
  const [assessmentFormId, setAssessmentFormId] = useState<string>();
  const markdownItInstance = MarkdownIt({ typographer: true, linkify: true });

  const { register, setValue, clearErrors, control, reset, getValues } =
    useFormContext<AssessmentData>();
  const {
    scheduleId,
    modalAssessmentFormId,
    modalAssessmentFormVersionId,
    performanceUnitId,
    siteId,
    svtId,
    categoryId,
  } = modalData;

  const userDefaultDetails = useUserDefaultDetails();

  const isModalEmpty = (obj: object) =>
    Object.values(obj).every((value) => value === undefined);

  const dateObserved = useWatch({
    name: 'dateObserved',
    defaultValue: new Date(),
    control,
  });
  const performanceUnit = useWatch({
    name: 'performanceUnit',
    defaultValue: userDefaultDetails.performanceUnit,
    control,
  });
  const assessmentForm = useWatch({ name: 'assessmentForm', control });
  const site = useWatch({
    name: 'site',
    defaultValue: userDefaultDetails.site,
    control,
  });
  const category = useWatch({ name: 'category', control });
  const assessor = useWatch({
    name: 'assessor',
    defaultValue: userDefaultDetails.id,
    control,
  });

  const performanceUnits = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    performanceUnitStore
      .getActivePerformanceUnits()
      .forEach((performanceUnit) => {
        values.push({
          id: performanceUnit.id.toString(),
          text: performanceUnit.name,
        });
      });
    return values;
  }, [performanceUnitStore.performanceUnits.values()]);

  const sites = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    siteStore.getActiveSites(Number(performanceUnit)).forEach((site) => {
      values.push({
        id: site.id.toString(),
        text: site.name,
      });
    });
    return values;
  }, [siteStore.sites.values(), useWatch({ name: 'performanceUnit' })]);

  const selfVerificationTypes = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    selfVerificationTypeStore
      .getActiveSelfVerificationTypes()
      .forEach((selfVerificationType) => {
        values.push({
          id: selfVerificationType.id.toString(),
          text: selfVerificationType.name,
        });
      });
    return values;
  }, [selfVerificationTypeStore.selfVerificationTypes.values()]);

  const fieldInspectionId =
    selfVerificationTypes.find(
      (obj: any) => obj.text === 'Safety Field Inspection',
    )?.id || selfVerificationTypes[0].id;

  const selfVerificationType = useWatch({
    name: 'selfVerificationType',
    defaultValue: !isModalEmpty(modalData)
      ? modalData.svtId
      : fieldInspectionId,
    control,
  });

  const categories = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    categoryStore
      .getActiveCategories(Number(selfVerificationType))
      .forEach((category) => {
        values.push({
          id: category.id.toString(),
          text: category.name,
        });
      });
    return values;
  }, [categoryStore.categories.values(), selfVerificationType]);

  const assessmentForms = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    assessmentFormStore
      .getActiveAssessmentForms(Number(selfVerificationType), Number(category))
      .forEach((assessmentForm) => {
        values.push({
          id: assessmentForm.id.toString(),
          text: assessmentForm.name,
        });
      });
    return values;
  }, [assessmentFormStore.assessmentForms.values()]);

  const users = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    userStore.getUsers({}).forEach((user) => {
      values.push({
        id: user.id.toString(),
        text: `${user.firstName} ${user.lastName}`,
      });
    });
    return values;
  }, [userStore.users.values()]);

  useEffect(() => {
    register('dateObserved', { required: true, value: new Date() });
    register('performanceUnit', {
      required: true,
      value: userDefaultDetails.performanceUnit,
    });
    register('selfVerificationType', {
      required: true,
      value: !isModalEmpty(modalData) ? modalData.svtId : fieldInspectionId,
    });
    register('assessmentForm', { required: true });
    register('assessmentFormVersionId', { required: true });
    register('site', { required: true, value: userDefaultDetails.site });
    register('category', { required: true });
    register('scheduleId', { required: false, value: scheduleId || '' });
    register('assessor', {
      required: true,
      value: userDefaultDetails.id,
    });
    register('isActive', { required: true, value: 'true' });
    register('isDraft', { required: true, value: 'true' });
  }, [register]);

  useEffect(() => {
    if (!siteId) {
      setValue('site', '');
    }
  }, [performanceUnit]);

  useEffect(() => {
    if (!modalAssessmentFormId) {
      setValue('assessmentForm', '');
      setValue('assessmentFormVersionId', '');
    }
  }, [category]);

  useEffect(() => {
    if (!categoryId) {
      setValue('category', '');
    }
  }, [selfVerificationType]);

  useEffect(() => {
    if (Number(assessmentFormId)) {
      setDescription(
        assessmentFormStore
          .getAssessmentForms()
          .find((a) => a.id === Number(assessmentFormId))!.description,
      );
      // Reset form data for answers
      const defaultData = pick(getValues(), [
        'dateObserved',
        'scheduleId',
        'performanceUnit',
        'selfVerificationType',
        'assessmentForm',
        'assessmentFormVersionId',
        'site',
        'category',
        'assessor',
        'isActive',
        'isDraft',
      ]);
      reset(defaultData);
    } else {
      setDescription('');
    }
  }, [register, assessmentFormId]);

  const disabled =
    !dateObserved ||
    !performanceUnit ||
    !assessmentForm ||
    !site ||
    !category ||
    !assessor;

  const changed =
    !!dateObserved ||
    !!performanceUnit ||
    !!assessmentForm ||
    !!site ||
    !!category ||
    !!assessor;

  const flagChanges = () => {
    if (!isChanged) {
      setIsChanged(true);
    }
  };

  const getQuestionList = useCallback(
    (assessmentFormId: string) => {
      // Get and sort form sections belonging to the assessment form
      const formSections = orderBy(
        assessmentFormStore
          .getFormSections()
          .filter(
            (formSection) =>
              formSection.assessmentForm === Number(assessmentFormId) &&
              formSection.isActive,
          ),
        ['order'],
        ['asc'],
      );

      // Sort questions under each form section
      const questions: Question[] = [];
      formSections.forEach((formSection) => {
        const formSectionQuestions = orderBy(
          assessmentFormStore
            .getQuestions()
            .filter(
              (question) =>
                question.formSection === formSection.id && question.isActive,
            ),
          ['order'],
          ['asc'],
        );
        questions.push(...formSectionQuestions);
      });

      return questions;
    },
    [
      assessmentStore.assessments.values(),
      assessmentFormStore.formSections.values(),
      assessmentFormStore.questions.values(),
    ],
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (changed) {
      flagChanges();
    }
  }, [changed]);

  useEffect(() => {
    if (initialLoading) {
      if (!isModalEmpty(modalData)) {
        setValue('performanceUnit', performanceUnitId!);
        setValue('site', siteId!);
        setValue('selfVerificationType', svtId!);
        setValue('category', categoryId!);
        setValue('assessmentForm', modalAssessmentFormId!);
        setValue('assessmentFormVersionId', modalAssessmentFormVersionId!);
        setValue('scheduleId', scheduleId!);
        setAssessmentFormId(modalAssessmentFormId!);
      } else {
        setValue('performanceUnit', userDefaultDetails.performanceUnit || '');
        setValue('site', userDefaultDetails.site || '');
        setValue('selfVerificationType', fieldInspectionId);
        setValue('assessor', userDefaultDetails.id);
        setValue('dateObserved', new Date());
      }
    }
    setInitialLoading(false);
  }, [initialLoading]);

  const onReset = () => {
    setValue('performanceUnit', '');
    setValue('site', '');
    setValue('selfVerificationType', fieldInspectionId);
    setValue('category', '');
    setValue('assessmentForm', '');
    setValue('assessmentFormVersionId', '');
    setValue('assessor', userDefaultDetails.id);
    setValue('dateObserved', new Date());
  };

  const onSubmit = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const assessment: AssessmentData = pick(getValues(), [
      'dateObserved',
      'scheduleId',
      'performanceUnit',
      'selfVerificationType',
      'assessmentForm',
      'assessmentFormVersionId',
      'site',
      'category',
      'assessor',
      'isActive',
      'isDraft',
    ]);
    const assessmentForm = assessmentFormStore.getAssessmentForm(
      Number(assessment.assessmentForm),
    )!;
    assessment.assessmentFormVersionId = assessmentForm.versionId.toString();

    assessment.dateObserved = new Date(
      Date.UTC(
        assessment.dateObserved.getFullYear(),
        assessment.dateObserved.getMonth(),
        assessment.dateObserved.getDate(),
      ),
    );

    const result = await assessmentStore.addAssessment(assessment);
    if (result.ok) {
      await assessmentFormStore.fetchAssessmentFormDetails(
        Number(assessment.assessmentForm),
      );

      const assessmentId = result.extra!.id;

      // delete previous draft assessment if it exists
      await deleteDraft();

      // create new blank answers
      const questions = getQuestionList(assessment.assessmentForm);
      const newAnswerList: AnswerData[] = [];

      questions.forEach((question) => {
        newAnswerList.push({
          answer: '',
          description: '',
          question: question.id.toString(),
          questionVersionId: question.versionId.toString(),
          assessment: assessmentId,
        });
      });
      await assessmentStore.addAnswer(newAnswerList);

      setDraftAssessmentId(assessmentId);
      setCurrentlyEditingAssessment(assessmentId);

      onNext();
    }

    setLoading(false);
  }, [loading, draftAssessmentId]);

  return (
    <>
      {!initialLoading && (
        <>
          <View style={styles.dropDownContainer}>
            <View style={styles.row}>
              <View style={styles.left}>
                <DatePicker
                  label="Date observed"
                  placeholder="Select date"
                  date={dateObserved || new Date()}
                  onSelectDate={(date) => setValue('dateObserved', date)}
                  showDateClear={false}
                />
                <AutocompleteComponent
                  label="Performance unit"
                  placeholder="Select performance unit"
                  onSelect={(id) => setValue('performanceUnit', id)}
                  data={performanceUnits}
                  value={performanceUnit}
                  disabled={performanceUnitId !== undefined}
                />
                <AutocompleteComponent
                  label="Self-verification type"
                  placeholder="Select self-verification type"
                  onSelect={(id) => setValue('selfVerificationType', id)}
                  data={selfVerificationTypes}
                  value={selfVerificationType}
                  disabled={svtId !== undefined}
                />
                <AutocompleteComponent
                  label="Assessment"
                  placeholder="Select assessment"
                  onSelect={(id) => {
                    setValue('assessmentForm', id);
                    setAssessmentFormId(id);
                    const assessmentFormModel = assessmentFormStore
                      .getAssessmentForms()
                      .find((a) => a.id === Number(id))!;
                    setValue(
                      'assessmentFormVersionId',
                      assessmentFormModel.versionId.toString(),
                    );
                    clearErrors();
                  }}
                  data={assessmentForms}
                  value={assessmentForm}
                  disabled={
                    modalData.modalAssessmentFormId !== undefined
                      ? true
                      : !category
                  }
                />
              </View>
              <View style={styles.right}>
                <View style={styles.empty} />
                <AutocompleteComponent
                  label="Site"
                  placeholder="Select site"
                  onSelect={(id) => setValue('site', id)}
                  data={sites}
                  value={site}
                  disabled={siteId !== undefined ? true : !performanceUnit}
                />
                <AutocompleteComponent
                  label="Category"
                  placeholder="Select category"
                  onSelect={(id) => setValue('category', id)}
                  data={categories}
                  value={category}
                  disabled={
                    categoryId !== undefined ? true : !selfVerificationType
                  }
                />
                <AutocompleteComponent
                  label="Assessor"
                  placeholder="Select assessor"
                  onSelect={(id) => setValue('assessor', id)}
                  data={users}
                  value={assessor}
                  disabled={modalData.modalAssessmentFormId !== undefined}
                />
              </View>
            </View>

            <View style={styles.description}>
              {description ? (
                <Markdown
                  style={{
                    body: { color: '#666666', fontFamily: 'UniversBP_Regular' },
                  }}
                  markdownit={markdownItInstance}
                >
                  {tdservice.turndown(
                    description.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                  )}
                </Markdown>
              ) : (
                <></>
              )}
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <Button
              status="basic"
              size="large"
              style={styles.button}
              onPress={onReset}
              accessoryLeft={() => (
                <Icon
                  name="close-outline"
                  width={24}
                  height={24}
                  fill={
                    modalData.modalAssessmentFormId
                      ? '#8e9bb4'
                      : theme['text-dark']
                  }
                />
              )}
              disabled={modalData.modalAssessmentFormId !== undefined}
            >
              <Text>Reset</Text>
            </Button>
            <Button
              status="primary"
              size="large"
              style={styles.button}
              onPress={onSubmit}
              accessoryLeft={(props) =>
                loading ? (
                  <Spinner status="info" {...props} />
                ) : (
                  <Icon
                    name="arrow-forward-outline"
                    width={24}
                    height={24}
                    fill={theme['text-white']}
                  />
                )
              }
              disabled={disabled}
            >
              <Text>Continue</Text>
            </Button>
          </View>
        </>
      )}
    </>
  );
};

const responsiveStyle = createResponsiveStyle({
  baseStyle: {
    loading: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dropDownContainer: {
      flex: 1,
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingLeft: 20,
      paddingRight: 12,
      paddingVertical: 16,
    },
    row: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    left: {
      flex: 1,
      flexDirection: 'column',
      width: '50%',
      paddingRight: 12.5,
    },
    right: {
      flex: 1,
      flexDirection: 'column',
      width: '50%',
      paddingLeft: 12.5,
    },
    empty: {
      flex: 1,
      minHeight: 71,
    },
    description: {
      minHeight: 109,
      paddingTop: 16,
      paddingLeft: 2,
    },
    descriptionText: {
      fontSize: 14,
      fontFamily: 'UniversBP_Regular',
      color: 'text-light',
    },
    buttonContainer: {
      paddingTop: 24,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    button: {
      marginLeft: 15,
      width: 312,
      justifyContent: 'flex-start',
    },
  },
});

export default observer(AssessmentInfo);
