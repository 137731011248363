import { useMemo } from 'react';

import { useStore } from '../stores';

export default function useUserDefaultDetails() {
  const store = useStore();
  const { authStore, performanceUnitStore, siteStore } = store;

  const id = useMemo(() => {
    return authStore.user?.id ? authStore.user.id.toString() : '';
  }, [authStore.user]);

  const performanceUnit = useMemo(() => {
    const performanceUnitId = authStore.user?.performanceUnit
      ? performanceUnitStore.getPerformanceUnit(authStore.user?.performanceUnit)
          ?.id
      : undefined;

    return performanceUnitId ? performanceUnitId.toString() : '';
  }, [performanceUnitStore.performanceUnits.values(), authStore.user]);

  const site = useMemo(() => {
    const site = siteStore
      .getSites()
      .find(
        (site) =>
          authStore.user?.sites.includes(site.id) &&
          site.performanceUnit === Number(performanceUnit),
      );
    return site && site.id ? site.id.toString() : '';
  }, [siteStore.sites.values(), authStore.user]);

  const initialDateStart = new Date(
    new Date().getFullYear(),
    0,
    1,
  ).toISOString();
  const initialDateEnd = new Date(
    new Date().getFullYear(),
    11,
    31,
  ).toISOString();

  return { id, performanceUnit, site, initialDateStart, initialDateEnd };
}
