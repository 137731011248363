import { useTheme, Button, Icon } from '@ui-kitten/components';
import React from 'react';
import { View, Text } from 'react-native';

import OfflineIcon from '../../../assets/images/warning_icon.svg';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

type Props = {
  onDiscard: () => void;
  onContinue: () => void;
};

const UnsavedChangesPrompt: React.FC<Props> = ({ onDiscard, onContinue }) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();

  return (
    <View style={styles.card}>
      <OfflineIcon />
      <View style={styles.textBox}>
        <Text style={styles.text}>
          You have unsaved changes.{'\n\n'}
          <Text style={[styles.text, { color: theme['text-grey'] }]}>
            Leaving this page will discard your changes. Confirm navigation?
          </Text>
        </Text>
      </View>
      <View style={styles.backButtons}>
        <Button
          status="primary"
          size="large"
          appearance="outline"
          style={{ marginRight: 20, width: 200 }}
          accessoryLeft={() => (
            <Icon
              name="trash-outline"
              width={20}
              height={20}
              fill={theme['text-dark']}
            />
          )}
          onPress={onDiscard}
        >
          Discard Changes
        </Button>
        <Button
          status="primary"
          size="large"
          appearance="filled"
          style={{ width: 200 }}
          accessoryLeft={() => (
            <Icon
              name="edit-outline"
              width={24}
              height={24}
              fill={theme['text-white']}
            />
          )}
          onPress={onContinue}
        >
          Continue Editing
        </Button>
      </View>
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    textBox: {
      marginTop: 30,
    },
    text: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    card: {
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      paddingVertical: 20,
      paddingHorizontal: 47,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 540,
      height: 320,
    },
    backButtons: {
      flexDirection: 'row',
      marginTop: 40,
    },
  },
});

export default UnsavedChangesPrompt;
