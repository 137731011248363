import { Icon, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { forwardRef } from 'react';
import {
  TouchableOpacity,
  View,
  Image,
  ImageStyle,
  Linking,
} from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { useStore } from '../../stores';
import { ImageAttachment } from '../../types';
import CustomLinking from '../../utils/linking';

type Props = {
  attachment: ImageAttachment;
  setIsImageVisible: (state: boolean) => void;
};

const ImageViewer = forwardRef((props: Props) => {
  const { attachment, setIsImageVisible } = props;
  const styles = useResponsiveStyleSheet(themedStyles);
  const store = useStore();
  const { isInternetReachable } = store;
  const tabOpeningImgFormats = ['gif', 'jpg', 'jpeg', 'png'];
  const isExpand = tabOpeningImgFormats.includes(attachment.name.split('.')[1]);

  const onExpand = async () => {
    if (attachment.isSaved) {
      const canOpen = await Linking.canOpenURL(attachment.file);
      if (canOpen) {
        CustomLinking.openURL(attachment.file, '_blank');
      }
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerRow}>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => setIsImageVisible(false)}
        >
          <Icon name="close-outline" fill="white" width={24} height={24} />
        </TouchableOpacity>
        <Text style={styles.name}>{attachment.name}</Text>
        {isInternetReachable && attachment.isSaved ? (
          isExpand ? (
            <TouchableOpacity activeOpacity={1} onPress={onExpand}>
              <Icon name="expand-outline" fill="white" width={24} height={24} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity activeOpacity={1} onPress={onExpand}>
              <Icon
                name="download-outline"
                fill="white"
                width={24}
                height={24}
              />
            </TouchableOpacity>
          )
        ) : (
          <View style={{ width: 24, height: 24 }} />
        )}
      </View>
      <Image
        style={styles.image as ImageStyle}
        source={{
          uri: attachment.file,
        }}
      />
      {/* <View style={styles.thumbnailRow} /> */}
    </View>
  );
});

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flexDirection: 'column',
      height: '72%',
      width: '70%',
      maxWidth: 744,
      maxHeight: 760,
      backgroundColor: 'text-dark',
      paddingHorizontal: 24,
      paddingTop: 25,
      paddingBottom: 36,
    },
    headerRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 32,
    },
    name: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-white',
    },
    thumbnailRow: {
      paddingTop: 32,
      height: 54,
    },
    image: {
      flex: 1,
      width: '100%',
      resizeMode: 'contain',
    },
  },
});

export default observer(ImageViewer);
