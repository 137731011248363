import { useMediaQuery } from 'react-responsive';

import { MediaMinWidths } from '../constants/Layout';

export const useMediaQueries = () => {
  const isDesktop = useMediaQuery({ minWidth: MediaMinWidths.DESKTOP });
  const isTablet = useMediaQuery({
    minWidth: MediaMinWidths.TABLET,
    maxWidth: MediaMinWidths.DESKTOP - 1,
  });
  const isMobile = useMediaQuery({ maxWidth: MediaMinWidths.TABLET - 1 });

  return { isDesktop, isTablet, isMobile };
};
