import { HeaderTitle } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import { Button, Icon, useTheme } from '@ui-kitten/components';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { Linking, ScrollView, TouchableOpacity, View } from 'react-native';

import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import HistoricalQuestion from '../../models/Question';
import { AppStackNavigation } from '../../navigation';
import { useStore } from '../../stores';
import CustomLinking from '../../utils/linking';
import CustomTooltip from '../Common/CustomTooltip';
import SyncIndicator from '../Common/SyncIndicator';
import ViewAssessmentFormHeader from './ViewAssessmentFormHeader';
import ViewQuestionFill from './ViewQuestionFill';

type Props = {
  onGoBack: () => void;
  assessmentId: number;
  selectedIndex: number;
  cameFrom?: string;
};

const ViewAssessmentFormFill: React.FC<Props> = ({
  onGoBack,
  assessmentId,
  cameFrom,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const navigation = useNavigation<AppStackNavigation>();
  const theme = useTheme();
  const store = useStore();
  const {
    assessmentStore,
    historicalAssessmentFormStore,
    isInternetReachable,
  } = store;
  const [showTip, setShowTip] = useState<boolean>(false);

  const assessment = assessmentStore.getAssessment(assessmentId)!;

  const questions: HistoricalQuestion[] = useMemo(() => {
    const id = assessmentStore.getAssessment(assessmentId)?.assessmentForm;

    // Get and sort form sections belonging to the assessment form
    const historicalFormSections = orderBy(
      historicalAssessmentFormStore
        .getFormSections()
        .filter(
          (formSection) =>
            formSection.assessmentForm === id &&
            formSection.assessmentFormVersionId ===
              assessment.assessmentFormVersionId &&
            formSection.isActive,
        ),
      ['order'],
      ['asc'],
    );

    // Sort questions under each form section
    const questions: HistoricalQuestion[] = [];
    historicalFormSections.forEach((formSection) => {
      const formSectionQuestions = orderBy(
        historicalAssessmentFormStore
          .getQuestions()
          .filter(
            (question) =>
              question.formSection === formSection.id &&
              question.formSectionVersionId ===
                formSection.versionId.toString() &&
              question.isActive,
          ),
        ['order'],
        ['asc'],
      );
      questions.push(...formSectionQuestions);
    });

    return questions;
  }, [
    assessmentId,
    assessmentStore.assessments.values(),
    historicalAssessmentFormStore.historicalFormSections.values(),
    historicalAssessmentFormStore.historicalQuestions.values(),
  ]);

  const renderItem = useCallback(
    (item: HistoricalQuestion, index: number) => (
      <React.Fragment key={item.id}>
        <ViewQuestionFill
          question={item}
          assessmentId={assessmentId}
          index={index}
        />
        {index === questions.length - 1 && <View style={styles.separator} />}
      </React.Fragment>
    ),
    [],
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={styles.headerRightContainer}>
          <CustomTooltip
            showTip={showTip}
            contentText="This is your offline indicator. If it is gray, you are
                  offline, and your inputs will be temporarily stored until you
                  are reconnected to the internet"
            setShowTip={setShowTip}
            placement="bottom"
            multiline
          >
            <TouchableOpacity
              onLongPress={() => setShowTip(true)}
              // @ts-ignore
              oMouseEnter={() => setShowTip(true)}
              // @ts-ignore
              oMouseLeave={() => setShowTip(false)}
            >
              <SyncIndicator />
            </TouchableOpacity>
          </CustomTooltip>
          {isInternetReachable ? (
            <Button
              style={{ marginHorizontal: 16, width: 169 }}
              status="success"
              size="medium"
              appearance="filled"
              onPress={() => {
                exportPdf();
              }}
              accessoryLeft={
                <Icon
                  name="file-text-outline"
                  width={24}
                  height={24}
                  fill={theme['text-white']}
                />
              }
            >
              Export as PDF
            </Button>
          ) : null}
        </View>
      ),
      headerTitle: () => {
        const headerText = `Back to ${cameFrom}`;
        return (
          cameFrom && (
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <HeaderTitle style={styles.headerTitle} tintColor="#ffffff">
                {headerText}
              </HeaderTitle>
            </TouchableOpacity>
          )
        );
      },
      headerLeft: () => (
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.headerLeftContainer}
        >
          <Icon
            name="arrow-back-outline"
            width={32}
            height={32}
            fill="#ffffff"
          />
        </TouchableOpacity>
      ),
    });
  }, [navigation, showTip]);

  const exportPdf = async () => {
    const url = `${config.urls.api}${config.urls.exportPdf}${assessmentId}`;
    const canOpen = await Linking.canOpenURL(url);
    if (canOpen) {
      return CustomLinking.openURL(url, '_blank');
    }
  };

  const renderHeader = () => (
    <ViewAssessmentFormHeader
      onGoBack={onGoBack}
      questionIds={questions.map((q) => q.id)}
      assessment={assessment}
    />
  );

  return (
    <ScrollView style={{ paddingBottom: 80, paddingRight: 55 }}>
      {renderHeader()}
      {questions.map(renderItem)}
    </ScrollView>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    header: {
      paddingTop: 8,
      paddingBottom: 17,
      minHeight: 69,
    },
    headerRightContainer: {
      marginHorizontal: 20,
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerLeftContainer: {
      marginHorizontal: 20,
    },
    headerTitle: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 18,
      letterSpacing: 1,
    },
    title: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 24,
      color: 'text-dark',
    },
    dropDownIcon: {
      paddingLeft: 46,
    },
    headerSubTitle: {
      flexDirection: 'row',
      paddingTop: 5,
      alignItems: 'center',
    },
    counterBadge: {
      height: 32,
      minWidth: 88,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'badge-grey',
      borderRadius: 16,
      paddingTop: 7,
      paddingBottom: 8,
      marginLeft: 13,
    },
    detailsContainer: {
      marginTop: 20,
      flexDirection: 'row',
    },
    detailsLeft: {
      flexDirection: 'column',
      minWidth: 522,
    },
    detailsRight: {
      flexDirection: 'column',
      minWidth: 686,
    },
    infoRow: {
      flexDirection: 'row',
      alignItems: 'baseline',
      paddingBottom: 24,
    },
    infoTitle: {
      minWidth: 182,
      fontFamily: 'UniversLTPro_Regular',
      fontSize: 14,
      color: 'text-grey',
    },
    infoContent: {
      minWidth: 223,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    editButton: {
      marginTop: 8,
      marginBottom: 24,
    },
    edit: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'bp-green',
    },
    separator: {
      height: 1,
      backgroundColor: 'grey-100',
    },
    questionsContainer: {
      flex: 1,
    },
    buttonContainer: {
      paddingTop: 24,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    button: {
      marginLeft: 15,
      width: 312,
      justifyContent: 'flex-start',
    },
  },
});

export default observer(ViewAssessmentFormFill);
