import { observer } from 'mobx-react-lite';
import React from 'react';

import SyncOffline from '../../../assets/images/Sync_Offline.svg';
import SyncOnline from '../../../assets/images/Sync_Online.svg';
import { useStore } from '../../stores';

const SyncIndicator: React.FC = () => {
  const store = useStore();
  const { isInternetReachable } = store;

  return isInternetReachable ? <SyncOnline /> : <SyncOffline />;
};

export default observer(SyncIndicator);
