import { Icon, Text, useTheme } from '@ui-kitten/components';
import MDEditor from '@uiw/react-md-editor';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';
import TurndownService from 'turndown';

import { CatActionStatus } from '../../constants/Status';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { CatActionTrackerItem } from '../../types';
import { getAssessmentIdFormat } from '../../utils/helper';

type Props = {
  catAction: CatActionTrackerItem;
};

const ActionTrackerItem: React.FC<Props> = ({ catAction }) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const markdownItInstance = MarkdownIt({ typographer: true, linkify: true });
  const tdservice = new TurndownService();
  tdservice.escape = (string) => string;

  const [expand, setExpand] = useState(false);

  let statusColor = '';
  switch (catAction.status) {
    case CatActionStatus.CLOSED:
      statusColor = theme['badge-red'];
      break;
    case CatActionStatus.CLOSED_REQUIRES_VERIFICATION:
      statusColor = theme['badge-blue'];
      break;
    case CatActionStatus.CANCELLED:
      statusColor = theme['grey-300'];
      break;
    case CatActionStatus.IN_PROGRESS:
      statusColor = theme['green-500'];
      break;
    case CatActionStatus.CHANGE_REQUESTED:
      statusColor = theme['green-500'];
      break;
    case CatActionStatus.DRAFT:
      statusColor = theme['green-500'];
      break;
    case CatActionStatus.WAITING_OWNER_ACKNOWLEDGEMENT:
      statusColor = theme['green-500'];
      break;
    case CatActionStatus.WAITING_APPROVAL:
      statusColor = theme['yellow-800'];
      break;
  }

  const incompleteStatuses: string[] = [
    CatActionStatus.IN_PROGRESS,
    CatActionStatus.CHANGE_REQUESTED,
    CatActionStatus.DRAFT,
    CatActionStatus.WAITING_OWNER_ACKNOWLEDGEMENT,
    CatActionStatus.WAITING_APPROVAL,
  ];

  return (
    <>
      <TouchableOpacity
        style={[
          styles.actionItem,
          expand && { backgroundColor: theme['gray-07'] },
        ]}
        activeOpacity={1}
        onPress={() => setExpand(!expand)}
      >
        <View
          style={[
            styles.actionCell,
            { flex: 125 / 1500, flexDirection: 'row' },
          ]}
        >
          {expand ? (
            <Icon
              name="chevron-down-outline"
              width={22}
              height={22}
              fill={theme['text-dark']}
            />
          ) : (
            <Icon
              name="chevron-right-outline"
              width={22}
              height={22}
              fill={theme['text-dark']}
            />
          )}
          <Text style={styles.actionText}>
            AC-{getAssessmentIdFormat(catAction?.id.toString() || '')}
          </Text>
        </View>
        <View style={[styles.actionCell, { flex: 324 / 1500 }]}>
          <Text style={styles.actionText}>
            {catAction.description ? catAction.description : ''}
          </Text>
        </View>
        <View style={[styles.actionCell, { flex: 217 / 1500 }]}>
          <View style={[styles.status, { backgroundColor: statusColor }]}>
            <Text
              style={[
                styles.actionText,
                { color: theme['text-white'], marginRight: 0 },
                catAction.status ===
                  CatActionStatus.CLOSED_REQUIRES_VERIFICATION && {
                  fontSize: 10,
                },
                catAction.status === CatActionStatus.CHANGE_REQUESTED && {
                  fontSize: 10,
                },
                catAction.status === CatActionStatus.WAITING_APPROVAL && {
                  fontSize: 10,
                },
                catAction.status ===
                  CatActionStatus.WAITING_OWNER_ACKNOWLEDGEMENT && {
                  fontSize: 10,
                },
              ]}
            >
              {catAction.status || ''}
            </Text>
          </View>
        </View>
        <View style={[styles.actionCell, { flex: 170 / 1500 }]}>
          {catAction.dueDate ? (
            <Text style={styles.actionText}>
              {catAction.catCreated
                ? format(parseISO(catAction.catCreated), 'dd MMMM yyyy')
                : ''}
            </Text>
          ) : (
            <Text style={[styles.actionText, { marginLeft: 30 }]}>-</Text>
          )}
        </View>
        <View style={[styles.actionCell, { flex: 168 / 1500 }]}>
          {catAction.dueDate ? (
            <Text style={styles.actionText}>
              {format(parseISO(catAction.dueDate), 'dd MMMM yyyy')}
              {!catAction.completedDate &&
              incompleteStatuses.includes(catAction.status!) &&
              new Date() > parseISO(catAction.dueDate) ? (
                <Text style={styles.overdueText}> (overdue)</Text>
              ) : (
                ''
              )}
            </Text>
          ) : (
            <Text style={[styles.actionText, { marginLeft: 30 }]}>-</Text>
          )}
        </View>
        <View style={[styles.actionCell, { flex: 196 / 1500 }]}>
          {catAction.completedDate ? (
            <Text style={styles.actionText}>
              {catAction.completedDate
                ? format(parseISO(catAction.completedDate), 'dd MMMM yyyy')
                : ''}
            </Text>
          ) : (
            <Text style={[styles.actionText, { marginLeft: 30 }]}>-</Text>
          )}
        </View>
        <View style={[styles.actionCell, { flex: 182 / 1500 }]}>
          <Text style={styles.actionText}>{catAction.site || ''}</Text>
        </View>
        <View style={[styles.actionCell, { flex: 118 / 1500 }]}>
          <Text style={styles.actionText}>
            {catAction.owner ? catAction.owner : ''}
          </Text>
        </View>
      </TouchableOpacity>
      {expand && (
        <>
          <View style={styles.expandHeader}>
            <View style={{ flex: 148 / 1330, marginLeft: 44 }}>
              <Text style={styles.expandHeaderText}>Assessment ID</Text>
            </View>
            <View style={{ flex: 236 / 1330 }}>
              <Text style={styles.expandHeaderText}>Assessment</Text>
            </View>
            <View style={{ flex: 316 / 1330 }}>
              <Text style={styles.expandHeaderText}>Question</Text>
            </View>
            <View style={{ flex: 311 / 1330 }}>
              <Text style={styles.expandHeaderText}>Comment</Text>
            </View>
            <View style={{ flex: 274 / 1330 }}>
              <Text style={styles.expandHeaderText}>Assessment date</Text>
            </View>
          </View>
          <View style={styles.expandDetails}>
            <View style={{ flex: 148 / 1330, marginLeft: 44 }}>
              <Text style={styles.expandDetailsText}>
                AS-{getAssessmentIdFormat(catAction.assessmentId || '')}
              </Text>
            </View>
            <View style={{ flex: 236 / 1330 }}>
              <Text style={styles.expandDetailsText}>
                {catAction.assessmentForm}
              </Text>
            </View>
            <View style={{ flex: 316 / 1330 }}>
              {/* <Text style={styles.expandDetailsText}>{catAction.question}</Text> */}
              <Markdown
                style={{
                  body: styles.expandDetailsText,
                  paragraph: {
                    marginTop: -2,
                    marginBottom: 0,
                  },
                }}
                markdownit={markdownItInstance}
              >
                {tdservice.turndown(
                  `${catAction.question}${
                    catAction.questionDescription
                      ? `\n${catAction.questionDescription}`
                      : ''
                  }`.replace(/(?:\r\n|\r|\n)/g, '<br>') || '',
                )}
              </Markdown>
            </View>
            <View style={{ flex: 311 / 1330 }}>
              <MDEditor.Markdown
                data-color-mode="light"
                source={catAction.comment}
                style={{
                  fontSize: 14,
                  fontFamily: 'UniversBP_Light',
                  fontWeight: 'bold',
                  color: theme['text-dark'],
                  backgroundColor: 'transparent',
                  marginTop: -2,
                  paddingRight: 10,
                }}
              />
            </View>
            <View style={{ flex: 274 / 1330 }}>
              <Text style={styles.expandDetailsText}>
                {catAction.assessmentDateObserved
                  ? format(
                      parseISO(catAction.assessmentDateObserved),
                      'dd MMMM yyyy',
                    )
                  : ''}
              </Text>
            </View>
          </View>
        </>
      )}
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    actionItem: {
      flexDirection: 'row',
      paddingVertical: 20,
      backgroundColor: 'text-white',
    },
    overdueText: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 12,
      color: 'badge-red',
    },
    actionCell: {
      marginHorizontal: 15,
    },
    actionText: {
      fontSize: 14,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-dark',
    },
    status: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: 115,
      height: 25,
      borderRadius: 12,
      backgroundColor: 'badge-red',
    },
    expandHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 32,
      backgroundColor: 'grey-100',
    },
    expandHeaderText: {
      fontSize: 12,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-grey',
    },
    expandDetails: {
      flexDirection: 'row',
      paddingVertical: 20,
      backgroundColor: 'gray-07',
    },
    expandDetailsText: {
      fontSize: 14,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-dark',
      paddingRight: 10,
    },
  },
});

export default observer(ActionTrackerItem);
